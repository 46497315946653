import { PrivacyPolicySection } from '../model/types';

export const MockPrivacyPolicySection: PrivacyPolicySection = {
  header: 'Политика конфиденциальности',
  firstText:
    'Настоящим подтверждением я даю разрешение АО «СЗ Сергея Макеева», любым иным третьим лицам (включая, но не ограничиваясь):',
  list: [
    'Страховым организациям.',
    'Организациям — владельцам серверов.',
    'Банковским и иным кредитным организациям.',
    'Организациям, оказывающим услуги по осуществлению звонков, СМСрассылок, любых иных видов рассылок и уведомлений.',
    'Организациям, оказывающим услуги по проведению различных опросов, исследований и пр. в целях информирования о товарах, работах, услугах, — осуществлять обработку персональных данных и направлять на  указанный мною адрес электронной почты и/или на номер мобильного телефона информацию и рекламу о товарах, работах, услугах АО «СЗ Сергея Макеева» и его партнёров с использованием любых средств связи.',
  ],
  secondText:
    'Я выражаю согласие и разрешаю АО «СЗ Сергея Макеева» и иным указанным им лицам объединять персональные данные в информационную систему персональных данных и обрабатывать мои персональные данные с помощью средств автоматизации либо без использования средств автоматизации, а также с помощью иных программных средств.',
  thirdText:
    'Я осведомлён, что настоящее согласие на обработку моих персональных данных и получение рекламы является бессрочным, оно может быть отозвано мною в любой момент путём направления письменного уведомления по адресу: 119017, г. Москва, Кадашёвская набережная, дом 6/1/2, строение 1, этаж 3, помещение I, комната 26.',
};
