import { mockFhotos } from '@/features/slider-default/model/mock-slider-default';
import { SliderHalfScreen } from '@/features/slider-half-screen';
import { TextBlockEnumeration } from '@/features/text-block-with-enumeration';
import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render';
import { pageTitle, slidesKidsLab, textBlockKidsLab, textBlockUniqueStyle } from '../api/mock-data';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { mockSlides } from '@/features/slider-half-screen/model/mock-slider-half-screen';
import { slidesFitLab } from '../api/mock-slider-fit-lab';
import { textBlockFitLab } from '../api/mock-text-block-fit-lab';
import { slidesFriendsLab } from '../api/mock-slider-friends-lab';
import { textBlockFriendsLab } from '../api/mock-text-block-friends-lab';
import tretyakovka from '@/shared/ui/assets/bg-images/tretyakovka.png';
import { absoluteComfort, idealEnvironment, impeccableLayouts, spalnya } from '../api/mock-photo-text-section';
import { textBlock } from '@/features/animated-images-with-text/model/mock-animated-images-with-text';
import { sliderFullScreenImages } from '../api/mock-slider-full-screen';
import { slidesAuto } from '../api/mock-slider-auto';
import { textBlockAuto } from '../api/mock-text-block-auto';
import { navbarSections } from '../api/mock-navbar-sections';
import { bottomText, topText } from '../api/mock-separate-uppercase-text';
import { separateTextBlock } from '../api/mock-separate-text-block';
import { dataForCircleProgressBar } from '../api/mock-big-circle-progress-bar';
import { firstVideoFineDevelopment } from '../api/mock-videos';
import { animatedCirclesTextBlock, wordsInCircles } from '@/features/animated-circles/model/mock-animated-circles';

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'section',
    props: { ...stylex.props(styles.titleWithNavbarWrapper) },
    children: [
      {
        component: 'PageTitle',
        props: {
          pageTitle: pageTitle,
        },
      },
      {
        component: 'Navbar',
        props: {
          navSections: navbarSections,
        },
      },
    ],
  },
  {
    component: 'section',
    props: { ...stylex.props(styles.pageWrapper) },
    children: [
      {
        component: 'SliderDefault',
        props: {
          images: mockFhotos,
        },
      },

      {
        component: 'section',
        props: { ...stylex.props(styles.animatedCirclesWithImages) },
        children: [
          {
            component: 'section',
            props: { ...stylex.props(styles.separateTextWithAnimatedCircles) },
            children: [
              {
                component: 'section',
                props: { ...stylex.props(styles.separateTextBlock), id: 'Наш метод' },
                children: [
                  {
                    component: 'SeparateTextBlock',
                    props: {
                      text: separateTextBlock,
                    },
                  },
                ],
              },
              {
                component: 'AnimatedCircles',
                props: {
                  textBlock: animatedCirclesTextBlock,
                  wordsInCircles: wordsInCircles,
                },
              },
            ],
          },

          {
            component: 'AnimatedImagesWithText',
            props: { textBlock: textBlock },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.topSeparateUppercaseTextWrapper) },
        children: [
          {
            component: 'SeparateUppercaseText',
            props: { text: topText },
          },
        ],
      },
      {
        component: 'ScrollingWrapper',
        props: {
          sliderComponent: <SliderHalfScreen slides={mockSlides} />,
          textBlockComponent: <TextBlockEnumeration textBlock={textBlockUniqueStyle} />,
        },
      },
      {
        component: 'PhotoFrameTextSection',
        props: {
          header: absoluteComfort.header,
          description: absoluteComfort.description,
          imgSrc: absoluteComfort.imgSrc,
          secondDescription: absoluteComfort.secondDescription,
        },
      },
      {
        component: 'Video',
        props: {
          previewUrl: firstVideoFineDevelopment[0].previewUrl,
          showButton: firstVideoFineDevelopment[0].showButton,
        },
      },
      {
        component: 'BigCircleProgressBar',
        props: {
          image: dataForCircleProgressBar.image,
          topText: dataForCircleProgressBar.topText,
          middleText: dataForCircleProgressBar.middleText,
          textWithImage: dataForCircleProgressBar.textWithImage,
        },
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.widthWrapper), id: 'Идеальная среда' },
        children: [
          {
            component: 'PhotoFrameTextSection',
            props: {
              header: idealEnvironment.header,
              description: idealEnvironment.description,
              imgSrc: idealEnvironment.imgSrc,
              reverseOrder: idealEnvironment.reverseOrder,
              id: 'Идеальная среда',
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.widthWrapper), id: 'Fit Lab' },
        children: [
          {
            component: 'ScrollingWrapper',
            props: {
              sliderComponent: <SliderHalfScreen slides={slidesFitLab} sticker="Fit Lab" position="right" />,
              textBlockComponent: <TextBlockEnumeration textBlock={textBlockFitLab} />,
              isSliderRight: true,
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.widthWrapper), id: "Kid's Lab" },
        children: [
          {
            component: 'ScrollingWrapper',
            props: {
              sliderComponent: <SliderHalfScreen slides={slidesKidsLab} sticker="Kid’s Lab" />,
              textBlockComponent: <TextBlockEnumeration textBlock={textBlockKidsLab} />,
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.widthWrapper), id: "Friend's Lab" },
        children: [
          {
            component: 'ScrollingWrapper',
            props: {
              sliderComponent: <SliderHalfScreen slides={slidesFriendsLab} sticker="Friend’s Lab" position="right" />,
              textBlockComponent: <TextBlockEnumeration textBlock={textBlockFriendsLab} />,
              isSliderRight: true,
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.sliderFullScreenWrapper) },
        children: [
          {
            component: 'SliderDefault',
            props: {
              images: sliderFullScreenImages,
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.bottomSeparateUppercaseTextWrapper), id: 'Безупречные планировки' },
        children: [
          {
            component: 'SeparateUppercaseText',
            props: {
              text: bottomText,
              textAlign: 'center',
            },
          },
        ],
      },
      {
        component: 'PhotoFrameTextSection',
        props: {
          header: impeccableLayouts.header,
          description: impeccableLayouts.description,
          imgSrc: impeccableLayouts.imgSrc,
        },
      },
      {
        component: 'PhotoFrameTextSection',
        props: {
          header: spalnya.header,
          description: spalnya.description,
          imgSrc: spalnya.imgSrc,
          reverseOrder: spalnya.reverseOrder,
        },
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.widthWrapper), id: 'Комфортный паркинг' },
        children: [
          {
            component: 'Video',
            props: {
              previewUrl: firstVideoFineDevelopment[1].previewUrl,
              videoUrl: firstVideoFineDevelopment[1].videoUrl,
            },
          },
        ],
      },
      {
        component: 'ScrollingWrapper',
        props: {
          sliderComponent: <SliderHalfScreen slides={slidesAuto} position="right" />,
          textBlockComponent: <TextBlockEnumeration textBlock={textBlockAuto} />,
          isSliderRight: true,
        },
      },
    ],
  },

  {
    component: 'TransitionCard',
    props: {
      bgUrl: tretyakovka,
      title: 'ПОРТФОЛИО ПРОЕКТОВ',
      link: '/',
    },
  },
];
