import { UpperSection } from '@/features/roompicker-grid-card/ui/upper-section.tsx';
import { ImageSection } from '@/features/roompicker-grid-card/ui/image-section.tsx';
import { LowerSection } from '@/features/roompicker-grid-card/ui/lower-section.tsx';
import { IRoompickerGridCardProps } from '@/features/roompicker-grid-card/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from './stylex.ts';

const RoompickerGridCard = ({
  infoProps,
  onImgClick,
  onCompareClick,
  onFavoriteClick,
  onMainClick,
  isFavorite,
  isToComparison,
}: IRoompickerGridCardProps) => {
  const { area, address, value, size, price, bedroomCount, showBuildingName, buildingName, currencyCode, imgLink } =
    infoProps;

  return (
    <div {...stylex.props(styles.container)}>
      <UpperSection
        address={address}
        buildingName={buildingName}
        showBuildingName={showBuildingName}
        isFavorite={isFavorite}
        isToComparison={isToComparison}
        onCompareClick={onCompareClick}
        onFavoriteClick={onFavoriteClick}
      />
      <ImageSection address={address} onClick={onImgClick} imgLink={imgLink} />
      <LowerSection
        size={size}
        area={area}
        value={value}
        price={price}
        currencyCode={currencyCode}
        bedroomCount={bedroomCount}
        onMainClick={onMainClick}
      />
    </div>
  );
};

export default RoompickerGridCard;
