const ShareIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.5454 5.98971L18.0098 2.45418C17.6193 2.06365 16.9862 2.06365 16.5956 2.45418L11.6459 7.40392C11.2554 7.79445 11.2554 8.42761 11.6459 8.81814L15.1814 12.3537C15.5719 12.7442 16.2051 12.7442 16.5956 12.3537L21.5454 7.40392C21.9359 7.0134 21.9359 6.38024 21.5454 5.98971Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12.354 15.1821L8.81843 11.6466C8.42791 11.256 7.79475 11.256 7.40422 11.6466L2.45447 16.5963C2.06395 16.9868 2.06395 17.62 2.45447 18.0105L5.99001 21.5461C6.38053 21.9366 7.0137 21.9366 7.40422 21.5461L12.354 16.5963C12.7445 16.2058 12.7445 15.5726 12.354 15.1821Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M15.5359 8.4646L8.46484 15.5356"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
