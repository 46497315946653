import * as stylex from '@stylexjs/stylex';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';

export const styles = stylex.create({
  linksContainer: {
    display: 'flex',
    borderBottom: '1px solid',
    gap: '1.66667rem',
    borderColor: colors.tertiaryBorder,
    paddingBottom: '6.25rem',
    fontSize: '1.04166667rem',
    lineHeight: '1.45833333rem',
    '@media (max-width: 767px)': {
      fontSize: '4.266667rem',
      paddingBottom: '14.933333rem',
      flexDirection: 'column',
      lineHeight: '6.4rem',
    },
  },
  shareHeading: {
    margin: 'auto 0',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  shareIconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1.66667rem',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '@media (max-width: 767px)': {
      marginTop: '4.266667rem',
    },
  },
  contentContainer: {
    width: '100%',
    color: colors.primaryText,
  },
  date: {
    color: colors.tertiaryText,
    fontSize: '0.9375rem',
    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
    },
  },
  heading: {
    fontFamily: fonts.din2014Light,
    fontSize: '1.875rem',
    lineHeight: '2.0833333rem',
    marginVertical: '1.25rem',
    textTransform: 'uppercase',
    color: 'inherit',
    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.4667rem',
      flexDirection: 'column',
      marginVertical: '6.4rem',
    },
  },
  categoryPlate: {
    fontSize: '0.9375rem',
    padding: '0.4166667rem 0.833333rem',
    borderRadius: '1000px',
    width: 'fit-content',
    color: 'inherit',
    backgroundColor: colors.brandBackground,
    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      padding: '2.133333rem 4.266667rem',
      marginBottom: '8.53333rem',
    },
  },
  textContainer: {
    fontSize: '1.041667rem',
    marginTop: '2.916667rem',
    lineHeight: '1.458333rem',
    color: 'inherit',
    '@media (max-width: 767px)': {
      fontSize: '4.266667rem',
      lineHeight: '6.4rem',
      marginTop: '8.533333rem',
    },
  },
  image: {
    width: '100%',
    height: '42.916667rem',
    marginVertical: '2.916667rem',
    objectFit: 'cover',
    '@media (max-width: 767px)': {
      height: '64rem',
      marginVertical: '8.53333rem',
    },
  },
  iconWrapper: {
    height: '1.25rem',
    width: '1.25rem',
    '@media (max-width: 767px)': {
      height: '6.4rem',
      width: '6.4rem',
    },
  },
  iconContainer: {
    display: 'flex',
    padding: '0.416667rem 0.625rem',
    borderRadius: '100px',
    backgroundColor: colors.brandBackground,
    transition: 'all 0.3s ease-in-out',
    '@media (max-width: 767px)': {
      padding: '2.13333rem 3.2rem',
    },
  },
  iconContainerHovered: {
    color: colors.inverseIcon,
    backgroundColor: colors.primaryIcon,
  },
  shareBtnTitle: {
    color: colors.primaryText,
    margin: 'auto 0',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  link: {
    display: 'flex',
    gap: '0.833333rem',
    textDecoration: 'unset',
    color: 'inherit',
    cursor: 'pointer',
  },
});
