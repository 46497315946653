import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { AnimatedCirclesTextBlockType, wordsInCirclesType } from '../model/types';
import { Circle } from './circle';
import useAnimatedLogic from '../lib/useAnimatedLogic';

interface AnimatedCirclesProps {
  textBlock: AnimatedCirclesTextBlockType;
  wordsInCircles: wordsInCirclesType;
}

export const AnimatedCircles: React.FC<AnimatedCirclesProps> = ({ textBlock, wordsInCircles }) => {
  const {
    containerRef,
    upperCircleWithTextRef,
    upperCircleRef,
    valuesTextRef,
    styleTextRef,
    comfortTextRef,
    uniquenessTextRef,
    impressionTextRef,
    rightCircleWithTextRef,
    rightCircleRef,
    functionTextRef,
    leftCircleWithTextRef,
    leftCircleRef,
    aestheticsTextRef,
    mainCircleBlockRef,
    mainCircleRef,
    wrapperTextRef,
  } = useAnimatedLogic();

  return (
    <div {...stylex.props(styles.mainContainer)}>
      <div {...stylex.props(styles.container)} ref={containerRef}>
        <div {...stylex.props(styles.upperCircleWithText)} ref={upperCircleWithTextRef}>
          <span {...stylex.props(styles.upperCircle)}>
            <Circle pathRef={upperCircleRef} />
          </span>
          <p {...stylex.props(styles.valuesText)} ref={valuesTextRef}>
            {wordsInCircles.topCircleText}
          </p>
          <p {...stylex.props(styles.styleText)} ref={styleTextRef}>
            {wordsInCircles.topAndLeftCircleText}
          </p>
          <p {...stylex.props(styles.comfortText)} ref={comfortTextRef}>
            {wordsInCircles.topAndRightCircleText}
          </p>
          <p {...stylex.props(styles.uniquenessText)} ref={uniquenessTextRef}>
            {wordsInCircles.mainCircleText}
          </p>
          <p {...stylex.props(styles.impressionText)} ref={impressionTextRef}>
            {wordsInCircles.leftAndRightCircleText}
          </p>
        </div>
        <div {...stylex.props(styles.rightCircleWithText)} ref={rightCircleWithTextRef}>
          <span {...stylex.props(styles.rightCircle)}>
            <Circle pathRef={rightCircleRef} />
          </span>
          <p {...stylex.props(styles.functionText)} ref={functionTextRef}>
            {wordsInCircles.rightCircleText}
          </p>
        </div>
        <div {...stylex.props(styles.leftCircleWithText)} ref={leftCircleWithTextRef}>
          <span {...stylex.props(styles.leftCircle)}>
            <Circle pathRef={leftCircleRef} />
          </span>
          <p {...stylex.props(styles.aestheticsText)} ref={aestheticsTextRef}>
            {wordsInCircles.leftCircleText}
          </p>
        </div>
        <div {...stylex.props(styles.mainCircleBlock)} ref={mainCircleBlockRef}>
          <span {...stylex.props(styles.mainCircle)}>
            <Circle pathRef={mainCircleRef} />
          </span>
        </div>
      </div>
      <div ref={wrapperTextRef} {...stylex.props(styles.textContainer)}>
        <div {...stylex.props(styles.textBlock)}>
          <p {...stylex.props(styles.textBlockTitle)}>{textBlock.title}</p>
          <div {...stylex.props(styles.descriptionContainer)}>
            <p {...stylex.props(styles.textBlockDescription)}>{textBlock.firstDescription}</p>
            <p {...stylex.props(styles.textBlockDescription)}>{textBlock.secondDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
