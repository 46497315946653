import React, { useState } from 'react';
import classes from './contact-page.module.css';
import { contactsPage } from '../model/dynamic-contacts-page-mock';
import { renderComponent } from '@/shared/libs/dynamic-components-render';

export const ContactPage = () => {
  const isMobile = window.innerWidth <= 767;
  
  const [selectedIndex, setSelectedIndex] = useState<number>(isMobile ? -1 : 0);
  const [, setIsVisible] = useState(true);

  return (
    <div className={`${classes.contactPageContainer} `}>
      {contactsPage(selectedIndex, setSelectedIndex, setIsVisible, isMobile).map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </div>
  );
};
