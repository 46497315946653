import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './style';

interface SeparateUppercaseTextProps {
  text: string;
  textAlign?: string;
  mobTextAlign?: string;
}

export const SeparateUppercaseText: React.FC<SeparateUppercaseTextProps> = ({ text, textAlign, mobTextAlign }) => {
  return <p {...stylex.props(styles.text(textAlign, mobTextAlign))}>{text}</p>;
};
