export function getFormattedDate({ date, addYear }: { date?: number; addYear?: boolean }) {
  if (!date) return '';

  const dateObject = new Date(date * 1000);

  const dayString = Number.parseFloat(dateObject.toLocaleDateString('ru-RU', { day: '2-digit' }));
  const monthString = dateObject.toLocaleDateString('ru-RU', { month: 'long' });
  const yearString = addYear ? dateObject.toLocaleDateString('ru-RU', { year: 'numeric' }) : '';

  return dayString + ' ' + monthString + ' ' + yearString;
}
