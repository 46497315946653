import React from 'react';
import { ButtonProps } from '../model/types';

import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

const Button: React.FC<ButtonProps> = (props) => {
  const {
    size = 'medium',
    isDisabled = false,
    isActive = false,
    variant = 'primary',
    children,
    onClick,
    badgeCount,
  } = props;
  return (
    <button
      role="button"
      {...stylex.props([styles.base, styles[size], styles[variant], isActive && styles.active])}
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
      {!isDisabled && <div {...stylex.props([styles.before])} />}
      {Boolean(badgeCount) && <div {...stylex.props([styles.badge])}>{badgeCount}</div>}
    </button>
  );
};

export default Button;
