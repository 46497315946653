import ordynkaLogo from '@/shared/ui/assets/reality-page/ordynka-logo.svg';
import renomeLogo from '@/shared/ui/assets/reality-page/renome-logo.svg';
import JazzLogo from '@/shared/ui/assets/reality-page/jazz-logo.svg';
import ordynkaFacade11 from '@/shared/ui/assets/reality-page/ordynka-facade-1-1.jpg';
import ordynkaFacade12 from '@/shared/ui/assets/reality-page/ordynka-facade-1-2.jpg';
import ordynkaDvor11 from '@/shared/ui/assets/reality-page/ordynka-dvor-1-1.jpg';
import ordynkaDvor12 from '@/shared/ui/assets/reality-page/ordynka-dvor-1-2.jpg';
import ordynkaDvor21 from '@/shared/ui/assets/reality-page/ordynka-dvor-2-1.jpg';
import ordynkaDvor22 from '@/shared/ui/assets/reality-page/ordynka-dvor-2-2.jpg';
import ordynkaFacade21 from '@/shared/ui/assets/reality-page/ordynka-facade-2-1.jpg';
import ordynkaFacade22 from '@/shared/ui/assets/reality-page/ordynka-facade-2-2.jpg';
import ordynkaLobby1 from '@/shared/ui/assets/reality-page/ordynka-lobby-1.jpg';
import ordynkaLobby2 from '@/shared/ui/assets/reality-page/ordynka-lobby-2.jpg';
import renomeFacade11 from '@/shared/ui/assets/reality-page/renome-facade-1-1.jpg';
import renomeFacade12 from '@/shared/ui/assets/reality-page/renome-facade-1-2.jpg';
import renomeDvor11 from '@/shared/ui/assets/reality-page/renome-dvor-1-1.jpg';
import renomeDvor12 from '@/shared/ui/assets/reality-page/renome-dvor-1-2.jpg';
import renomeDvor21 from '@/shared/ui/assets/reality-page/renome-dvor-2-1.jpg';
import renomeDvor22 from '@/shared/ui/assets/reality-page/renome-dvor-2-2.jpg';
import renomeLobby11 from '@/shared/ui/assets/reality-page/renome-lobby-1-1.jpg';
import renomeLobby12 from '@/shared/ui/assets/reality-page/renome-lobby-1-2.jpg';
import renomeLobby21 from '@/shared/ui/assets/reality-page/renome-lobby-2-1.jpg';
import renomeLobby22 from '@/shared/ui/assets/reality-page/renome-lobby-2-2.jpg';

export const BEFORE_AFTER_PHOTO_INFO_MOCK = [
  {
    id: 1,
    buildingName: 'Малая Ордынка 19',
    logoImg: ordynkaLogo,
    isDisabled: false,
    photos: [
      { id: 2, title: 'Фасад', beforeImg: ordynkaFacade11, afterImg: ordynkaFacade12 },
      { id: 3, title: 'Фасад', beforeImg: ordynkaFacade21, afterImg: ordynkaFacade22 },
      { id: 4, title: 'Двор', beforeImg: ordynkaDvor11, afterImg: ordynkaDvor12 },
      { id: 5, title: 'Двор', beforeImg: ordynkaDvor21, afterImg: ordynkaDvor22 },
      { id: 6, title: 'Лобби', beforeImg: ordynkaLobby1, afterImg: ordynkaLobby2 },
    ],
  },
  {
    id: 7,
    buildingName: 'Реномэ',
    logoImg: renomeLogo,
    isDisabled: false,
    photos: [
      { id: 8, title: 'Фасад', beforeImg: renomeFacade11, afterImg: renomeFacade12 },
      { id: 9, title: 'Двор', beforeImg: renomeDvor11, afterImg: renomeDvor12 },
      { id: 10, title: 'Двор', beforeImg: renomeDvor21, afterImg: renomeDvor22 },
      { id: 11, title: 'Лобби', beforeImg: renomeLobby11, afterImg: renomeLobby12 },
      { id: 12, title: 'Лобби', beforeImg: renomeLobby21, afterImg: renomeLobby22 },
    ],
  },
  {
    id: 15,
    buildingName: 'Квартал Jazz (скоро)',
    logoImg: JazzLogo,
    isDisabled: true,
    photos: [],
  },
];
