import { CancelIcon } from '@/shared/ui/icons/cancel-icon.tsx';
import { ThreeDotsIcon } from '@/shared/ui/icons/three-dots-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';

const CloseOrExpandButton = ({ isExpanded, onClick }: { isExpanded?: boolean; onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      {...stylex.props([
        styles.defaultBtn,
        styles.nonActiveBtn,
        styles.expandBtn,
        isExpanded ? styles.activeBtn : null,
      ])}
    >
      <span {...stylex.props([styles.closeBtn])}>{isExpanded ? <CancelIcon /> : <ThreeDotsIcon />}</span>
    </button>
  );
};

export default CloseOrExpandButton;
