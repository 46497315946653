import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  gridContainer: {
    position: 'relative',
    fontFamily: fonts.din2014Regular,
  },
  gridWrapper: (quantity) => ({
    display: 'flex',
    maxWidth: quantity * 15.5556 > 92.2222 ? '92.2222rem' : `${quantity * 15.5556}rem`, // 224px / 14.4, 1328px / 14.4
    backgroundColor: colors.primaryInverseForeground,
    borderRadius: '0.8333rem', // 12px / 14.4
    overflowX: 'hidden',
    position: 'relative',
    height: '37.7778rem', // 544px / 14.4
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '@media (max-width: 767px)': {
      maxWidth: '91.1111rem', //328
      height: '142.2222rem', //512
      borderRadius: '3.3333rem', // 12px
    },
  }),
  contentGrid: (quantity) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${quantity}, 15.5556rem)`,
    '@media (max-width: 767px)': {
      maxWidth: '91.1111rem', // 328
      gridTemplateColumns: `repeat(${quantity}, 46.6667rem)`,
    },
  }),

  header: {
    fontSize: '0.8333rem', // 12px / 14.4
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    color: colors.tertiaryForeground,
    marginLeft: '1.1111rem', // 16px / 14.4
    marginTop: '0.8333rem', // 12px / 14.4
    '@media (max-width: 767px)': {
      fontSize: '3.3333rem', // 12px
      marginLeft: '3.3333rem', // 12px
      marginTop: '3.3333rem', // 12px
    },
  },
  headerContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '3.0556rem', // 44px / 14.4
    backgroundColor: 'transparent',
    zIndex: 1,
    '@media (max-width: 767px)': {
      gap: '11.1111rem',
    },
  },
});
