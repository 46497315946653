import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  tasksWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '6.25rem 0',
    '@media (max-width: 767px)': {
      display: 'block',
      padding: '14.934rem 0',
    },
  },
  tasks: {
    width:'30.417rem',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    lineHeight:'140%',
    fontWeight: '300',
    fontSize: '1.0417rem',
    '@media (max-width: 767px)': {
      fontSize: '4.267rem',
      width: '100%',
    },
  },
});
