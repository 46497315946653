import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface SliderCaptionProps {
  isActive: boolean;
  imageCaption: string;
}

export const SliderCaption: React.FC<SliderCaptionProps> = ({ isActive, imageCaption }) => {
  return (
    <div {...stylex.props(styles.captionItem(isActive))}>
      <p {...stylex.props(styles.caption)}>{imageCaption}</p>
    </div>
  );
};
