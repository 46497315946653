import { MOCK_NEWS } from '@/shared/mockData/info-center/info-center-all-news-mock.ts';

export const getNewsItem = (id?: string) => {
  if (!id) return;

  return MOCK_NEWS.find((newsItem) => newsItem.id === id);
};

export const getRecommendedNews = () => {
  return MOCK_NEWS.filter((_, index) => index < 2);
};
