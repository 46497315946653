import * as stylex from '@stylexjs/stylex';
import { anatomy } from '@/shared/tokens/anatomy.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';

export const styles = stylex.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: anatomy['size-16'],
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      backgroundColor: palette.greyWhite,
      padding: `${anatomy['size-12']} ${anatomy['size-16']}`,
    },
  },
  mobileHide: {
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  dropdownContainer: {
    minWidth: '266px',
  },
  desktopHide: {
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    gap: '8px',
  },
  compareBtnText: {
    fontSize: '14px',
    lineHeight: anatomy['size-20'],
    paddingHorizontal: '8px',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  compareBtnContent: {
    display: 'flex',
    margin: 'auto',
    fontSize: '14px',
    lineHeight: anatomy['size-20'],
    '@media (min-width: 768px)': {
      paddingLeft: '4px',
    },
  },
  filterBtnContent: {
    display: 'flex',
    gap: '8px',
    margin: 'auto',
    fontSize: '14px',
    lineHeight: anatomy['size-20'],
    paddingLeft: '4px',
    paddingRight: '8px',
  },
});
