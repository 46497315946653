import { ApartmentCardsList } from '@/features/apartment-cards-list';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { VerticalDataGrid } from '@/features/vertical-data-grid';
import { apartments, headers } from '@/features/vertical-data-grid/ui/mockData';
import { ProgressBar } from '@/enteties/progress-bar';
import { useCallback, useRef, useState } from 'react';
import { ComparisonHeader } from '@/enteties/comparison-header';
import { CallOrderButtonsList } from '@/features/call-order-buttons-list/ui';
import useIsMobile from '@/shared/hooks/useIsMobile';

export const ComparisonPage = () => {
  const cardsRef = useRef<HTMLDivElement>(null);
  const datagridRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const commonScroll = useRef(false);
  const [currentCardWidth, setCurrentCardWidth] = useState(0);
  const { isMobile } = useIsMobile();
  const gapBetweenCardsInRem = isMobile ? 2.2222 : 1.1111;

  const handleSyncScroll = useCallback((source: 'cards' | 'datagrid') => {
    if (!cardsRef.current || !datagridRef.current || !buttonsRef.current || commonScroll.current) return;

    commonScroll.current = true;

    if (source === 'cards') {
      datagridRef.current.scrollLeft = cardsRef.current.scrollLeft;
      buttonsRef.current.scrollLeft = cardsRef.current.scrollLeft;
    } else {
      cardsRef.current.scrollLeft = datagridRef.current.scrollLeft;
      buttonsRef.current.scrollLeft = datagridRef.current.scrollLeft;
    }
    setTimeout(() => {
      commonScroll.current = false;
    }, 0);
  }, []);

  return (
    <div {...stylex.props(styles.mainContainer)}>
      <ComparisonHeader apartments={apartments} ref={headerRef} />
      <ApartmentCardsList
        apartments={apartments}
        ref={cardsRef}
        onScroll={() => handleSyncScroll('cards')}
        currentCardWidth={currentCardWidth}
        setCurrentCardWidth={setCurrentCardWidth}
        gapBetweenCardsInRem={gapBetweenCardsInRem}
      />
      <CallOrderButtonsList apartments={apartments} ref={buttonsRef} />
      <ProgressBar apartments={apartments} ref={cardsRef} />
      <VerticalDataGrid
        apartments={apartments}
        headers={headers}
        ref={datagridRef}
        currentCardWidth={currentCardWidth}
        onScroll={() => handleSyncScroll('datagrid')}
        gapBetweenCardsInRem={gapBetweenCardsInRem}
      />
    </div>
  );
};
