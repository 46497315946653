import slide from '@/shared/ui/assets/mock/sites-slider-dropdown/slide.png';

export const SitesSliderDropdownMockData = [
  {
    title: 'Третьяковская галерея и Новая Третьяковка',
    description: 'Новый центр культурной жизни в полутора километрах от дома',
    slides: [
      { alt: 'Картинка 1', src: slide },
      { alt: 'Картинка 2', src: slide },
      { alt: 'Картинка 3', src: slide },
    ],
  },
  {
    title: 'Третьяковская галерея и Новая Третьяковка',
    description: 'Новый центр культурной жизни в полутора километрах от дома',
    slides: [
      { alt: 'Картинка 1', src: slide },
      { alt: 'Картинка 2', src: slide },
      { alt: 'Картинка 3', src: slide },
    ],
  },
  {
    title: 'Третьяковская галерея и Новая Третьяковка',
    description: 'Новый центр культурной жизни в полутора километрах от дома',
    slides: [
      { alt: 'Картинка 1', src: slide },
      { alt: 'Картинка 2', src: slide },
      { alt: 'Картинка 3', src: slide },
    ],
  },
  {
    title: 'Третьяковская галерея и Новая Третьяковка',
    description: 'Новый центр культурной жизни в полутора километрах от дома',
    slides: [
      { alt: 'Картинка 1', src: slide },
      { alt: 'Картинка 2', src: slide },
      { alt: 'Картинка 3', src: slide },
    ],
  },
  {
    title: 'Третьяковская галерея и Новая Третьяковка',
    description: 'Новый центр культурной жизни в полутора километрах от дома',
    slides: [
      { alt: 'Картинка 1', src: slide },
      { alt: 'Картинка 2', src: slide },
      { alt: 'Картинка 3', src: slide },
    ],
  },
  {
    title: 'Третьяковская галерея и Новая Третьяковка',
    description: 'Новый центр культурной жизни в полутора километрах от дома',
    slides: [
      { alt: 'Картинка 1', src: slide },
      { alt: 'Картинка 2', src: slide },
      { alt: 'Картинка 3', src: slide },
    ],
  },
];
