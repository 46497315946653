import * as stylex from '@stylexjs/stylex';
import type { VarGroup } from '@stylexjs/stylex/lib/StyleXTypes';

export type TFonts = Readonly<{
  din2014Demi: string;
  din2014Regular: string;
  din2014Light: string;
  din2014ExtraLight: string;
  montserratExtraLight: string;
  montserratLight: string;
}>;
export const fonts: VarGroup<TFonts> = stylex.defineVars({
  din2014Demi: 'din2014Demi',
  din2014Regular: 'din2014Regular',
  din2014Light: 'din2014Light',
  din2014LightItalic: 'din2014LightItalic',
  din2014ExtraLight: 'din2014ExtraLight',
  din2014ExtraLightItalic: 'din2014ExtraLightItalic',
  montserratExtraLight: 'montserrat-extra-light',
  montserratLight: 'montserratLight',
});
