import { Section } from '@/enteties/employer-section';
import { ISectionItem } from '@/enteties/employer-section/model/types';

interface SectionListProps {
  mockSections: ISectionItem[];
}

export const SectionList = ({ mockSections }: SectionListProps) => {
  return (
    <>
      {mockSections.map((item: ISectionItem, i: number) => (
        <Section key={i} sectionContent={item} />
      ))}
    </>
  );
};
