import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  buttonBlock: {
    padding: '0px 1.1111rem', // 16px / 14.4
    '@media (max-width: 767px)': {
      padding: '0px 3.3333rem', // 12px / 3.6
    },
  },
  button: {
    display: 'flex',
    width: '12.2222rem', // 176px / 14.4
    padding: '0.5556rem 1.3889rem', // 8px 20px / 14.4
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5556rem', // 8px / 14.4
    gap: '0.5556rem', // 8px / 14.4
    flex: '0 0 auto',
    backgroundColor: colors.primaryInverseBackground,
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      width: '37.7778rem', // 136px / 3.6
      padding: '2.2222rem 4.4444rem', // 8px 16px / 3.6
      borderRadius: '2.2222rem', // 8px / 3.6
      gap: '2.2222rem', // 8px / 3.6
    },
  },
  buttonText: {
    color: colors.primaryInverseForeground,
    fontSize: '0.9722rem', // 14px / 14.4
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142.857%',
    '@media (max-width: 767px)': {
      fontSize: '3.8889rem', // 14px
    },
  },
});
