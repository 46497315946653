import React from 'react';

interface CircleProps {
  pathRef: React.Ref<SVGPathElement> | null;
}

export const Circle: React.FC<CircleProps> = ({ pathRef }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 531 530" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M530.5 265C530.5 411.078 411.856 529.5 265.5 529.5C119.144 529.5 0.5 411.078 0.5 265C0.5 118.922 119.144 0.5 265.5 0.5C411.856 0.5 530.5 118.922 530.5 265Z"
        stroke="currentColor"
        strokeWidth="1"
        ref={pathRef}
      />
    </svg>
  );
};
