import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    position: 'relative',
    display: 'inline-block',
    userSelect: 'none',
    fontFamily: fonts.din2014Regular,
  },
  popover: (position) => ({
    backgroundColor: colors.primaryInverseBackground,
    padding: '4px 8px',
    borderRadius: '4px',
    gap: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: position === 'bottom' ? '25%' : '-25px',
    transform: 'translateX(-50%)',
  }),

  popoverContent: {
    color: colors.primaryInverseText,
    fontSize: '12px',
    lineHeight: '12px',
    fontStyle: 'normal',
  },
});
