export const PhoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="M27.5511 25.028C27.5511 25.028 26.0062 26.5454 25.6276 26.9902C25.0109 27.6483 24.2843 27.9591 23.3317 27.9591C23.2401 27.9591 23.1424 27.9591 23.0508 27.953C21.2372 27.8373 19.5519 27.1304 18.2879 26.5271C14.8318 24.8574 11.7969 22.4869 9.27506 19.4827C7.19282 16.9781 5.80059 14.6625 4.87855 12.1762C4.31066 10.6589 4.10305 9.47667 4.19464 8.36151C4.25571 7.64854 4.53049 7.05744 5.03731 6.55166L7.11954 4.47367C7.41875 4.19336 7.73628 4.04102 8.0477 4.04102C8.43239 4.04102 8.74381 4.27258 8.93921 4.46758C8.94532 4.47367 8.95142 4.47977 8.95753 4.48586C9.33001 4.83321 9.68417 5.19274 10.0567 5.57665C10.246 5.77165 10.4414 5.96665 10.6368 6.16775L12.3038 7.83135C12.951 8.47729 12.951 9.07448 12.3038 9.72043C12.1267 9.89715 11.9557 10.0739 11.7786 10.2445C11.2657 10.7686 11.6686 10.3664 11.1374 10.8418C11.1252 10.8539 11.113 10.86 11.1069 10.8722C10.5817 11.3963 10.6794 11.9082 10.7893 12.2555C10.7954 12.2738 10.8015 12.2921 10.8076 12.3104C11.2412 13.3585 11.8518 14.3457 12.78 15.5218L12.7861 15.5279C14.4714 17.5998 16.2483 19.2146 18.2084 20.4517C18.4588 20.6101 18.7153 20.7381 18.9595 20.8599C19.1793 20.9696 19.3869 21.0732 19.564 21.1829C19.5885 21.1951 19.6129 21.2134 19.6373 21.2256C19.8449 21.3292 20.0403 21.3779 20.2418 21.3779C20.7486 21.3779 21.0662 21.061 21.17 20.9574L22.3669 19.763C22.5745 19.5558 22.9042 19.306 23.2889 19.306C23.6675 19.306 23.9789 19.5436 24.1682 19.7508C24.1743 19.7569 24.1743 19.7569 24.1804 19.763L27.545 23.1207C28.1739 23.7422 27.5511 25.028 27.5511 25.028Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
