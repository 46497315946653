import { INewsItem } from '@/pages/info-center-all-news-page/model';
import { FloatBtnReversed } from '@/shared/ui/float-btn-reversed/float-btn-reversed.tsx';
import MainContentSection from '@/pages/info-center-news-page/ui/components/main-content-section.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-news-page/ui/styles.ts';

const OneNewsSection = ({ newsItem }: { newsItem?: INewsItem }) => {
  return (
    <div {...stylex.props([styles.container])}>
      <div {...stylex.props([styles.backBtnContainer])}>
        <div {...stylex.props([styles.backBtnWrapper])}>
          <FloatBtnReversed btnName="Назад" redirectUrl="/kompaniya/novosti" />
        </div>
      </div>
      <MainContentSection newsItem={newsItem} />
    </div>
  );
};

export default OneNewsSection;
