import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

export interface ISwitchArrows {
  showLeftArrow: boolean;
  showRightArrow: boolean;
  handleScrollLeft: () => void;
  handleScrollRight: () => void;
}

export const SwitchArrows = ({ showLeftArrow, showRightArrow, handleScrollLeft, handleScrollRight }: ISwitchArrows) => {
  return (
    <>
      {showLeftArrow && (
        <div {...stylex.props(styles.btn, styles.btnLeft)} role="button" onClick={handleScrollLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
            <path
              d="M12.291 3.75L6.04102 10L12.291 16.25"
              stroke="#1D252D"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {showRightArrow && (
        <div {...stylex.props(styles.btn, styles.btnRight)} role="button" onClick={handleScrollRight}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
            <path
              d="M7.70898 3.75L13.959 10L7.70898 16.25"
              stroke="#1D252D"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};
