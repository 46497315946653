import stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  containerImg: {
    width: '100%',
    height: '34.7916667rem',
    '@media (max-width: 767px)': {
      width: '100rem',
      height: '83.2rem',
      marginBottom: '0rem',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    alignSelf: 'stretch',
  },
});
