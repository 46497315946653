export const ThreeDotsIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / More">
        <path id="Vector" d="M12.0098 12L11.9997 12" stroke="#101820" strokeWidth="2" strokeLinecap="round" />
        <path id="Vector_2" d="M6.00977 12L5.99974 12" stroke="#101820" strokeWidth="2" strokeLinecap="round" />
        <path id="Vector_3" d="M18.0098 12L17.9997 12" stroke="#101820" strokeWidth="2" strokeLinecap="round" />
      </g>
    </svg>
  );
};
