import { Footer } from '@/features/footer';
import { Header } from '@/features/header';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { Outlet } from 'react-router-dom';

export const ElectorLayout = () => {
  const { isMobile } = useIsMobile();
  return (
    <>
      {!isMobile && <Header />}
      <Outlet />
      <Footer />
    </>
  );
};
