import * as stylex from '@stylexjs/stylex';
import { colors } from '@/shared/tokens/colors.stylex.ts';

export const styles = stylex.create({
  container: {
    width: '100%',
    paddingVertical: '6rem',
    backgroundColor: colors.brandGhostBackground,
    '@media (max-width: 767px)': {
      paddingTop: '21.33rem',
    },
  },
  desktopHide: {
    '@media (min-width: 768px)': {
      display: 'none',
    },
    paddingTop: '16px',
    paddingBottom: '8px',
  },
  cardsContainer: {
    paddingHorizontal: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(234px, 1fr))',
    gap: '16px',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gap: '8px',
    },
  },
  moreContainer: { backgroundColor: colors.brandGhostBackground },
  headerWrapper: {
    paddingHorizontal: '16px',
    '@media (max-width: 767px)': {
      paddingHorizontal: '0px',
    },
  },
});
