export const FavSmallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.0623 2.93564C11.796 3.02079 12.3334 3.65337 12.3334 4.39203V14.7207L8.00008 12.2207L3.66675 14.7207V4.39203C3.66675 3.65337 4.20414 3.02079 4.93787 2.93564C6.16112 2.79368 6.73875 2.7207 8.00008 2.7207C9.26142 2.7207 9.83904 2.79368 11.0623 2.93564Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
