import { ITextType } from '@/features/text-block-with-enumeration/model/types';

export const textBlockFriendsLab: ITextType = {
  header: 'ПРОСТРАНСТВО ОТНОШЕНИЙ',
  description: `FINE DEVELOPMENT — это и качество человеческих отношений. 
  Мы расширяем пространство общения жителей, помогая создать атмосферу респектабельного добрососедского комьюнити, 
  дающую уютное ощущение «дома вне дома». Наши элегантные общественные гостиные Friend’s Lab поддерживают 
  концептуальный дизайн дома, сохраняют ощущение гармонично бесшовной среды и расширяют ваше жизненное пространство. 
  Мы уверены, что Friend’s Lab будет на третьем месте после квартиры и работы, 
  здесь комфортно проводить досуг и общаться с соседями.`,
  descriptionList: 'Здесь мы создали уютную, красивую и непринужденную среду, позволяющую:',
  list: [
    'Поработать или отдохнуть',
    'Выпить кофе, пока дети играют в Kid’s Lab',
    'Познакомиться с соседями',
    'Отметить праздник',
    'Заняться хобби',
  ],
};
