import { ContactsContent } from './content/сontacts-сontent';
import { LocationData } from '../model/contacts-data-types';
import styles from './contacts-content-list.module.css';

interface ContactsContentListProps {
  selectedIndex: number;
  setSelectedIndex: (id: number) => void;
  locationData: LocationData[];
  isMobile: boolean;
}

export const ContactsContentList = ({
  selectedIndex,
  locationData,
  setSelectedIndex,
  isMobile,
}: ContactsContentListProps) => {
  const handleBackToMenu = () => {
    setSelectedIndex(-1);
  };

  return (
    <div
      className={`
    ${styles.contactsContentContainer} 
    `}
    >
      {locationData.map((data, index) => (
        <div
          key={index}
          className={`${styles.contentBlock} ${
            isMobile ? (selectedIndex === index ? styles.visible : styles.hidden) : ''
          } ${selectedIndex === index ? styles.visibleContent : styles.hiddenContent}`}
        >
          <ContactsContent data={data} handleBackToMenu={handleBackToMenu} />
        </div>
      ))}
    </div>
  );
};
