import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    width: '100%',
    height: '66.66667rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    position: 'relative',
    overflow: 'hidden',

    '@media (max-width: 767px)': {
      height: '227.2rem',
    },
  },
  circleBlock: {
    width: '46.875rem',
    height: '46.875rem',
    flexShrink: '0',
    position: 'relative',
    overflow: 'hidden',

    '@media (max-width: 767px)': {
      width: '122.13333rem',
      height: '122.13333rem',
    },
  },
  svgMob: {
    transform: 'rotate(-180deg)',
  },
  topTextBlock: {
    display: 'flex',
    width: '100%',
    padding: '1.04167rem 11.04167rem 1.04167rem 11.04167rem',
    alignItems: 'center',
    alignSelf: 'stretch',
    position: 'absolute',
    top: '0',

    '@media (max-width: 767px)': {
      padding: '0rem 4rem 0rem 4.26667rem',
      top: '1.2%',
    },
  },
  topText: {
    width: '30.41667rem',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '3.125rem',
    lineHeight: '4.16667rem',

    '@media (max-width: 767px)': {
      flex: '1 0 0',
      width: '100%',
      fontSize: '8rem',
      lineHeight: '10.66667rem',
    },
  },
  middleTextBlock: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '30.67708rem',
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',

    '@media (max-width: 767px)': {
      width: '77.6rem',
    },
  },
  middleText: {
    textAlign: 'center',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '3.125rem',
    lineHeight: '4.16667rem',

    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.46667rem',
    },
  },
  textWithImageBlockWrapper: {
    position: 'relative',
    width: '100%',
    height: '30.20833rem',

    '@media (max-width: 767px)': {
      height: '128.53333rem',
    },
  },
  textWithImageBlock: {
    display: 'flex',
    width: '100%',
    padding: '0rem 3.125rem 0rem 11.04167rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    position: 'absolute',
    bottom: '0',

    '@media (max-width: 767px)': {
      padding: '0rem 0rem 0rem 0rem',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      gap: '8.53333rem',
    },
  },
  textBlock: {
    display: 'flex',
    width: '30.41667rem',
    alignItems: 'flex-start',

    '@media (max-width: 767px)': {
      order: '2',
      width: '100%',
      padding: '0rem 4rem 0rem 4.26667rem',
    },
  },
  text: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.04167rem',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
  imgBlock: {
    borderRadius: '0rem 0rem 0rem 2.91667rem',
    overflow: 'hidden',

    '@media (max-width: 767px)': {
      order: '1',
      borderRadius: '0rem 0rem 0rem 14.93333rem',
    },
  },
  img: {
    width: '46.25rem',
    height: '30.20833rem',
    objectFit: 'cover',

    '@media (max-width: 767px)': {
      order: '1',
      width: '95.73333rem',
      height: '68.928rem',
    },
  },
});
