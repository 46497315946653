import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { HeroIconsOutline } from '@/shared/ui/icons/HeroIconsOutline';
import { useNavigate } from 'react-router-dom';

const BackBtn = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <button onClick={handleBack} {...stylex.props(styles.backBtnContainer)}>
      <div {...stylex.props(styles.iconBtnContainer)}>
        <HeroIconsOutline />
      </div>
      Назад
    </button>
  );
};

export default BackBtn;
