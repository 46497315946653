import firstVideoFineDev from '@/shared/ui/videos/sm-finedev.mp4';
import parkingIntro from '@/shared/ui/videos/smx-parking-intro.mp4';
import parkingReel from '@/shared/ui/videos/smx-parking-reel-2.mp4';

export const firstVideoFineDevelopment = [
  {
    previewUrl: firstVideoFineDev,
    showButton: false,
  },
  {
    previewUrl: parkingIntro,
    videoUrl: parkingReel,
  },
];
