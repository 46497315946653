import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface SliderCounterProps {
  currentIndex: number;
  imagesLength: number;
}

export const SliderCounter: React.FC<SliderCounterProps> = ({ currentIndex, imagesLength }) => {
  return (
    <div {...stylex.props(styles.containerOfNumberPage)}>
      <div {...stylex.props(styles.numberCurrentPage)}>{currentIndex + 1}</div>
      <div {...stylex.props(styles.numberCurrentPage)}>/</div>
      <div {...stylex.props(styles.totalPage)}>{imagesLength}</div>
    </div>
  );
};
