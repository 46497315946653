export const Quote = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 21C0.875 18.75 2.775 12.15 6.375 3.75H10.875C10 7.5 8.175 16.2 7.875 21H0.375ZM13.125 21C13.625 18.75 15.525 12.15 19.125 3.75H23.625C22.75 7.5 20.925 16.2 20.625 21H13.125Z"
      />
    </svg>
  );
};
