import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  phoneNumber: (isClicked: boolean) => ({
    cursor: 'pointer',
    color: isClicked ? colors.secondaryInverseText : colors.primaryIcon,
    textDecoration: 'none',
    fontWeight: 300,
    display: {
      default: 'flex',
      '@media (max-width: 767px)': 'none',
    },
    alignItems: 'center',
  }),
  phoneIcon: (isClicked: boolean, headerType: string) => ({
    fill: 'none',
    display: 'none',
    '@media (max-width: 767px)': {
      ':not(#__unused__) > svg': {
        width: headerType === 'selection' ? '6.666rem' : '8.533rem',
        height: headerType === 'selection' ? '6.666rem' : '8.533rem',
        stroke: isClicked ? '#fff' : colors.primaryIcon,
      },
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  }),
});
