import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ApartmentCard } from '@/enteties/apartment-card/ui';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import React, { useEffect, useState } from 'react';
import { smoothScrollTo } from '@/shared/utils/scroll-polyfill';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { SwitchArrows } from './SwitchArrows';

export interface IApartmentCardsList {
  apartments: IApartment[];
  onScroll?: () => void;
  currentCardWidth: number;
  setCurrentCardWidth: (value: number) => void;
  gapBetweenCardsInRem: number;
}

export const ApartmentCardsList = React.forwardRef<HTMLDivElement, IApartmentCardsList>(
  ({ apartments, onScroll, currentCardWidth, setCurrentCardWidth, gapBetweenCardsInRem }, ref) => {
    const { isMobile } = useIsMobile();
    const [startPosition, setStartPosition] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const columnWidth = Math.round(currentCardWidth + (gapBetweenCardsInRem * window.innerWidth) / 100);

    useEffect(() => {
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < (apartments.length - 2) * columnWidth);
    }, [scrollLeft, currentCardWidth]);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      e.stopPropagation();
      e.preventDefault();
      setIsDragging(true);
      setStartPosition(e.pageX - ref.current.offsetLeft);
      setScrollLeft(ref.current.scrollLeft);
      ref.current.style.cursor = 'grabbing';
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (!isDragging || !ref || typeof ref === 'function' || !ref.current) return;
      const x = e.pageX - ref.current.offsetLeft;
      const walk = (x - startPosition) * 0.75;
      ref.current.scrollLeft = scrollLeft - walk;
    };
    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isDragging || !ref || typeof ref === 'function' || !ref.current) return;
      const x = e.touches[0].pageX - ref.current.offsetLeft;
      const walk = (x - startPosition) * 0.75;
      ref.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      setScrollLeft(ref.current.scrollLeft);
      setIsDragging(false);
      ref.current.style.cursor = 'grab';
      snapToClosestCard();
    };
    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isMobile || !ref || typeof ref === 'function' || !ref.current) return;
      setIsDragging(true);
      setStartPosition(e.touches[0].pageX - ref.current.offsetLeft);
      setScrollLeft(ref.current.scrollLeft);
    };
    const handleTouchEnd = () => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      setIsDragging(false);
      snapToClosestCard();
    };
    const snapToClosestCard = () => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      const currentScrollLeft = ref.current.scrollLeft;
      const snappedScrollLeft = Math.round(currentScrollLeft / columnWidth) * columnWidth;
      smoothScrollTo(ref.current, snappedScrollLeft, 300);
      setScrollLeft(snappedScrollLeft);
    };

    const handleScrollLeft = () => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      const snappedScrollLeft = Math.round(ref.current.scrollLeft / columnWidth) * columnWidth - columnWidth;
      smoothScrollTo(ref.current, snappedScrollLeft, 300);
      setScrollLeft(snappedScrollLeft);
    };

    const handleScrollRight = () => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      const snappedScrollLeft = Math.round(ref.current.scrollLeft / columnWidth) * columnWidth + columnWidth;
      smoothScrollTo(ref.current, snappedScrollLeft, 300);
      setScrollLeft(snappedScrollLeft);
    };
    return (
      <div {...stylex.props(styles.containerWrapper)}>
        <div {...stylex.props(styles.container)}>
          <div
            {...stylex.props(styles.container)}
            ref={ref}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onScroll={onScroll}
          >
            {apartments.map((apartment, index) => (
              <ApartmentCard
                id={`apartment-card-${index}`}
                apartment={apartment}
                key={apartment.id}
                setCurrentCardWidth={setCurrentCardWidth}
              />
            ))}
          </div>
        </div>
        {isMobile && (
          <SwitchArrows
            showLeftArrow={showLeftArrow}
            showRightArrow={showRightArrow}
            handleScrollLeft={handleScrollLeft}
            handleScrollRight={handleScrollRight}
          />
        )}
      </div>
    );
  }
);
