import React from 'react';
import { pageConfig } from '../model/mock-fine-development-page';
import { renderComponent } from '@/shared/libs/dynamic-components-render';

export const FineDevelopmentPage = () => {
  return (
    <div>
      {pageConfig().map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </div>
  );
};
