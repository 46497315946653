import React from 'react';

interface MiniPathProps {
  strokeColor?: string;
  pathRef?: React.Ref<SVGPathElement> | null;
}

export const MiniPath: React.FC<MiniPathProps> = ({ strokeColor, pathRef }) => {
  return (
    <path
      d="M402.5.5c222,0,402,180,402,402a400.68,400.68,0,0,1-31.39,156"
      stroke={strokeColor ? strokeColor : 'black'}
      fill="none"
      strokeWidth="1px"
      ref={pathRef ? pathRef : null}
    ></path>
  );
};
