import { CURRENCY_ENUM } from '@/shared/model/roompicker.ts';

const ONE_LAST_DIGIT_DIVIDER = 10;
const TWO_LAST_DIGITS_DIVIDER = 100;
const FIRST_DECADE_START = 10;
const SECOND_DECADE_START = 20;
const NUMBER_ONE = 1;
const NUMBER_FIVE = 5;

export const getWordEnding = (number?: number, wordEndings?: [one: string, two: string, five: string]) => {
  if (typeof number !== 'number' || !wordEndings) return '';

  const twoLastDigits = Math.abs(number) % TWO_LAST_DIGITS_DIVIDER;
  const oneLastDigit = number % ONE_LAST_DIGIT_DIVIDER;
  if (twoLastDigits > FIRST_DECADE_START && twoLastDigits < SECOND_DECADE_START) return wordEndings[2];
  if (oneLastDigit > NUMBER_ONE && oneLastDigit < NUMBER_FIVE) return wordEndings[1];
  if (oneLastDigit == NUMBER_ONE) return wordEndings[0];
  return wordEndings[2];
};

export const getNumberWithSpaces = (number?: number) => {
  if (!number) return '';
  return number.toLocaleString('ru-RU');
};

export const currencySign = (code = CURRENCY_ENUM.RUB) => {
  const currencySymbols = {
    [CURRENCY_ENUM.RUB]: '₽',
    [CURRENCY_ENUM.USD]: '$',
    [CURRENCY_ENUM.EUR]: '€',
  };

  return currencySymbols[code] || currencySymbols[CURRENCY_ENUM.RUB];
};
