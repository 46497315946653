import styles from './swiper-slider-buttons.module.css';

import { ChevronRightIcon } from '@/shared/ui/icons/chevron-right-icon';
import { ChevronLeftIcon } from '@/shared/ui/icons/chevron-left-icon';

interface ISliderButtonsProps {
  id: string;
  btnsPosition?: 'bottom-left' | 'bottom-right' | 'center';
}

const SwiperSliderButtons = ({ id, btnsPosition = 'center' }: ISliderButtonsProps) => {
  return (
    <>
      <div data-id={`next-${id}`} className={styles.nextButton} data-position={btnsPosition}>
        <ChevronRightIcon />
      </div>
      <div data-id={`prev-${id}`} className={styles.prevButton} data-position={btnsPosition}>
        <ChevronLeftIcon />
      </div>
    </>
  );
};

export default SwiperSliderButtons;
