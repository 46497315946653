export const RowsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M3.125 5.7207C3.125 4.68517 3.96447 3.8457 5 3.8457H15C16.0355 3.8457 16.875 4.68517 16.875 5.7207V7.5957C16.875 8.63124 16.0355 9.4707 15 9.4707H5C3.96447 9.4707 3.125 8.63124 3.125 7.5957V5.7207Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.125 13.8457C3.125 12.8102 3.96447 11.9707 5 11.9707H15C16.0355 11.9707 16.875 12.8102 16.875 13.8457V15.7207C16.875 16.7562 16.0355 17.5957 15 17.5957H5C3.96447 17.5957 3.125 16.7562 3.125 15.7207V13.8457Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
