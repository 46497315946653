import { FloatBtn } from '@/shared/ui/float-btn/float-btn.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-news-page/ui/styles.ts';

const AllNewsBtn = () => {
  return (
    <div {...stylex.props([styles.allNewsBtnWrapper])}>
      <FloatBtn btnName="ВСЕ НОВОСТИ" redirectUrl="/kompaniya/novosti/" />
    </div>
  );
};

export default AllNewsBtn;
