import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  progressBarContainerWrapper: {
    backgroundColor: colors.brandGhostBackground,
    width: '92.2222rem', // '1328px',
    height: '0.5556rem', // 8px / 14.4
    marginBottom: '2.2222rem', // 32px / 14.4
    marginTop: '2.2222rem', // 32px / 14.4
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    boxShadow: `0 0 0.4167rem  ${colors.brandGhostBackground}`,
    transform: 'translateY(0)',
    transition: 'transform 0.6s ease, opacity 0.6s ease',
    position: 'sticky',
    top: '27.22rem',
    zIndex: '99',
    flexWrap: 'nowrap',
    '@media (max-width: 767px)': {
      width: '91.1111rem', // '328px',
      marginBottom: '6.6667rem', // 24px / 3.6
      marginTop: '6.6667rem', // 24px / 3.6
      top: '84.2rem', // 304px / 3.6
      height: '2.5rem', // 8px
    },
  },
  progressBarContainer: {
    width: '92.2222rem', // '1328px',
    height: '0.0694rem',
    backgroundColor: colors.tertiaryBorder,
    boxShadow: `0 0 1.6667rem  ${colors.brandGhostBackground}`,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 767px)': {
      height: '0.2778rem',
      width: '91.1111rem',
    },
  },

  progress: (width) => ({
    width: `${width}%`,
    height: '0.5556rem', // 8px / 14.4
    backgroundColor: colors.tertiaryBackground,
    transition: 'width 0.1s ease',
    borderRadius: '0.5556rem',
    '@media (max-width: 767px)': {
      height: '2.2222rem', // 8px
      borderRadius: '2.2222rem', // 8px
    },
  }),
});
