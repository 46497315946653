import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  comparisonHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '92.2222rem', // 1328px / 14.4
    marginTop: '1.6667rem', // 24px / 14.4
  },

  primaryForegroundColor: {
    color: colors.primaryForeground,
  },

  backBtnContainer: {
    display: 'flex',
  },
  iconWrapper: {
    width: '1.389rem',
    height: '1.389rem',
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5556rem 1.3889rem 0.5556rem 0.8333rem', // 8px 20px 8px 12px
    gap: '0.5556rem', // 8px / 14.4
    borderRadius: '0.5556rem', // 8px / 14.4
    backgroundColor: colors.primaryBackground,
    cursor: 'pointer',
  },
  headerBtnText: {
    color: colors.primaryForeground,
    fontSize: '0.9722rem', // 14px / 14.4
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.3889rem', // 20px / 14.4
    display: 'flex',
    gap: '0.5556rem', // 8px / 14.4
    cursor: 'pointer',
    padding: '0.5556rem 1.3889rem 0.5556rem 0.8333rem', // 8px 20px 8px 12px
  },
  headerBtnTextToCompare: {
    padding: '0.5556rem 0', // 8px / 14.4
  },
  backBtnText: {
    padding: 0,
  },
  backBtnTextTertiary: {
    color: colors.tertiaryForeground,
  },
  header: {
    color: colors.primaryText,
    fontSize: '3.0556rem', // 44px / 14.4
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '118.1818%', // 52px / 14.4
    marginBottom: '1.1111rem', // 16px / 14.4
    marginTop: '1.6667rem', // 24px / 14.4
  },
  objectsAndBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  actionBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1.6667rem', // 24px / 14.4
  },
});
