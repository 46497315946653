import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ITextType } from '../model/types';
import { UlIcon } from '@/shared/ui/icons/ul-icon';

interface TextBlockEnumerationProps {
  textBlock: ITextType;
}

export const TextBlockEnumeration: React.FC<TextBlockEnumerationProps> = ({ textBlock }) => {
  return textBlock ? (
    <div {...stylex.props(styles.container)}>
      {textBlock.header && <p {...stylex.props(styles.header)}>{textBlock.header}</p>}
      <div {...stylex.props(styles.descriptionWithlist)}>
        {textBlock.description && <p {...stylex.props(styles.description)}>{textBlock.description}</p>}
        {(textBlock.descriptionList || textBlock.list) && (
          <div {...stylex.props(styles.descriptionListWithList)}>
            {textBlock.descriptionList && <p {...stylex.props(styles.descriptionList)}>{textBlock.descriptionList}</p>}
            {textBlock.list ? (
              <ul {...stylex.props(styles.list)}>
                {textBlock.list?.map((element, index) => (
                  <li key={index} {...stylex.props(styles.oneElement)}>
                    <span {...stylex.props(styles.svgIcon)}>
                      <UlIcon />
                    </span>
                    <span {...stylex.props(styles.elementText)}>{element}</span>
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    ''
  );
};
