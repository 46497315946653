import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface ContactsMenuItemProps {
  title: string;
  onClick: () => void;
  isLastBuilding?: boolean;
  isSelected: boolean
}

export const ContactsMenuItem = ({ title, onClick, isLastBuilding,isSelected }: ContactsMenuItemProps) => {
  return (
    <>
      <li {...stylex.props(styles.menuItem(isSelected))} onClick={onClick}>
        {title}
      </li>
      {isLastBuilding && <li {...stylex.props(styles.underLine)} />}
    </>
  );
};
