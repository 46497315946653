import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import { ApartmentCardPlan } from './plan';
import { ApartmentCardInfoBlock } from './infoblock';
import { useEffect, useRef } from 'react';

export interface IApartmentCardProps {
  apartment: IApartment;
  id: string;
  setCurrentCardWidth: (value: number) => void;
}

export const ApartmentCard = ({ apartment, id, setCurrentCardWidth }: IApartmentCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref || !ref.current) return;
    setCurrentCardWidth(ref.current.clientWidth);
  }, [ref?.current?.clientWidth]);
  return (
    <div {...stylex.props(styles.container)} id={id} role="button" onClick={() => {}} ref={ref}>
      <ApartmentCardPlan apartment={apartment} />
      <ApartmentCardInfoBlock apartment={apartment} />
    </div>
  );
};
