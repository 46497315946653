import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ContactsMenuItem } from '@/enteties/contacts-menu-item';
import { LocationData } from '@/features/contacts-content/model/contacts-data-types';
import { lastBuildingIndex } from '@/pages/contact-page/lib/lastIndex';

interface ContactsMenuItemListProps {
  selectedIndex: number;
  locationData: LocationData[];
  setIsVisible: (item: boolean) => void;
  setSelectedIndex: (id: number) => void;
}

export const ContactsMenuItemList = ({
  selectedIndex,
  locationData,
  setSelectedIndex,
}: ContactsMenuItemListProps) => {

  const handleMenuClick = (_: LocationData, index: number) => {
    if (selectedIndex === index) return; // Если тот же элемент выбран, ничего не делаем
      setSelectedIndex(index); // Меняем выбранный элемент
  };
  return (
    <ul {...stylex.props(styles.menu, selectedIndex !== -1 && styles.menuHidden)}>
      {locationData.map((item, index) => (
        <ContactsMenuItem
          key={index}
          title={item.title}
          onClick={() => handleMenuClick(item, index)}
          isSelected={index === selectedIndex}
          isLastBuilding={index === lastBuildingIndex}
        />
      ))}
    </ul>
  );
};
