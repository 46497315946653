import { anatomy } from '@/shared/tokens/anatomy.stylex';
import { colors } from '@/shared/tokens/colors.stylex';
import { typography } from '@/shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';

export const styles = stylex.create({
  base: {
    position: 'relative',
    borderRadius: anatomy['size-8'],
    transition: 'all 0.3s ease',
    cursor: { default: 'pointer', ':disabled': 'not-allowed' },
    border: 'none',
    outline: { ':focus': 0 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    fontFamily: fonts.din2014Regular,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  active: {
    background: colors.activeBackground,
  },
  before: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: anatomy.borderRadiusS,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    transition: 'all .3s ease-in-out',
    '@media (hover: hover)': {
      ':hover': `linear-gradient(0deg, ${colors.hoverBackground} 0%, ${colors.hoverBackground} 100%)`,
    },
    backgroundImage: {
      ':active': `linear-gradient(0deg, ${colors.activeBackground} 0%, ${colors.activeBackground} 100%)`,
    },
  },
  small: {
    height: anatomy['size-36'],
    padding: `${anatomy['size-8']}`,
    fontSize: typography['fontSizeBody-4'],
    lineHeight: typography['lineHeightBody-4'],
  },

  medium: {
    height: anatomy['size-48'],
    padding: anatomy['size-12'],
    fontSize: typography['fontSizeBody-2'],
    lineHeight: typography['lineHeightBody-2'],
  },

  large: {
    height: '60px',
    padding: anatomy['size-16'],
    fontSize: typography['fontSizeBody-1'],
    lineHeight: typography['lineHeightBody-1'],
  },

  //variants
  accent: {
    color: {
      default: colors.primaryInverseText,
      ':disabled': colors.ghostText,
    },
    background: {
      default: colors.accentBackground,
      ':disabled': colors.ghostBackground,
    },
  },

  primary: {
    color: {
      default: colors.primaryInverseText,
      ':disabled': colors.ghostText,
    },
    background: {
      default: colors.primaryInverseBackground,
      ':disabled': colors.ghostBackground,
    },
  },

  secondary: {
    color: {
      default: colors.primaryText,
      ':disabled': colors.ghostText,
    },
    background: {
      default: colors.primaryBackground,
    },
    border: {
      default: `1px ${colors.primaryBorder} solid`,
      ':disabled': `1px ${colors.ghostBorder} solid`,
    },
  },
  link: {
    color: {
      default: colors.primaryText,
      ':disabled': colors.ghostText,
    },
    background: {
      default: colors.primaryBackground,
    },
  },
  badge: {
    position: 'absolute',
    top: '-6px',
    right: '-6px',
    padding: '2px 5px',
    backgroundColor: colors.errorText,
    borderRadius: anatomy.borderRadiusRound,
    color: palette.greyWhite,
    fontSize: '10px',
    lineHeight: anatomy['size-12'],
    minWidth: '6px',
  },
});
