import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface EmployerTitleProps {
  title: string;
}

export const EmployerTitle = ({ title }: EmployerTitleProps) => {
  return (
    <h2 {...stylex.props(styles.employerTitle)} id="Правление">
      {title}
    </h2>
  );
};
