import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  backBtnContainer: {
    display: 'flex',
    padding: '0.55555556rem 1.38888889rem 0.55555556rem 0.83333333rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.55555556rem',
    borderRadius: '0.55555556rem',
    border: `1px solid ${colors.primaryBorder}`,
    background: colors.primaryBackground,
    color: colors.primaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: '0.97222222rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.38888889rem',
    '@media (max-width: 767px)': {
      padding: '2.22222222rem 5.55555556rem 2.22222222rem 3.33333333rem',
      gap: '2.22222222rem',
      borderRadius: '2.22222222rem',
      fontSize: '3.88888889rem',
      lineHeight: '5.55555556rem',
    },
  },
  iconBtnContainer: {
    width: '1.388888888888889rem',
    height: '1.388888888888889rem',
    '@media (max-width: 767px)': {
      width: '5.5555555555555556rem',
      height: '5.5555555555555556rem',
    },
  },
});
