import { colors } from '@/shared/tokens/colors.stylex';
import React, { useLayoutEffect, useRef } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { ImageType } from '../model/types';
import { MobilePath } from './mobile-path';
import { MiniPath } from './mini-path';
import { BigPath } from './big-path';
import { TextWithImageBlock } from './text-with-image-block';

export interface BigCircleProgressBarProps {
  image: ImageType;
  topText: string;
  middleText: string;
  textWithImage: string;
}

gsap.registerPlugin(ScrollTrigger);

export const BigCircleProgressBar: React.FC<BigCircleProgressBarProps> = ({
  image,
  topText,
  middleText,
  textWithImage,
}) => {
  const pathOneRef = useRef<SVGPathElement | null>(null);
  const pathTwoRef = useRef<SVGPathElement | null>(null);
  const pathMobRef = useRef<SVGPathElement | null>(null);
  const topTextRef = useRef<HTMLDivElement | null>(null);
  const textWithImageRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useIsMobile();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const lengthOne = pathOneRef.current?.getTotalLength();
      const lengthTwo = pathTwoRef.current?.getTotalLength();
      const lengthMob = pathMobRef.current?.getTotalLength();

      isMobile
        ? gsap.set(pathMobRef.current, { strokeDasharray: lengthMob, strokeDashoffset: lengthMob })
        : (gsap.set(pathOneRef.current, { strokeDasharray: lengthOne, strokeDashoffset: lengthOne }),
          gsap.set(pathTwoRef.current, { strokeDasharray: lengthTwo, strokeDashoffset: lengthTwo && lengthTwo }));

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: isMobile ? pathMobRef.current : pathOneRef.current,
          start: 'top 75%',
          end: 'top top',
          scrub: 0.7,
          invalidateOnRefresh: true,
        },
      });

      isMobile
        ? tl.add('start').to(pathMobRef.current, { strokeDashoffset: '0' }, 'start')
        : tl
            .add('start')
            .to(pathOneRef.current, { strokeDashoffset: '0' }, 'start')
            .to(pathTwoRef.current, { strokeDashoffset: '0' });

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: isMobile ? pathMobRef.current : pathOneRef.current,
          start: 'top 90%',
          end: 'top 50%',
          scrub: 3,
          invalidateOnRefresh: true,
          once: true,
        },
      });

      tl2
        .add('start')
        .fromTo(
          [topTextRef.current, textWithImageRef.current],
          { y: '100%' },
          { y: '0%', duration: 0.5, ease: 'power3.out' },
          'start'
        )
        .fromTo(
          [topTextRef.current, textWithImageRef.current],
          {
            clipPath: 'inset(0% 0% 100% 0%)',
            opacity: 1,
          },
          {
            clipPath: 'inset(0% 0% 0% 0%)',
            duration: 0.5,
            ease: 'power3.inOut',
          },
          'start'
        );
    });

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.topTextBlock)} ref={topTextRef}>
        <p {...stylex.props(styles.topText)}>{topText}</p>
      </div>

      <div {...stylex.props(styles.circleBlock)}>
        {isMobile ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 375 459"
            {...stylex.props(styles.svgMob)}
          >
            <MobilePath strokeColor={colors.brandBackground} />
            <MobilePath strokeColor={colors.primaryIcon} pathRef={pathMobRef} />
          </svg>
        ) : (
          <svg width="100%" height="100%" viewBox="0 0 805 805" xmlns="http://www.w3.org/2000/svg">
            <MiniPath strokeColor={colors.brandBackground} />
            <MiniPath strokeColor={colors.primaryIcon} pathRef={pathOneRef} />
            <BigPath strokeColor={colors.brandBackground} />
            <BigPath strokeColor={colors.primaryIcon} pathRef={pathTwoRef} />
          </svg>
        )}

        <div {...stylex.props(styles.middleTextBlock)}>
          <p {...stylex.props(styles.middleText)}>{middleText}</p>
        </div>
      </div>

      <TextWithImageBlock textWithImageRef={textWithImageRef} textWithImage={textWithImage} image={image} />
    </div>
  );
};
