import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './style';

interface SeparateTextBlockProps {
  text: string;
}

export const SeparateTextBlock: React.FC<SeparateTextBlockProps> = ({ text }) => {
  return <p {...stylex.props(styles.text)}>{text}</p>;
};
