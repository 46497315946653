export const CompareMediumIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.29744 11.1412C6.26614 11.3813 6.25 11.6263 6.25 11.875C6.25 12.1251 6.26632 12.3713 6.29795 12.6128M11.142 6.29732C11.3819 6.2661 11.6266 6.25 11.875 6.25C12.1234 6.25 12.3681 6.2661 12.608 6.29732M9.72044 6.6774C9.26294 6.86726 8.83592 7.11577 8.44875 7.41354M7.40965 8.45381C7.11295 8.84049 6.86534 9.26682 6.67614 9.72348M13.7026 8.85884C13.7339 8.61866 13.75 8.37371 13.75 8.125C13.75 7.87493 13.7337 7.62867 13.7021 7.38723M8.85798 13.7027C8.61807 13.7339 8.37342 13.75 8.125 13.75C7.87659 13.75 7.63193 13.7339 7.39203 13.7027M11.5512 12.5865C11.1641 12.8842 10.7371 13.1327 10.2796 13.3226M13.3239 10.2765C13.1347 10.7332 12.8871 11.1595 12.5903 11.5462M17.5 11.875C17.5 14.9816 14.9816 17.5 11.875 17.5C9.53106 17.5 7.52196 16.0663 6.67678 14.0281C6.60693 13.8596 6.54504 13.687 6.49155 13.5108C6.31401 13.457 6.14046 13.3931 5.97081 13.3228C3.93314 12.4773 2.5 10.4685 2.5 8.125C2.5 5.0184 5.0184 2.5 8.125 2.5C10.4689 2.5 12.478 3.93367 13.3232 5.97194C13.3933 6.14094 13.4555 6.31419 13.5091 6.49103C13.6863 6.54475 13.8598 6.60698 14.0292 6.67724C16.0669 7.5227 17.5 9.53149 17.5 11.875Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  );
};
