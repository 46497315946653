import { useEffect } from 'react';

export function useResizeFeel(callback: () => void, withResizeFeel?: boolean) {
  useEffect(() => {
    if (withResizeFeel) {
      window.addEventListener('resize', callback);
    }

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);
}
