import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  text: {
    width: '38.33333rem',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.04167rem',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      width: '100%',
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
});
