import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  slider: (position?: 'left' | 'right') => ({
    position: 'relative',
    width: '46.25rem',
    height: '33.125rem',
    overflow: 'hidden',
    borderRadius: position && position === 'right' ? '56px 0px 0px 0px' : '0px 56px 0px 0px',
    touchAction: 'pan-y',

    '@media (max-width: 767px)': {
      width: '96rem',
      height: '87.46667rem',
    },
  }),
  sliderItem: (isActive) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.04167rem',
    width: '100%',
    position: 'absolute',
    zIndex: isActive ? '1' : '0',

    '@media (max-width: 767px)': {
      gap: '5.33333rem',
    },
  }),
  imageItem: (isActive) => ({
    width: '100%',
    opacity: isActive ? '1' : '0',
    transition: 'opacity 0.6s ease-in-out',
    overflow: 'hidden',
  }),
  image: {
    width: '100%',
    height: '29.58333rem',
    objectFit: 'cover',
    userSelect: 'none',

    '@media (max-width: 767px)': {
      height: '69.33333rem',
    },
  },
  captionItem: (isActive) => ({
    maxWidth: '36.45833rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    userSelect: 'none',
    opacity: isActive ? '1' : '0',
    visibility: isActive ? 'visible' : 'hidden',
    transition: 'opacity 0.5s ease-out, visibility 0.5s ease-out',
    transitionDelay: isActive ? '0.5s' : '0s',
    overflow: 'hidden',

    '@media (max-width: 767px)': {
      maxWidth: '44.8rem',
      height: '12.8rem',
    },
  }),
  caption: {
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.25rem',

    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26667rem',
    },
  },
  buttonsWithNumbersPage: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.25rem',
    position: 'absolute',
    top: '99.99%',
    left: '99.99%',
    transform: 'translate(-100%, -100%)',
    zIndex: '2',

    '@media (max-width: 767px)': {
      gap: '3.2rem',
      left: '95.55556%',
    },
  },
  containerOfNumberPage: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.20833rem',
    userSelect: 'none',

    '@media (max-width: 767px)': {
      gap: '1.06667rem',
    },
  },
  numberCurrentPage: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
  button: {
    width: '2.5rem',
    height: '2.5rem',
    position: 'relative',
    borderRadius: '1000px',
    border: `1px solid ${colors.primaryIcon}`,
    padding: '0.625rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: colors.primaryIcon,
    transition: 'background-color 0.3s ease, color 0.3s ease',
    outline: 'none',
    zIndex: '3',

    ':hover': {
      backgroundColor: 'black',
      color: 'white',
    },
    ':active': {
      opacity: '0.3',
      transition: 'opacity 0.25s ease',
      outline: 'none',
    },

    '@media (max-width: 767px)': {
      width: '12.8rem',
      height: '12.8rem',
      padding: '3.2rem',
      ':hover': {
        backgroundColor: 'transparent',
        color: colors.primaryIcon,
      },
      ':active': {
        transition: 'background-color 0.3s ease, color 0.3s ease',
        backgroundColor: 'black',
        color: 'white',
      },
      '-webkit-tap-highlight-color': 'transparent',
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
    },
  },
  rightButton: {
    border: '1px solid lightgray',
    ':hover': {
      border: `1px solid ${colors.primaryIcon}`,
    },
  },
  arrow: {
    width: '1.25rem',
    height: '1.25rem',
    flexShrink: '0',

    '@media (max-width: 767px)': {
      width: '6.4rem',
      height: '6.4rem',
    },
  },
  svgContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  svg: {
    width: '100%',
    height: '100%',
    overflow: 'visible',
  },
  sticker: {
    display: 'flex',
    width: '14.16667rem',
    height: '14.16667rem',
    padding: '0.52083rem',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '16.04167rem',
    top: '7.70833rem',
    borderRadius: '1000px',
    background: colors.overlayBackground,
    overflow: 'hidden',
    zIndex: '2',

    '@media (max-width: 767px)': {
      width: '53.33333rem',
      height: '53.33333rem',
      left: '21.06667rem',
      top: '8rem',
    },
  },
  stickerText: {
    color: colors.primaryInverseText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.875rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '2.08333rem',
    textAlign: 'center',

    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.46667rem',
    },
  },
  typeComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '29.58333rem',
    objectFit: 'cover',
    userSelect: 'none',

    '@media (max-width: 767px)': {
      height: '69.33333rem',
    },
  },
});
