import NewsColumnCard from '@/pages/info-center-all-news-page/ui/components/news-column-card.tsx';
import { INewsItem } from '@/pages/info-center-all-news-page/model';

const RecommendedNews = ({ recommendedNews }: { recommendedNews: INewsItem[] }) => {
  return (
    <div>
      {recommendedNews.map((item) => (
        <NewsColumnCard key={item.id} item={item} />
      ))}
    </div>
  );
};

export default RecommendedNews;
