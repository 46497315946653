import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 1.1111rem', // 16px / 14.4
    '@media (max-width: 767px)': {
      padding: '0px 3.3333rem', // 12px / 3.6
    },
  },
  price: {
    fontSize: '1.6667rem', // 24px / 14.4
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '133.333%',
    whiteSpace: 'nowrap',
    '@media (max-width: 767px)': {
      fontSize: '5.5556rem', // 20px / 3.6
      lineHeight: '140%',
    },
  },
  amount: {
    color: colors.primaryForeground,
  },
  currency: {
    color: colors.tertiaryForeground,
    marginLeft: '0.2778rem', // 4px / 14.4
    '@media (max-width: 767px)': {
      marginLeft: '1.1111rem', // 4px
    },
  },
  apartment: {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '0.2778rem 0.2778rem', // 4px / 14.4
    flexWrap: 'wrap',
    color: colors.tertiaryForeground,
    marginTop: '0.2778rem', // 4px / 14.4
    marginBottom: '1.1111rem', // 16px / 14.4
    '@media (max-width: 767px)': {
      marginBottom: '3.3333rem', // 12px / 3.6
      gap: '1.1111rem', //4px
      marginTop: '1.1111rem', // 4px
    },
  },

  apartmentText: {
    color: colors.tertiaryForeground,
    fontSize: '0.9722rem', // 14px / 14.4
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142.857%',
    '@media (max-width: 767px)': {
      fontSize: '3.8889rem', // 14px / 3.6
    },
  },
});
