export const CancelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_10235_10954)">
        <path d="M5.63663 18.364L18.3646 5.63604M18.3646 18.364L12.0006 12L5.63663 5.63604" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_10235_10954">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
