import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { PhoneIcon } from '@/shared/ui/icons/PhoneIcon';
import { BoldPhoneIcon } from './bold-phone-icon';

interface PhoneNumberProps {
  phone: string;
  isMenuVisible: boolean;
  headerType?: 'default' | 'selection';
}

export const PhoneNumber = ({ phone, isMenuVisible, headerType = 'default' }: PhoneNumberProps) => {
  return (
    <>
      <a href={`tel:${phone}`} {...stylex.props([styles.phoneNumber(isMenuVisible)])}>
        {phone}
      </a>
      {headerType === 'default' ? (
        <a href={`tel:${phone}`} {...stylex.props([styles.phoneIcon(isMenuVisible, headerType)])}>
          <PhoneIcon />
        </a>
      ) : (
        <a href={`tel:${phone}`} {...stylex.props([styles.phoneIcon(isMenuVisible, headerType)])}>
          <BoldPhoneIcon />
        </a>
      )}
    </>
  );
};
