import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    width: '100%',
  },
  textBlock: {
    display: 'flex',
    width: '48.95833rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.91667rem',

    '@media (max-width: 767px)': {
      width: '100%',
      gap: '8.53333rem',
      alignSelf: 'stretch',
    },
  },
  title: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: 'din2014ExtraLight',
    fontSize: '4.16667rem',
    fontStyle: 'normal',
    fontWeight: '250',
    lineHeight: '4.16667rem',

    '@media (max-width: 767px)': {
      fontSize: '10.66667rem',
      lineHeight: '10.66667rem',
    },
  },
  description: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
});
