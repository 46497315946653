export const SminexLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="-2 0 130 32" preserveAspectRatio="xMinYMin meet">
      <path d="M107.698 30.1496L115.651 20.5911L107.989 9.60973H112.149C114.192 9.60973 113.9 10.4488 117.439 15.52L118.643 17.2711L123.458 9.86511C123.604 9.64621 123.86 9.57324 124.078 9.57324H126.559L119.336 18.2562L127.69 30.1496H123.787C121.196 30.1861 121.671 29.1281 118.241 24.2759L116.344 21.5761L110.835 29.8213C110.689 30.0402 110.47 30.1131 110.178 30.1131H107.698V30.1496Z" />
      <path d="M99.3431 30.5511C96.5339 30.5511 93.6152 29.7484 91.7911 27.4865C90.0764 25.3705 89.4562 22.7437 89.3832 20.0805C89.3467 16.7605 90.1858 14.0973 91.937 12.0907C93.6882 10.0842 96.0961 9.09912 99.1606 9.09912C100.62 9.09912 101.933 9.31801 103.064 9.75581C104.195 10.1936 105.071 10.7773 105.764 11.5435C106.457 12.3096 106.968 13.1852 107.296 14.1702C107.625 15.1553 107.807 16.2498 107.844 17.4172C107.844 18.0374 107.807 18.6212 107.698 19.1319C107.698 19.2049 107.625 19.2414 107.588 19.2414H94.7462C94.8192 22.5613 95.3299 24.9327 96.2785 26.4285C97.2271 27.9243 98.7958 28.654 101.021 28.654C103.32 28.654 105.107 27.9973 106.859 26.6474C107.15 26.9028 107.479 27.596 106.859 28.1432C105.253 29.6025 102.116 30.5511 99.3431 30.5511ZM94.7097 18.2563L103.028 17.6361C103.064 14.8634 102.955 10.3031 99.2701 10.3031C95.4029 10.3031 94.8192 15.3012 94.7097 18.2563Z" />
      <path d="M86.1727 27.7785V15.7392C86.1727 11.6166 84.604 9.13574 80.5544 9.13574C77.7087 9.13574 75.009 10.4856 73.0754 12.4922C73.0754 10.8869 72.82 9.57353 71.2512 9.57353H67.9678L68.1502 11.9084V27.742L67.9678 30.1134H73.2943L73.1119 27.742V13.5866C74.6441 12.4192 76.067 11.8355 78.11 11.8355C80.7003 11.8355 81.2111 14.2433 81.2111 16.9066V27.3407C81.2111 29.0919 81.6124 30.1134 83.0717 30.1134H86.3552L86.1727 27.7785Z" />
      <path d="M62.8237 3.62677C62.8237 5.08609 61.6198 6.25355 60.1605 6.25355C58.7011 6.25355 57.4972 5.08609 57.4972 3.62677C57.4972 2.16746 58.7011 1 60.1605 1C61.6198 1 62.8237 2.16746 62.8237 3.62677Z" />
      <path d="M57.6796 9.57358L57.862 11.9085V27.7421L57.6796 30.1135H63.0061L62.8237 27.7421V12.3098C62.8237 10.741 62.5318 9.53711 60.963 9.53711H57.6796V9.57358Z" />
      <path d="M52.6449 27.7785V15.7392C52.6449 11.5801 51.0761 9.13574 47.0265 9.13574C43.6336 9.13574 41.3352 10.8504 39.511 12.784C38.8544 10.4856 37.2491 9.13574 34.1845 9.13574C31.3024 9.13574 28.9675 10.4856 27.0704 12.5286V12.4922C27.0704 10.8869 26.815 9.57353 25.2462 9.57353H21.9628L22.1452 11.9084V27.742L21.9628 30.1134H27.2893L27.1069 27.742V13.5866C28.6027 12.3462 30.3903 11.8355 31.8132 11.8355C34.6223 11.8355 34.9142 14.7176 34.9142 16.9066V27.742L34.7318 30.1134H40.0583L39.8759 27.742V13.5866C41.1528 12.4922 42.9404 11.8355 44.6187 11.8355C47.5373 11.8355 47.7197 15.0095 47.7197 16.9066V27.3407C47.7197 29.3108 48.2669 30.1134 49.5803 30.1134H52.8638L52.6449 27.7785Z" />
      <path d="M8.50059 30.5512C6.05624 30.5512 2.44443 29.858 0.255452 28.6176C0.656764 27.7055 1.4229 27.0123 2.51739 27.596C4.48747 28.6176 6.45755 29.0918 8.64652 29.0918C11.784 29.0918 14.0825 27.3407 13.9365 24.1666C13.6082 17.5268 -0.0364099 16.5417 7.30299e-05 8.55197C0.0365559 2.67822 5.32658 1 10.3977 1C12.8421 1 15.2499 1.47429 17.6213 2.42284C17.22 3.3714 16.5268 4.17401 15.3229 3.59029C13.6447 2.78766 11.9665 2.42284 10.1788 2.42284C7.07775 2.42284 5.32657 4.10105 5.32657 6.58189C5.29009 12.9299 19.1536 13.8785 19.409 22.1236C19.5549 28.1433 14.5203 30.5512 8.50059 30.5512Z" />
    </svg>
  );
};
