import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { APARTMENT_CARD_CONSTS } from '@/shared/lib/consts';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';

export interface ICallOrderButton {
  apartment: IApartment;
}
export const CallOrderButton = ({ apartment }: ICallOrderButton) => {
  return (
    <div {...stylex.props(styles.buttonBlock)}>
      <div {...stylex.props(styles.button)} role="button" onClick={() => console.log(apartment.price)}>
        <span {...stylex.props(styles.buttonText)}>{APARTMENT_CARD_CONSTS.ORDER_A_CALL}</span>
      </div>
    </div>
  );
};
