import { MockPrivacyPolicySection } from '@/features/privacy-policy-section/model/mock-privacy-section';
import { ComponentConfig } from '@/shared/libs/dynamic-components-render';

export interface CommonProps {
  [key: string]: unknown;
}

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  { component: 'BackBtn', props: {} },
  {
    component: 'PrivacyPolicySection',
    props: { sectionContent: MockPrivacyPolicySection },
  },
];
