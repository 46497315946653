import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  dropdownMenu: (isMenuOpen) => ({
    display: !isMenuOpen ? 'none' : 'inline-block',
    position: 'absolute',
    zIndex: 150,
    width: '77.5rem',
    top: '12.2222rem',
    right: 0,
    padding: '1.1111rem',
    gap: '1.1111rem',
    fontFamily: fonts.din2014Regular,
    border: `1px solid ${colors.tertiaryBorder}`,
    borderRadius: '2.2222rem',
    background: colors.primaryBackground,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  }),

  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2.2222rem 3.3333rem',
    gap: '2.2222rem',
    transition: 'all 0.3s ease-out',
    color: colors.primaryText,
  },
  dropdownText: {
    fontSize: '3.8889rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '142.857%',
    color: colors.primaryForeground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2.2222rem',
  },
  iconWrapper: {
    width: '5.5556rem',
    height: '5.5556rem',
  },
});
