const WhatsappIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.9692 2C6.46154 2 2 6.47205 2 12C2 14.1739 2.70769 16.2236 3.90769 17.8696L2.64615 21.5963L6.49231 20.354C8.06154 21.4099 9.96923 22 12 22C17.5077 22 22 17.528 22 12C21.9692 6.47205 17.4769 2 11.9692 2ZM17.8154 16.1304C17.5692 16.8137 16.3692 17.4658 15.8462 17.528C15.3231 17.5901 15.3231 17.9627 12.3385 16.8137C9.38462 15.6335 7.50769 12.6211 7.35385 12.4037C7.2 12.2174 6.15385 10.8199 6.15385 9.39131C6.15385 7.96273 6.89231 7.24845 7.16923 6.96895C7.44615 6.68944 7.75385 6.59627 7.93846 6.59627C8.12308 6.59627 8.33846 6.62733 8.49231 6.62733C8.67692 6.62733 8.89231 6.53416 9.13846 7.12422C9.38462 7.71429 9.96923 9.14286 10.0308 9.29814C10.0923 9.45342 10.1538 9.6087 10.0615 9.79503C9.96923 9.98137 9.90769 10.1056 9.78462 10.2919C9.63077 10.4472 9.47692 10.6646 9.35385 10.7888C9.2 10.9441 9.04615 11.0994 9.23077 11.3789C9.41538 11.6584 10 12.6211 10.8615 13.3975C11.9692 14.3913 12.9231 14.7019 13.2 14.8571C13.4769 15.0124 13.6615 14.9814 13.8154 14.795C14 14.6087 14.5538 13.9565 14.7385 13.646C14.9231 13.3665 15.1385 13.3975 15.3846 13.4907C15.6615 13.5839 17.0769 14.2981 17.3846 14.4534C17.6615 14.6087 17.8769 14.6708 17.9385 14.795C18.0308 14.8571 18.0308 15.4472 17.8154 16.1304Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WhatsappIcon;
