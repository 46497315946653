import { Close } from '@/shared/ui/icons/Close';
import { Menu } from '@/shared/ui/icons/MenuIcon';
import styles from './header-menu-btn.module.css';

interface HeaderMenuBtnProps {
  onClickFunc?: () => void;
  isMenuVisible: boolean;
}

export const HeaderMenuBtn = ({ onClickFunc, isMenuVisible }: HeaderMenuBtnProps) => {
  return (
    <div onClick={onClickFunc} className={styles.headerMenuBtnWrap}>
      {isMenuVisible ? <Close /> : <Menu />}
    </div>
  );
};
