export const ArrowLeftBack = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16667 14.375L5 10M5 10L9.16667 5.625M5 10H15"
        stroke="#1D252D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
