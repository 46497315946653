import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
    bgImageContainer: {
        position: 'relative',
        overflow: 'hidden',
        height: '50vh',
    },
    bgImage: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }
});