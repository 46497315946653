import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  text: (textAlign, mobTextAlign) => ({
    width: '100%',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '3.125rem',
    lineHeight: '4.16667rem',
    textAlign: textAlign ? textAlign : 'start',

    '@media (max-width: 767px)': {
      fontSize: '8rem',
      lineHeight: '10.66667rem',
      textAlign: mobTextAlign ? mobTextAlign : 'start',
    },
  }),
});
