import { useEffect, useState } from 'react';
import { useQueryParams } from '@/shared/hooks/useQueryParams.tsx';
import useIsSticky from '@/shared/hooks/useIsSticky.tsx';
import useIsMobile from '@/shared/hooks/useIsMobile.tsx';
import CategoryItem from '@/pages/info-center-all-news-page/ui/components/category-filter/ui/category-item.tsx';
import DefaultCategoryItem from '@/pages/info-center-all-news-page/ui/components/category-filter/ui/default-category-item.tsx';
import CloseOrExpandButton from '@/pages/info-center-all-news-page/ui/components/category-filter/ui/close-expand-button.tsx';
import {
  ICategoryItem,
  IFilterPanelProps,
} from '@/pages/info-center-all-news-page/ui/components/category-filter/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';
import stylesModule from '@/pages/info-center-all-news-page/ui/components/styles.module.css';

import { QUERY_PARAM_ACTIVE_CATEGORY_NAME } from '@/shared/constants/queries.ts';
import { DEFAULT_CATEGORY_ID } from '@/shared/mockData/info-center/info-center-all-news-mock.ts';

const STICKY_HEIGHT = 50;

const CategoryFilter = ({ filters }: IFilterPanelProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const { isMobile } = useIsMobile();
  const { isSticky } = useIsSticky({ stickyHeight: STICKY_HEIGHT });

  const { get, set, remove, searchParams } = useQueryParams();
  const [activeCat, setActiveCat] = useState<string | null>(get(QUERY_PARAM_ACTIVE_CATEGORY_NAME));

  useEffect(() => {
    setActiveCat(get(QUERY_PARAM_ACTIVE_CATEGORY_NAME));
  }, [searchParams]);

  const onClickFilterHandler = (selectedFilter?: ICategoryItem | null) => {
    selectedFilter?.id
      ? set(QUERY_PARAM_ACTIVE_CATEGORY_NAME, selectedFilter?.id)
      : remove(QUERY_PARAM_ACTIVE_CATEGORY_NAME);
    setExpanded(false);
  };

  const onClickCloseOrExpandHandler = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <div {...stylex.props([styles.categoryItemContainer])}>
        <DefaultCategoryItem
          isActive={activeCat === DEFAULT_CATEGORY_ID}
          onClickFilterHandler={onClickFilterHandler}
          isHidden={Boolean(isMobile && !isExpanded && activeCat)}
        />
        {filters
          .filter((category) => {
            if (!isMobile) return true;
            const isTopOrIsActive = activeCat ? category.id === activeCat : category.isTop;
            return isExpanded || isTopOrIsActive;
          })
          .map((item) => (
            <CategoryItem item={item} key={item.id} isActive={activeCat === item.id} onClick={onClickFilterHandler} />
          ))}

        {isMobile && <CloseOrExpandButton isExpanded={isExpanded} onClick={onClickCloseOrExpandHandler} />}
      </div>

      <div
        className={`${isSticky ? stylesModule.fadeInAnimation : ''} ${stylex.props([styles.categoryItemContainer, styles.categoryItemsContainerSticky, isSticky && styles.sticky, !isSticky && styles.hidden])?.className}`}
      >
        <DefaultCategoryItem
          isActive={activeCat === DEFAULT_CATEGORY_ID}
          onClickFilterHandler={onClickFilterHandler}
          isHidden={Boolean(isMobile && !isExpanded && activeCat)}
        />
        {filters
          .filter((category, index) => {
            if (!isMobile && isSticky && !isExpanded) return index < 8;
            if (!isMobile && isSticky && isExpanded) return true;

            const isTopOrIsActive = activeCat ? category.id === activeCat : category.isTop;
            return isExpanded || isTopOrIsActive;
          })
          .map((item) => (
            <CategoryItem item={item} key={item.id} isActive={activeCat === item.id} onClick={onClickFilterHandler} />
          ))}

        {isSticky && <CloseOrExpandButton isExpanded={isExpanded} onClick={onClickCloseOrExpandHandler} />}
      </div>
    </>
  );
};

export default CategoryFilter;
