export const CounterArrowsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M2.5 6.9707L6.25 3.2207M6.25 3.2207L10 6.9707M6.25 3.2207V14.4707M17.5 14.4707L13.75 18.2207M13.75 18.2207L10 14.4707M13.75 18.2207L13.75 6.9707"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
