import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface SliderStickerProps {
  sticker: string;
}

export const SliderSticker: React.FC<SliderStickerProps> = ({ sticker }) => {
  return (
    <div {...stylex.props(styles.sticker)}>
      <p {...stylex.props(styles.stickerText)}>{sticker}</p>
    </div>
  );
};
