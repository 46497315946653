import CategoryItem from '@/pages/info-center-all-news-page/ui/components/category-filter/ui/category-item.tsx';
import { ICategoryItem } from '@/pages/info-center-all-news-page/ui/components/category-filter/model/types.ts';

import { DEFAULT_CATEGORY } from '@/shared/mockData/info-center/info-center-all-news-mock.ts';

const DefaultCategoryItem = ({
  onClickFilterHandler,
  isActive,
  isHidden,
}: {
  onClickFilterHandler?: (selectedFilter: ICategoryItem) => void;
  isActive?: boolean;
  isHidden?: boolean;
}) => {
  if (isHidden) return null;
  return <CategoryItem item={DEFAULT_CATEGORY} isActive={isActive} onClick={onClickFilterHandler} />;
};

export default DefaultCategoryItem;
