import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  btn: {
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: '#FFF',
    padding: '2.2222rem', // 8px / 3.6
    width: '10rem', // 36px / 3.6
    height: '10rem', // 36px / 3.6
    borderRadius: '7.2222rem', // 26px / 3.6
    border: `1px solid ${colors.primaryBorder}`,
    opacity: '0.5',
    transition: 'background 0.3s ease, color 0.3s ease',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  btnLeft: { left: '-3.3333rem' }, // -12px / 3.6
  btnRight: {
    right: '-3.3333rem', // -12px / 3.6
  },
});
