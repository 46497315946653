import NewsColumnCard from '@/pages/info-center-all-news-page/ui/components/news-column-card.tsx';
import NewsGridCard from '@/pages/info-center-all-news-page/ui/components/news-grid-card.tsx';
import { INewsContainerProps } from '@/pages/info-center-all-news-page/model';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';
import { useQueryParams } from '@/shared/hooks/useQueryParams.tsx';
import { useEffect, useState } from 'react';
import { QUERY_PARAM_GRID_MODE_NAME } from '@/shared/constants/queries.ts';

const NewsContainer = ({ news }: INewsContainerProps) => {
  const { get, searchParams } = useQueryParams();
  const [isGridMode, setGridMode] = useState(false);

  useEffect(() => {
    setGridMode(Boolean(get(QUERY_PARAM_GRID_MODE_NAME)));
  }, [searchParams]);

  return (
    <>
      {!isGridMode && (
        <div>
          {news.map((item) => (
            <NewsColumnCard key={item.id} item={item} />
          ))}
        </div>
      )}
      {isGridMode && (
        <div {...stylex.props([styles.newsGridContainer])}>
          {news.map((item) => (
            <NewsGridCard key={item.id} item={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default NewsContainer;
