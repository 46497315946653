import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { LeftShevron } from '@/shared/ui/icons/LeftShevron';
import { COMPARE_HEADER_CONSTS } from '@/shared/lib/consts';
import { Envelope } from '@/shared/ui/icons/Envelope';
import { DownLoadIcon } from '@/shared/ui/icons/DownloadIcon';
import { Bucket } from '@/shared/ui/icons/Bucket';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';

export interface IComparisonHeaderDesktopProps {
  apartments: IApartment[];
}

export const ComparisonHeaderDesktop = ({ apartments }: IComparisonHeaderDesktopProps) => {
  return (
    <div {...stylex.props(styles.comparisonHeaderContainer)}>
      <div {...stylex.props(styles.backBtnContainer)} role="button">
        <div {...stylex.props(styles.backBtn)}>
          <div {...stylex.props(styles.iconWrapper)}>
            <LeftShevron />
          </div>
          <span {...stylex.props(styles.headerBtnText, styles.backBtnText)}>{COMPARE_HEADER_CONSTS.BACK}</span>
        </div>
      </div>
      <div {...stylex.props(styles.header)}>{COMPARE_HEADER_CONSTS.COMPARE}</div>
      <div {...stylex.props(styles.objectsAndBtns)}>
        <div {...stylex.props(styles.headerBtnText, styles.headerBtnTextToCompare)}>
          <span {...stylex.props(styles.backBtnTextTertiary)}>{COMPARE_HEADER_CONSTS.OBJECTS}</span>
          <span>{apartments.length > 0 ? apartments.length : ''}</span>
        </div>
        <div {...stylex.props(styles.actionBtns)}>
          <div {...stylex.props(styles.headerBtnText)} role="button">
            <div {...stylex.props(styles.iconWrapper)}>
              <Envelope />
            </div>
            {COMPARE_HEADER_CONSTS.SEND}
          </div>
          <div {...stylex.props(styles.headerBtnText)} role="button">
            <div {...stylex.props(styles.iconWrapper)}>
              <DownLoadIcon />
            </div>
            {COMPARE_HEADER_CONSTS.DOWNLOAD}
          </div>
          <div {...stylex.props(styles.headerBtnText)} role="button">
            <div {...stylex.props(styles.iconWrapper)}>
              <Bucket />
            </div>
            {COMPARE_HEADER_CONSTS.CLEAR}
          </div>
        </div>
      </div>
    </div>
  );
};
