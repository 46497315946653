const OkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.0001 12.1235C14.7955 12.1235 17.0612 9.85742 17.0612 7.06153C17.0611 4.26564 14.7955 2 12.0001 2C9.20372 2 6.93715 4.26564 6.93715 7.06153C6.93711 9.85742 9.20372 12.1235 12.0001 12.1235ZM12.0001 4.57127C13.3751 4.57127 14.4898 5.68603 14.4898 7.06149C14.4898 8.43699 13.3751 9.55171 12.0001 9.55171C10.6237 9.55171 9.5094 8.43699 9.5094 7.06149C9.5094 5.68603 10.6236 4.57127 12.0001 4.57127ZM17.8341 12.7412C17.5489 12.167 16.7574 11.6895 15.7051 12.5181C14.2828 13.6382 12.0001 13.6382 12.0001 13.6382C12.0001 13.6382 9.7159 13.6382 8.29355 12.5181C7.24176 11.6895 6.45077 12.167 6.16513 12.7412C5.66611 13.7417 6.22908 14.2256 7.49961 15.0411C8.58458 15.7379 10.0758 15.9981 11.0382 16.0953L10.2345 16.899C9.10267 18.0303 8.01038 19.1236 7.25206 19.8819C6.79844 20.335 6.79844 21.0699 7.25206 21.523L7.38826 21.6602C7.84188 22.1134 8.57629 22.1134 9.02987 21.6602L12.0123 18.6773C13.1451 19.8092 14.2374 20.9019 14.9957 21.6602C15.4493 22.1134 16.1837 22.1134 16.6373 21.6602L16.7735 21.523C17.2271 21.0694 17.2271 20.335 16.7735 19.8814L13.791 16.899L12.9854 16.0928C13.9488 15.9932 15.4234 15.732 16.4995 15.0411C17.7701 14.2256 18.3321 13.7417 17.8341 12.7412Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OkIcon;
