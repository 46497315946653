import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8333rem', // 12px / 14.4
    fontFamily: fonts.din2014Regular,
    width: '14.4444rem', // 208px / 14.4
    flex: '0 0 auto',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '@media (max-width: 767px)': {
      width: '44.4444rem', // 160px / 3.6
      gap: '2.2222rem', // 8px / 3.6
    },
  },
});
