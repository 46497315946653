import { SlideType } from '@/features/slider-half-screen/model/types';
import { ITextType } from '@/features/text-block-with-enumeration/model/types';
import { PageTitleType } from '@/features/title-of-page/model/types';
import detskayaChistiePrydi from '@shared/ui/assets/slider-kids-lab/detskaya-chistie-prydi.png';
import detskayaIgrovayaKomnataLavr from '@shared/ui/assets/slider-kids-lab/detskaya-igrovaya-komnata-lavr.png';
import detskayaPloschadkaDostizhenie from '@shared/ui/assets/slider-kids-lab/detskaya-ploschadka-dostizhenie.png';
import detskayaPloschadkaJazz from '@shared/ui/assets/slider-kids-lab/detskaya-ploschadka-jazz.png';
import detskayaPloschadkaMalaya19 from '@shared/ui/assets/slider-kids-lab/detskaya-ploschadka-malaya-19.png';
import detskayaPloschadkaRenome2 from '@shared/ui/assets/slider-kids-lab/detskaya-ploschadka-renome-2.png';
import detskayaPloschadkaRenome from '@shared/ui/assets/slider-kids-lab/detskaya-ploschadka-renome.png';
import detskayaPloshchadkaLavr from '@shared/ui/assets/slider-kids-lab/detskaya-ploshchadka-lavr.png';
import igrovayaDostizhenie from '@shared/ui/assets/slider-kids-lab/igrovaya-dostizhenie.png';

export const slidesKidsLab: SlideType[] = [
  {
    id: 1,
    imgSrc: detskayaIgrovayaKomnataLavr,
    alt: `${detskayaIgrovayaKomnataLavr}'s photo`,
    caption: `Детская игровая комната в «Лаврушинском»`,
  },
  {
    id: 2,
    imgSrc: detskayaPloschadkaDostizhenie,
    alt: `${detskayaPloschadkaDostizhenie}'s photo`,
    caption: `Детская площадка в «Достижении»`,
  },
  {
    id: 3,
    imgSrc: detskayaPloschadkaMalaya19,
    alt: `${detskayaPloschadkaMalaya19}'s photo`,
    caption: `Детская площадка в «Малой Ордынке 19»`,
  },
  {
    id: 4,
    imgSrc: detskayaChistiePrydi,
    alt: `${detskayaChistiePrydi}'s photo`,
    caption: `Детская игровая комната в «Чистых Прудах»`,
  },
  {
    id: 5,
    imgSrc: detskayaPloshchadkaLavr,
    alt: `${detskayaPloshchadkaLavr}'s photo`,
    caption: `Детская площадка в «Лаврушинском»`,
  },
  {
    id: 6,
    imgSrc: detskayaPloschadkaRenome,
    alt: `${detskayaPloschadkaRenome}'s photo`,
    caption: `Детская площадка в «Реномэ»`,
  },
  {
    id: 7,
    imgSrc: igrovayaDostizhenie,
    alt: `${igrovayaDostizhenie}'s photo`,
    caption: `Детская игровая комната в «Достижении»`,
  },
  {
    id: 8,
    imgSrc: detskayaPloschadkaJazz,
    alt: `${detskayaPloschadkaJazz}'s photo`,
    caption: `Детская площадка в «JAZZ»`,
  },
  {
    id: 9,
    imgSrc: detskayaPloschadkaRenome2,
    alt: `${detskayaPloschadkaRenome2}'s photo`,
    caption: `Детская площадка в «Реномэ»`,
  },
];

export const textBlockKidsLab: ITextType = {
  header: 'СЧАСТЛИВОЕ ДЕТСТВО',
  description: `FINE DEVELOPMENT проявляется в заботе о родителях и детях. Мы создали собственный стандарт детских пространств
  Kid’s Lab для гармоничного развития маленьких жителей с учётом современных знаний в детской психологии и
  педагогике. Чтобы обеспечить детям идеальное детство, а вам — удовольствие от роли родителя.`,
  descriptionList: `Принципы Kid’s Lab:`,
  list: [
    'Абстрактный образ и трансформируемость: пространство всегда остаётся интересным, не ограничивая ребёнка заданными сценариями игры и позволяя ему включить фантазию.',
    'Допустимый риск — важнейший компонент игры, который позволяет детям почувствовать дух авантюризма.',
    'Учёт возрастных особенностей: малыши (0–3 года), дошкольники (3–7 лет), школьники (7–11 лет) — интересно будет всем.',
    'Зонирование: чередование пространств для активных игр и спокойных занятий, мест, где можно и побыть одному, и вместе пошалить, — каждый ребёнок найдёт своё по настроению и темпераменту.',
    'Приглашение к общению: звуковые трубы, перископы, иллюминаторы, системы для игр с песком побуждают детей дружить и взаимодействовать.',
    'Максимально насыщенная среда включает элементы всех видов для формирования разных навыков и способностей: от мелкой моторики до ориентирования в пространстве.',
  ],
};

export const pageTitle: PageTitleType = {
  title: 'FINE DEVELOPMENT',
  description: `Мы не просто строим дома — мы создаем идеальную жилую среду. 
    Она обладает изысканным вкусом как fine wine, впечатляет подлинной роскошью как fine jewelry и 
    накапливает коллекционную ценность\u00A0как\u00A0fine\u00A0art.`,
};

export const textBlockUniqueStyle: ITextType = {
  header: 'НЕПОВТОРИМЫЙ СТИЛЬ',
  description: `Идея и концепция определяют архитектуру, внутренний и ландшафтный дизайн. Для работы над ними мы привлекаем лучших авторов. 
  Все элементы наших проектов — фасады, благоустройство двора, детская площадка, лобби — выдержаны в едином стиле. 
  Мы буквально одержимы красотой и никогда на ней не экономим. Мы скрупулезно подходим к оформлению даже второстепенных пространств: подземного паркинга, эвакуационных лестниц, жилых этажей, колясочных и т.д. 
  В наших проектах безупречно всё, даже изнанка.`,
};
