import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ArrowLeftSlider } from '@/shared/ui/icons/arrow-left-slider';
import { ArrowRightSlider } from '@/shared/ui/icons/arrow-right-slider';
import style from './slider-half-screen.module.css';

interface SliderButtonsWithCounterProps {
  handlePrevClick: () => void;
  handleNextClick: () => void;
  isPaused: boolean;
  isVisible: boolean;
  currentIndex: number;
  slidesLength: number;
}

export const SliderButtonsWithCounter: React.FC<SliderButtonsWithCounterProps> = ({
  handlePrevClick,
  handleNextClick,
  isPaused,
  isVisible,
  currentIndex,
  slidesLength,
}) => {
  return (
    <div {...stylex.props(styles.buttonsWithNumbersPage)}>
      <button {...stylex.props(styles.button)} onClick={handlePrevClick}>
        <span {...stylex.props(styles.arrow)}>
          <ArrowLeftSlider />
        </span>
      </button>
      <div {...stylex.props(styles.containerOfNumberPage)}>
        <div {...stylex.props(styles.numberCurrentPage)}>{currentIndex + 1}</div>
        <div {...stylex.props(styles.numberCurrentPage)}>/</div>
        <div {...stylex.props(styles.numberCurrentPage)}>{slidesLength}</div>
      </div>
      <button {...stylex.props(styles.button, !isPaused && isVisible && styles.rightButton)} onClick={handleNextClick}>
        <span {...stylex.props(styles.arrow)}>
          <ArrowRightSlider />
        </span>
        <div {...stylex.props(styles.svgContainer)}>
          <svg viewBox="0 0 48 48" {...stylex.props(styles.svg)}>
            <circle
              cx="24"
              cy="24"
              r="24"
              fill="none"
              stroke="#000"
              strokeWidth="1"
              strokeDasharray="0px, 150.79632px"
              strokeDashoffset="-150.79632px"
              className={!isPaused && isVisible ? style.progressCircle : ''}
            />
          </svg>
        </div>
      </button>
    </div>
  );
};
