import { useEffect, useState } from 'react';
import { useQueryParams } from '@/shared/hooks/useQueryParams.tsx';
import { FloatBtn } from '@/shared/ui/float-btn/float-btn.tsx';
import ButtonWithIcon from '@/pages/info-center-all-news-page/ui/components/view-switch-section/ui/button-with-icon.tsx';

import { MenuListIcon } from '@/shared/ui/icons/menu-list.tsx';
import { MenuBoxIcon } from '@/shared/ui/icons/menu-box.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';

import { QUERY_PARAM_GRID_MODE_NAME } from '@/shared/constants/queries.ts';

const ViewSwitchSection = () => {
  const { get, set, remove, searchParams } = useQueryParams();
  const [isGridMode, setGridMode] = useState(false);

  useEffect(() => {
    setGridMode(Boolean(get(QUERY_PARAM_GRID_MODE_NAME)));
  }, [searchParams]);

  const gridModeHandler = () => set(QUERY_PARAM_GRID_MODE_NAME, 'true');
  const listModeHandler = () => remove(QUERY_PARAM_GRID_MODE_NAME);

  return (
    <div {...stylex.props([styles.viewSwitchContainer])}>
      <div {...stylex.props([styles.viewSwitcher])}>
        <ButtonWithIcon title="Списком" isActive={!isGridMode} onClick={listModeHandler} icon={<MenuListIcon />} />
        <ButtonWithIcon title="Плиткой" isActive={isGridMode} onClick={gridModeHandler} icon={<MenuBoxIcon />} />
      </div>
      <div {...stylex.props([styles.indexBtn])}>
        <FloatBtn btnName="ИНДЕКС СТОИМОСТИ ЭЛИТНОЙ НЕДВИЖИМОСТИ" redirectUrl="/" />
      </div>
    </div>
  );
};

export default ViewSwitchSection;
