import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IApartment } from '../../mockData';
import { COMPARE_GRID_CONSTS } from '@/shared/lib/consts';
import { renderCell } from './render-props';

export interface IСolumnProps {
  apartment: IApartment;
}

export const Column = ({ apartment }: IСolumnProps) => {
  return (
    <div {...stylex.props(styles.cols)}>
      {renderCell(apartment.project, null)}
      {renderCell(apartment.bedrooms, null)}
      {renderCell(apartment.square, COMPARE_GRID_CONSTS.METERS)}
      {renderCell(apartment.size, null)}
      {renderCell(apartment.building, null)}
      {renderCell(apartment.floor, null)}
      {renderCell(apartment.number, null)}
      {renderCell(apartment.priceForSquare, COMPARE_GRID_CONSTS.RUB)}
    </div>
  );
};
