import { Link } from 'react-router-dom';
import { INewsItem } from '@/pages/info-center-all-news-page/model';
import { getFormattedDate } from '@/shared/utils/dates.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';

const NewsColumnCard = ({ item }: { item: INewsItem }) => {
  return (
    <Link to={`/kompaniya/novosti/${item.id}`} {...stylex.props([styles.link])}>
      <div {...stylex.props([styles.newsColumnCard])}>
        <div {...stylex.props([styles.newsColumnCardContentWrapper])}>
          <img src={item.image} alt={item.title} {...stylex.props([styles.newsColumnCardImage])} />

          <div {...stylex.props([styles.newsColumnCardCenterContainer])}>
            <div {...stylex.props([styles.newsColumnCardTitle])}>{item.title}</div>
            <div {...stylex.props([styles.newsColumnCardDate])}>
              {getFormattedDate({
                date: item?.date,
                addYear: true,
              })}
            </div>
          </div>

          <div {...stylex.props([styles.newsColumnCardContent])}>{item.contentPreview}</div>
        </div>
      </div>
    </Link>
  );
};

export default NewsColumnCard;
