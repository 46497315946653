import React from 'react';

interface NextProps {
  isHovered: boolean;
}

export const Next: React.FC<NextProps> = ({ isHovered }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M16.2353 20.4707L19.7647 24.0001M19.7647 24.0001L16.2353 27.5295M19.7647 24.0001H5.64706" />
      <path
        d="M6.97844 7.07928C10.1893 4.38504 14.1005 2.66001 18.2552 2.10565C22.4098 1.5513 26.6365 2.19052 30.4414 3.94865C34.2464 5.70679 37.4725 8.51125 39.7431 12.0345C42.0137 15.5577 43.2349 19.6543 43.2642 23.8457C43.2934 28.037 42.1295 32.1502 39.9084 35.7048C37.6872 39.2594 34.5005 42.1087 30.7205 43.9197C26.9405 45.7308 22.7232 46.429 18.5611 45.9327C14.3991 45.4364 10.4642 43.7662 7.21606 41.117"
        style={{
          strokeDasharray: isHovered ? '0 100' : '100 100',
          // transition: 'stroke-dasharray 0.2s',
        }}
      />
    </svg>
  );
};
