import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ArrowLeftBack } from '@/shared/ui/icons/arrow-left-back';
import { COMPARE_HEADER_CONSTS } from '@/shared/lib/consts';
import { ShowMoreIcon } from '@/shared/ui/icons/show-more-icon';
import { ListMenu } from '../ListMenu';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import { useEffect, useRef, useState } from 'react';

export interface IComparisonHeaderMobileProps {
  apartments: IApartment[];
}
export const ComparisonHeaderMobile = ({ apartments }: IComparisonHeaderMobileProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (event: TouchEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  const handleScroll = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      document.addEventListener('touchstart', handleOutsideClick);
    } else {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('touchstart', handleOutsideClick);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [isMenuOpen]);
  return (
    <>
      <div {...stylex.props(styles.navBar)}>
        <div {...stylex.props(styles.navBarBtn)} role="button" onClick={() => {}}>
          <span {...stylex.props(styles.backIconContainer)}>
            <ArrowLeftBack />
          </span>
          <span {...stylex.props(styles.mobileHeaderText, styles.primaryForegroundColor)}>
            {COMPARE_HEADER_CONSTS.BACK}
          </span>
        </div>
        <div {...stylex.props(styles.navBarText)}>{COMPARE_HEADER_CONSTS.COMPARISON}</div>
      </div>
      <div {...stylex.props(styles.mobileHeaderWrapper)} ref={menuRef}>
        <div>
          <span {...stylex.props(styles.mobileHeaderText)}>{COMPARE_HEADER_CONSTS.OBJECTS_SHORT} </span>
          <span {...stylex.props(styles.mobileHeaderText, styles.primaryForegroundColor)}>
            {apartments.length > 0 ? apartments.length : ''}
          </span>
        </div>
        <div {...stylex.props(styles.showMoreBtn(isMenuOpen))} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <ShowMoreIcon />
          <ListMenu isMenuOpen={isMenuOpen} />
        </div>
      </div>
    </>
  );
};
