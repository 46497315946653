interface IChevronRightIcon {
  className?: string;
}
export const ChevronRightIcon = ({ className }: IChevronRightIcon) => {
  return (
    <svg viewBox="0 0 10 18" width="100%" height="100%" fill="none" className={className}>
      <path stroke="#fff" d="m1 1 8 8-8 8" />
    </svg>
  );
};
