import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  plan: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.6944rem', // 10px / 14.4
    padding: '1.6667rem', // 24px / 14.4
    borderRadius: '0.8333rem', // 12px / 14.4
    backgroundColor: colors.primaryBackground,
    position: 'relative',
    '@media (max-width: 767px)': {
      padding: '4.4444rem',
      borderRadius: '3.3333rem', // 12px
      gap: '2.7778rem',
    },
  },

  img: {
    width: '11.1111rem',
    height: '12.7778rem',
    '@media (max-width: 767px)': {
      width: '35.5556rem',
      height: '41.1111rem',
    },
  },

  hoveredIcons: (isPlanHovered) => ({
    position: 'absolute',
    top: '0.7639rem', // 11px / 14.4
    right: '0.8333rem', // 12px / 14.4
    display: isPlanHovered ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.5556rem', // 8px / 14.4
    '@media (max-width: 767px)': {
      top: '3.0556rem', // 11px
      right: '3.3333rem', // 12px
      gap: '2.2222rem', // 8px
      display: 'flex',
    },
  }),
  subStrate: (clicked) => ({
    display: 'flex',
    width: '1.9444rem', // 28px / 14.4
    height: '1.9444rem', // 28px / 14.4
    padding: '0.2778rem', // 4px / 14.4
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6.9444rem', //100px
    backgroundColor: clicked ? colors.accentBackground : colors.brandGhostBackground,
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      width: '7.7778rem', // 28px
      height: '7.7778rem', // 28px
      padding: '1.1111rem', // 4px
      borderRadius: '27.7778rem', //100px
    },
  }),
});
