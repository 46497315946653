import { CURRENCY_ENUM } from '@/shared/model/roompicker.ts';
import { ISortOption } from '@/shared/model/types.ts';
import APARTMENT_IMAGE_LINK from '@/shared/ui/assets/roompicker/apartment-1.png';

export const BADGE_COUNT_TEST_ELEVEN = 11;

export const APARTMENTS_MOCK_DATA = [
  {
    id: 1,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 34, Кв. 987',
    price: 236123123,
    value: 244000,
    area: 33,
    size: 'S',
    bedroomCount: 1,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
  },
  {
    id: 2,
    buildingName: 'Обыденский №1',
    showBuildingName: true,
    address: 'Этаж 1, Номер 1',
    price: 54400000,
    value: 5400,
    area: 65,
    size: 'M',
    bedroomCount: 2,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
  },
  {
    id: 3,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 34, Кв. 987',
    price: 264422000,
    value: 64400220,
    area: 92,
    size: 'M',
    bedroomCount: 3,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
  },
  {
    id: 4,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 34, Кв. 987',
    price: 645423,
    value: 6454200,
    area: 2200,
    size: 'L',
    bedroomCount: 19,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
  },
  {
    id: 5,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Длинный адрес, ул. Пушкина, дом Колотушкина',
    price: 1233330020,
    value: 644000,
    area: 53,
    size: 'L',
    bedroomCount: 1,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
  },
  {
    id: 6,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 34, Кв. 987',
    price: 555020321,
    value: 444000,
    area: 78,
    size: 'XL',
    bedroomCount: 3,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
  },
];

export const DEFAULT_DROPDOWN_SORTING_OPTIONS: ISortOption[] = [
  {
    id: 0,
    title: 'Стоимость сначала дешевле',
    key: 'price_asc',
  },
  {
    id: 1,
    title: 'Стоимость сначала дороже',
    key: 'price_desc',
  },
  {
    id: 2,
    title: 'Площадь сначала меньшая',
    key: 'area_asc',
  },
  {
    id: 3,
    title: 'Площадь сначала большая',
    key: 'area_desc',
  },
  {
    id: 4,
    title: 'Спален сначала меньше',
    key: 'bedrooms_asc',
  },
  {
    id: 5,
    title: 'Спален сначала больше',
    key: 'bedrooms_desc',
  },
  {
    id: 6,
    title: 'Спальни сначала маленькие',
    key: 'bedroom_area_asc',
  },
  {
    id: 7,
    title: 'Спальни сначала большие',
    key: 'bedroom_area_desc',
  },
];
