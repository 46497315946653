export function isIntersectionObserverSupport() {
  if (typeof window === 'undefined') return false;
  return Boolean(window.IntersectionObserver);
}

export function normalizeNewPosition(newPosition: number, imagesWidth: number) {
  if (newPosition > imagesWidth) {
    return imagesWidth;
  }
  if (newPosition < 0) {
    return 0;
  }

  return newPosition;
}

export const computeNormalizedBrightness = (image: 'first' | 'second' = 'first', position: number) => {
  const CHANGE_PERCENT = 35;
  const DEFAULT_BRIGHTNESS = 100;
  const MAX_PERCENT = 100;

  if (image === 'second')
    return `${position < CHANGE_PERCENT ? (position * MAX_PERCENT) / CHANGE_PERCENT : DEFAULT_BRIGHTNESS}`;
  return `${MAX_PERCENT - position < CHANGE_PERCENT ? ((MAX_PERCENT - position) * MAX_PERCENT) / CHANGE_PERCENT : DEFAULT_BRIGHTNESS}`;
};
