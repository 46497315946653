import { CURRENCY_ENUM } from '@/shared/model/roompicker.ts';
import { currencySign } from '@/shared/utils/roompicker.ts';
import {
  REG_EXP_ANY_DOT,
  REG_EXP_TWO_ZEROS_AFTER_DOT,
} from '@/enteties/formatted-price-with-currency/lib/reg-exp.ts';

function FormattedPriceWithCurrency({
  price = 0,
  currencyCode = CURRENCY_ENUM.RUB,
  numColor = '',
  textColor = '',
}: {
  price?: number;
  currencyCode?: CURRENCY_ENUM;
  numColor?: string;
  textColor?: string;
}) {
  const units = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'тыс.' },
    { value: 1e6, symbol: 'млн.' },
    { value: 1e9, symbol: 'млрд.' },
    { value: 1e12, symbol: 'трлн.' },
  ];

  const unit = units
    .slice()
    .reverse()
    .find((unit) => price >= unit.value);

  if (!price || !unit) return String(price) + currencySign(currencyCode);

  const formattedValue = (price / unit.value)
    .toFixed(2)
    .replace(REG_EXP_TWO_ZEROS_AFTER_DOT, '')
    .replace(REG_EXP_ANY_DOT, ',');

  return (
    <>
      <span className={numColor}>{formattedValue} </span>
      <span className={textColor}>
        {unit.symbol}
        {'\u00A0'}
        {currencySign(currencyCode)}
      </span>
    </>
  );
}

export default FormattedPriceWithCurrency;
