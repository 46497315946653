import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { SliderPhotoType } from '../model/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { SliderCounter } from './slider-counter';
import { SliderButtons } from './slider-buttons';
import { SliderCaption } from './slider-caption';
import { SliderSlides } from './slider-slides';
import useSwipeLogic from '@/shared/hooks/useSwipeLogic';

interface SliderDefaultProps {
  images: SliderPhotoType[];
}

export const SliderDefault: React.FC<SliderDefaultProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const isTransitioning = useRef(false);
  const lastInteractionTime = useRef(Date.now());
  const isInitialLoad = useRef(true);
  const [isVisible, setIsVisible] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    const FATE_OF_VISIBILITY = 0.8;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.intersectionRatio >= FATE_OF_VISIBILITY);
      },
      { threshold: [FATE_OF_VISIBILITY] }
    );
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };
    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const changeSlide = (direction: 'next' | 'prev') => {
    if (isTransitioning.current || images.length < 2) return;
    isTransitioning.current = true;
    const SWIPE_CLICK_DELAY = 500;
    if (direction === 'next') {
      setCurrentImageIndex(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1);
    }
    if (direction === 'prev') {
      setCurrentImageIndex(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
    }
    setTimeout(() => {
      isTransitioning.current = false;
    }, SWIPE_CLICK_DELAY);
  };

  const nextSlideHandler = useCallback(() => changeSlide('next'), [changeSlide]);
  const prevSlideHandler = useCallback(() => changeSlide('prev'), [changeSlide]);

  useEffect(() => {
    const PAUSE_AFTER_ACTION = 10050;
    const AUTO_SWITCH_INTERVAL = 3350;
    const intervalId = setInterval(() => {
      if (isVisible && (isInitialLoad.current || Date.now() - lastInteractionTime.current >= PAUSE_AFTER_ACTION)) {
        setIsPaused(false);
        nextSlideHandler();
      }
    }, AUTO_SWITCH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [images, nextSlideHandler, isVisible]);

  const getUploadedImageIndexes = (currentIndex: number, totalNumberImages: number) => {
    if (totalNumberImages < 4) return {};
    let prev = currentIndex - 1;
    let next = currentIndex + 1;
    if (prev < 0) prev = totalNumberImages - 1;
    if (next >= totalNumberImages) next = 0;

    return {
      prev: prev,
      current: currentIndex,
      next: next,
    };
  };
  const uploadedImage = getUploadedImageIndexes(currentImageIndex, images.length);

  const handleChangeSlide = useCallback(() => {
    setIsPaused(true);

    lastInteractionTime.current = Date.now();
    isInitialLoad.current = false;
  }, []);

  const handleNextClick = useCallback(() => {
    if (images.length < 2) return;
    nextSlideHandler();
    handleChangeSlide();
  }, [images.length, nextSlideHandler, handleChangeSlide]);

  const handlePrevClick = useCallback(() => {
    if (images.length < 2) return;
    prevSlideHandler();
    handleChangeSlide();
  }, [images.length, prevSlideHandler, handleChangeSlide]);

  const { handleMouseDown, handleMouseMove, handleMouseUp, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useSwipeLogic(handlePrevClick, handleNextClick);

  const getRenderIndexes = (images: SliderPhotoType[]) => {
    if (images.length < 4) {
      const imagesIndexes = images.map((_, index) => index);
      return imagesIndexes;
    }
    return Object.values(uploadedImage);
  };

  const renderSlides = getRenderIndexes(images);

  return (
    <div {...stylex.props(styles.sliderContainer)} ref={sliderRef}>
      <div {...stylex.props(styles.slider)}>
        <div
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {renderSlides.map((index) => (
            <SliderSlides
              isActive={index === currentImageIndex}
              imageSrc={images[index].imgSrc}
              imageAlt={images[index].alt}
              key={images[index].id}
            />
          ))}
        </div>

        <SliderCounter currentIndex={currentImageIndex} imagesLength={images.length} />
        <SliderButtons
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
          isPaused={isPaused}
          isVisible={isVisible}
        />
      </div>

      <div {...stylex.props(styles.captionContainer)}>
        {renderSlides.map((index) => (
          <SliderCaption
            isActive={index === currentImageIndex}
            imageCaption={images[index].caption}
            key={images[index].id}
          />
        ))}
      </div>
    </div>
  );
};
