export const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.50033 4.99992C7.50033 5.92039 6.75413 6.66659 5.83366 6.66659C4.91318 6.66659 4.16699 5.92039 4.16699 4.99992M7.50033 4.99992C7.50033 4.07944 6.75413 3.33325 5.83366 3.33325C4.91318 3.33325 4.16699 4.07944 4.16699 4.99992M7.50033 4.99992H18.3337M4.16699 4.99992H1.66699M12.5003 9.99992C12.5003 10.9204 13.2465 11.6666 14.167 11.6666C15.0875 11.6666 15.8337 10.9204 15.8337 9.99992M12.5003 9.99992C12.5003 9.07944 13.2465 8.33325 14.167 8.33325C15.0875 8.33325 15.8337 9.07944 15.8337 9.99992M12.5003 9.99992H1.66699M15.8337 9.99992H18.3337M7.50033 14.9999C7.50033 15.9204 6.75413 16.6666 5.83366 16.6666C4.91318 16.6666 4.16699 15.9204 4.16699 14.9999M7.50033 14.9999C7.50033 14.0794 6.75413 13.3333 5.83366 13.3333C4.91318 13.3333 4.16699 14.0794 4.16699 14.9999M7.50033 14.9999H18.3337M4.16699 14.9999H1.66699"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  );
};
