import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: (isSliderRight) => ({
    display: 'flex',
    padding: isSliderRight ? '0rem 3.125rem 0rem 11.04167rem' : '0rem 11.04167rem 0rem 3.125rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',

    '@media (max-width: 767px)': {
      flexDirection: 'column',
      gap: '10.66667rem',
      justifyContent: 'center',
      padding: '0rem',
      alignItems: isSliderRight ? 'flex-end' : 'flex-start',
    },
  }),
  slider: (isSliderRight) => ({
    order: isSliderRight ? '2' : '1',
    position: 'sticky',
    top: '10.5rem',

    '@media (max-width: 767px)': {
      order: '1',
      position: 'static',
      top: 'auto',
    },
  }),
  textBlockWrapper: (isSliderRight) => ({
    width: '30.41667rem',
    order: isSliderRight ? '1' : '2',

    '@media (max-width: 767px)': {
      width: '100%',
      padding: '0rem 4rem 0rem 4.26667rem',
      order: '2',
    },
  }),
});
