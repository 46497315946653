import { useEffect, useState } from 'react';
import { Dropdown } from '@/shared/ui/components/dropdown';
import { RoompickerHeader } from '@/features/roompicker-header';
import { RoompickerGridCard } from '@/features/roompicker-grid-card';
import { sortCallbackHandler } from '@/pages/roompicker-page/lib/sorting.ts';
import { IApartment } from '@/pages/roompicker-page/model/types.ts';
import { ISortOption } from '@/shared/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/roompicker-page/ui/styles.ts';

import {
  APARTMENTS_MOCK_DATA,
  BADGE_COUNT_TEST_ELEVEN,
  DEFAULT_DROPDOWN_SORTING_OPTIONS,
} from '@/pages/roompicker-page/api/mock-data.ts';

const RoompickerPage = () => {
  const [apartments, setApartments] = useState<IApartment[]>([]);
  const [isGridRow, setIsGridRow] = useState<boolean>(true);
  const [sort, setSort] = useState<ISortOption | null>();
  const [apartsToShow, setApartsToShow] = useState<IApartment[]>([]);

  useEffect(() => {
    setApartsToShow([...apartments].sort(sortCallbackHandler(sort?.key)));
  }, [apartments, sort?.key]);

  useEffect(() => {
    setApartments(APARTMENTS_MOCK_DATA.map((el) => ({ ...el, isFavorite: false, isToCompare: false })));
  }, []);

  const toCompareCount = apartments.reduce((acc, el) => {
    return acc + (el.isToCompare ? 1 : 0);
  }, 0);

  const compareOnClickHandler = (apartment: IApartment) => {
    setApartments((prev) =>
      prev.map((item) =>
        item.id === apartment.id
          ? {
              ...item,
              isToCompare: !item.isToCompare,
            }
          : item
      )
    );
  };

  const favoriteOnClickHandler = (apartment: IApartment) => {
    setApartments((prev) =>
      prev.map((item) =>
        item.id === apartment.id
          ? {
              ...item,
              isFavorite: !item.isFavorite,
            }
          : item
      )
    );
  };

  return (
    <div {...stylex.props(styles.moreContainer)}>
      <div {...stylex.props(styles.container)}>
        <div {...stylex.props(styles.headerWrapper)}>
          <RoompickerHeader
            isGridRow={isGridRow}
            compareBadgeCount={toCompareCount}
            filterBadgeCount={BADGE_COUNT_TEST_ELEVEN}
            gridOnClick={() => setIsGridRow(true)}
            rowOnClick={() => setIsGridRow(false)}
            dropdownOnSelect={(option) => setSort(option)}
          />
        </div>
        <div {...stylex.props(styles.cardsContainer)}>
          {/* На мобильной версии выпадающее меню является частью страницы, а не хедера */}
          <div {...stylex.props(styles.desktopHide)}>
            <Dropdown options={DEFAULT_DROPDOWN_SORTING_OPTIONS} onSelect={(option) => setSort(option)} />
          </div>

          {apartsToShow.map((apartment, index) => (
            <RoompickerGridCard
              key={index}
              infoProps={apartment}
              isToComparison={apartment.isToCompare}
              isFavorite={apartment.isFavorite}
              onCompareClick={() => compareOnClickHandler(apartment)}
              onFavoriteClick={() => favoriteOnClickHandler(apartment)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoompickerPage;
