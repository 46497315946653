import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.brandGhostBackground,
    paddingTop: '4.17rem',
    paddingBottom: '7.22rem',
    fontFamily: fonts.din2014Regular,
    position: 'relative',
    '-webkit-font-smoothing': 'antialiased',
    '@media (max-width: 767px)': {
      paddingTop: '0',
      paddingBottom: '4.4444rem',
    },
  },
});
