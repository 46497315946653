import { useState, Fragment } from 'react';
import styles from '../desktop-menu/menu.module.css';
import { Next } from '@/shared/ui/icons/Next/Next';
import { ArrowLeft } from '@/shared/ui/icons/ArrowLeft';
import { ArrowRight } from '@/shared/ui/icons/ArrowRight';
import { MenuProps } from '../desktop-menu/desktop-menu';
import { useNavigate } from 'react-router-dom';
import { Close } from '@/shared/ui/icons/Close';
import { ArrowRightBold } from '@/shared/ui/icons/ArrowRightBold';

interface MenuItemType {
  id: number;
  title: string;
  url?: string | undefined;
  children?: MenuItemType[];
}

export const MobileMenu = ({ menuContent, onClose, isSelection }: MenuProps) => {
  const [currentItems, setCurrentItems] = useState<MenuItemType[]>(menuContent);
  const [navigationStack, setNavigationStack] = useState<MenuItemType[][]>([]);
  const navigate = useNavigate();

  const handleItemClick = (item: MenuItemType) => {
    if (item.children && item.children.length > 0) {
      setNavigationStack([...navigationStack, currentItems]);
      setCurrentItems(item.children);
    }
  };
  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      onClose();
      setTimeout(() => {
        navigate(url);
      }, 100);
    }
  };
  const handleBackClick = () => {
    const previousItems = navigationStack.pop();
    if (previousItems) {
      setCurrentItems(previousItems);
      setNavigationStack([...navigationStack]);
    }
  };

  const renderMenuItems = (items: MenuItemType[]): JSX.Element[] => {
    return items.map((item) => (
      <Fragment key={item.id}>
        <div
          key={item.id}
          className={`${isSelection ? styles.selectionMenuContentItem : styles.menuContentItem}`}
          onClick={() => {
            handleItemClick(item);
            handleMenuClick(item.url);
          }}
        >
          {item.title}
          {item.children && item.children.length > 0 && (
            <>
              {isSelection ? (
                <span className={styles.selectionArrow}>
                  <ArrowRightBold />
                </span>
              ) : (
                <span className={styles.arrow}>
                  <ArrowRight />
                </span>
              )}
            </>
          )}
        </div>
      </Fragment>
    ));
  };

  return (
    <div
      className={`${isSelection ? styles.selectionMenuContainer : styles.menuContainer} ${isSelection ? styles.selectionMobile : styles.mobile}`}
    >
      {isSelection && (
        <button className={styles.closeBtn} onClick={onClose}>
          <Close />
        </button>
      )}
      <div className={`${isSelection ? styles.selectionMenuContentWrapper : styles.menuContentWrapper}`}>
        {navigationStack.length > 0 && (
          <div className={`${isSelection ? styles.selectionBackButton : styles.backButton}`} onClick={handleBackClick}>
            <>
              {isSelection ? (
                <div className={styles.selectionBackButtonIcon}>
                  <ArrowRightBold />
                </div>
              ) : (
                <div className={styles.backButtonIcon}>
                  <ArrowLeft />
                </div>
              )}
            </>
            Назад
          </div>
        )}
        {renderMenuItems(currentItems)}
      </div>
      {!isSelection && (
        <div className={`${styles.menuNextBtn} ${navigationStack.length > 0 ? styles.menuNextBtnHidden : ''}`}>
          ВЫБРАТЬ НЕДВИЖИМОСТЬ
          <div className={styles.menuNextBtnIcon}>
            <Next isHovered={false} />
          </div>
        </div>
      )}
    </div>
  );
};
