import { SlideType } from '@/features/slider-half-screen/model/types';
import poolLavr from '@shared/ui/assets/slider-fit-lab/basseyn-lavr.png';
import fitnessHollDostizhenie from '@shared/ui/assets/slider-fit-lab/holl-fitnes-dostizhenie.png';
import fitnessChistie from '@shared/ui/assets/slider-fit-lab/tren-zal-chistie.png';
import fitnessDostizhenie from '@shared/ui/assets/slider-fit-lab/tren-zal-dostizhenie.png';
import fitnessJazz from '@shared/ui/assets/slider-fit-lab/tren-zal-jazz.png';
import fitnessObidensliy from '@shared/ui/assets/slider-fit-lab/tren-zal-obidensliy.png';
import fitnessRenome from '@shared/ui/assets/slider-fit-lab/tren-zal-renome.png';

export const slidesFitLab: SlideType[] = [
  {
    id: 1,
    imgSrc: poolLavr,
    alt: `${poolLavr}'s photo`,
    caption: `Бассейн в «Лаврушинском»`,
  },
  {
    id: 2,
    imgSrc: fitnessChistie,
    alt: `${fitnessChistie}'s photo`,
    caption: `Тренажерный зал в «Чистых Прудах»`,
  },
  {
    id: 3,
    imgSrc: fitnessDostizhenie,
    alt: `${fitnessDostizhenie}'s photo`,
    caption: `Тренажерный зал в «Достижении»`,
  },
  {
    id: 4,
    imgSrc: fitnessObidensliy,
    alt: `${fitnessObidensliy}'s photo`,
    caption: `Тренажерный зал в «Обыденском 1»`,
  },
  {
    id: 5,
    imgSrc: fitnessHollDostizhenie,
    alt: `${fitnessHollDostizhenie}'s photo`,
    caption: `Холл фитнес-клуба в «Достижении»`,
  },
  {
    id: 6,
    imgSrc: fitnessRenome,
    alt: `${fitnessRenome}'s photo`,
    caption: `Тренажерный зал в «Реномэ»`,
  },
  {
    id: 7,
    imgSrc: fitnessJazz,
    alt: `${fitnessJazz}'s photo`,
    caption: `Тренажерный зал в «JAZZ»`,
  },
];
