import { Fragment } from 'react';
import { renderComponent } from '@/shared/libs/dynamic-components-render.tsx';
import { realisationPageConfig } from '@/pages/realisation-page/model/realisation-page-config-mock';
import styles from '@/pages/realisation-page/ui/realization-page.module.css';

const RealisationPage = () => {
  return (
    <div className={styles.bgImage}>
      {realisationPageConfig().map((item, index) => (
        <Fragment key={index}>{renderComponent(item)}</Fragment>
      ))}
    </div>
  );
};

export default RealisationPage;
