import { ITextType } from '@/features/text-block-with-enumeration/model/types';

export const textBlockFitLab: ITextType = {
  header: 'ВСЕГДА В ПРЕКРАСНОЙ ФОРМЕ',
  description: `FINE DEVELOPMENT — синоним высокого качества жизни, которое невозможно достичь без заботы о здоровье. 
    Совместно с экспертами, создававшими лучшие фитнес-центры Москвы, 
    мы разработали собственный стандарт инфраструктуры для поддержания физической формы и хорошего самочувствия Fit Lab. 
    Чтобы вы могли у себя дома получать максимум эффективности и удовольствия от активного образа жизни.
`,
  descriptionList: 'Принципы Fit Lab:',
  list: [
    'Только для жителей, круглосуточно и бесплатно (включено в стоимость эксплуатации).',
    'Заниматься спортом комфортно: не нужно никуда ехать.',
    'Размеры помещений и количество оборудования рассчитаны, исходя из числа жителей.',
    'Спортивный инвентарь лучших мировых производителей.',
    'Оборудование для всех групп мышц и стилей тренировок.',
    'Продуманное сочетание воркаутов и спортплощадок на воздухе с залами в помещении.',
  ],
};
