import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ImageType } from '../model/types';

interface TextWithImageBlock {
  textWithImageRef: React.Ref<HTMLDivElement> | null;
  textWithImage: string;
  image: ImageType;
}

export const TextWithImageBlock: React.FC<TextWithImageBlock> = ({ textWithImageRef, textWithImage, image }) => {
  return (
    <div {...stylex.props(styles.textWithImageBlockWrapper)}>
      <div {...stylex.props(styles.textWithImageBlock)} ref={textWithImageRef}>
        <div {...stylex.props(styles.textBlock)}>
          <p {...stylex.props(styles.text)}>{textWithImage}</p>
        </div>
        <div {...stylex.props(styles.imgBlock)}>
          <img {...stylex.props(styles.img)} src={image.src} alt={image.alt} draggable={false} />
        </div>
      </div>
    </div>
  );
};
