import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  navBar: {
    position: 'fixed',
    top: 0,
    height: '12.2222rem', // 44px
    width: '100%',
    padding: '0 4.4444rem', // 0 16px
    display: 'flex',
    alignItems: 'center',
    background: colors.primaryInverseForeground,
    borderBottom: `1px solid ${colors.tertiaryBorder}`, // 1px
    zIndex: 999,
  },
  navBarBtn: {
    display: 'flex',
    padding: '2.2222rem 0', // 8px 0
    alignItems: 'center',
    gap: '2.2222rem', // 8px
  },
  backIconContainer: {
    width: '5.5556rem', // 20px
    height: '5.5556rem', // 20px
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navBarText: {
    color: colors.primaryForeground,
    fontSize: '5.5556rem', // 20px
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '140%',
    padding: '2.2222rem 0', // 8px 0
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },

  mobileHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '91.1111rem', // 328px
    marginTop: '14.2222rem', // 8px
  },
  showMoreBtn: (isMenuOpen) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2.2222rem', // 8px
    gap: '2.2222rem', // 8px
    borderRadius: '2.2222rem', // 8px
    backgroundColor: isMenuOpen ? colors.activeBackground : 'transparent',
    transition: 'background-color 0.3s ease',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  }),

  dropdownMenu: (isMenuOpen) => ({
    display: isMenuOpen ? 'none' : 'block',
    position: 'absolute',
    top: '100%',
    width: '77.5rem', // 279px
    padding: '1.1111rem', // 4px
    gap: '1.1111rem', // 4px
    border: `1px solid ${colors.tertiaryBackground}`,
  }),

  mobileHeaderText: {
    color: colors.tertiaryForeground,
    fontSize: '3.8889rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '142.857%',
  },

  primaryForegroundColor: {
    color: colors.primaryForeground,
  },
});
