import { SlideType } from '@/features/slider-half-screen/model/types';
import emptyParking from '@shared/ui/assets/slider-auto/empty-parking.png';
import greenCar from '@shared/ui/assets/slider-auto/green-car.png';
import oneCar from '@shared/ui/assets/slider-auto/one-cars.png';
import parkingFive from '@shared/ui/assets/slider-auto/parking-five.png';
import manyCars from '@shared/ui/assets/slider-auto/parking-many-cars.png';
import parkingOutside from '@shared/ui/assets/slider-auto/parking-outside.png';
import redCars from '@shared/ui/assets/slider-auto/parking-red-cars.png';
import twoCars from '@shared/ui/assets/slider-auto/parking-two-cars.png';

export const slidesAuto: SlideType[] = [
  {
    id: 1,
    imgSrc: twoCars,
    alt: `${twoCars}'s photo`,
    caption: `Паркинг в «Лаврушинском»`,
  },
  {
    id: 2,
    imgSrc: redCars,
    alt: `${redCars}'s photo`,
    caption: `Паркинг в «Малой Ордынке 19»`,
  },
  {
    id: 3,
    imgSrc: oneCar,
    alt: `${oneCar}'s photo`,
    caption: `Паркинг в «Чистых Прудах»`,
  },
  {
    id: 4,
    imgSrc: greenCar,
    alt: `${greenCar}'s photo`,
    caption: `Паркинг в «Обыденском 1»`,
  },
  {
    id: 5,
    imgSrc: parkingOutside,
    alt: `${parkingOutside}'s photo`,
    caption: `Въезд на паркинг в «Достижении»`,
  },
  {
    id: 6,
    imgSrc: emptyParking,
    alt: `${emptyParking}'s photo`,
    caption: `Паркинг в «Реномэ»`,
  },
  {
    id: 7,
    imgSrc: parkingFive,
    alt: `${parkingFive}'s photo`,
    caption: `Паркинг в «Достижении»`,
  },
  {
    id: 8,
    imgSrc: manyCars,
    alt: `${manyCars}'s photo`,
    caption: `Паркинг в «JAZZ»`,
  },
];
