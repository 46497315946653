import { SelectionHeader } from '@/features/header/ui/selection-header/ui';
import { Outlet } from 'react-router-dom';

export const SelectionLayout = () => {
  return (
    <>
      <SelectionHeader />
      <Outlet />
    </>
  );
};
