export interface ICompareIconProps {
  status: boolean;
}

export const CompareIcon = ({ status }: ICompareIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.0616 2.21494C11.7953 2.30009 12.3327 2.93267 12.3327 3.67132V14L7.99935 11.5L3.66602 14V3.67132C3.66602 2.93267 4.2034 2.30009 4.93714 2.21494C6.16039 2.07298 6.73801 2 7.99935 2C9.26068 2 9.83831 2.07298 11.0616 2.21494Z"
        stroke={status ? '#FFF' : '#AE955F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
