import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  titleWithNavbarWrapper: {
    width: '100%',
    display: 'flex',
    padding: '10.42rem 11.04167rem 6.25rem 11.04167rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6.25rem',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      padding: '29.86333rem 4.26667rem 8.53333rem 4.26667rem',
    },
  },
  pageWrapper: {
    width: '100%',
    padding: '0rem 0rem 6.25rem 0rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '6.25rem',
    background: 'linear-gradient(180deg, #F2F3EE 0%, #FFF 62.64%)',

    '@media (max-width: 767px)': {
      padding: '0rem 0rem 8.53333rem 0rem',
      gap: '8.53333rem',
    },
  },
  separateTextBlock: {
    width: '100%',
    padding: '0rem 11.04167rem 0rem 11.04167rem',
    display: 'flex',
    justifyContent: 'flex-end',

    '@media (max-width: 767px)': {
      padding: '0rem 4rem 0rem 4.26667rem',
      display: 'block',
    },
  },
  animatedCirclesWithImages: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.67708rem',

    '@media (max-width: 767px)': {
      gap: '8.53333rem',
    },
  },
  separateTextWithAnimatedCircles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '6.25rem',

    '@media (max-width: 767px)': {
      gap: '0rem',
    },
  },

  sliderFullScreenWrapper: {
    width: '100%',
    padding: '0rem 3.125rem',

    '@media (max-width: 767px)': {
      padding: '0rem 0rem',
    },
  },
  widthWrapper: {
    width: '100%',
  },
  topSeparateUppercaseTextWrapper: {
    width: '67.96875rem',
    padding: '0rem 0rem 0rem 11.04167rem',
    alignSelf: 'flex-start',

    '@media (max-width: 767px)': {
      width: '100%',
      padding: '0rem 4rem 0rem 4.26667rem',
    },
  },
  bottomSeparateUppercaseTextWrapper: {
    width: '100%',

    '@media (max-width: 767px)': {
      padding: '0rem 4rem 0rem 4.26667rem',
    },
  },
});
