import { ITextType } from '@/features/text-block-with-enumeration/model/types';

export const textBlockAuto: ITextType = {
  header: 'АВТОМОБИЛЬ В\u00A0УДОВОЛЬСТВИЕ',
  description: `Один из принципов FINE DEVELOPMENT — в идеальном доме не бывает второстепенных пространств. 
    Заботясь о каждом аспекте комфортной жизни, мы делаем парковочное пространство не техническим дополнением, 
    а бескомпромиссным продолжением вашего дома. Мы продумываем все детали, чтобы вам ни о чем не пришлось беспокоиться — 
    и вы просто наслаждались самым продвинутым паркингом в своем классе.`,
  list: [
    'Премиальная отделка и парадные входы',
    'Ширина проездов — 6 метров',
    'Увеличенные габариты машино-мест',
    'Высокий парковочный коэффициент',
    'Комфортная навигация',
    'Прямой лифт с жилых этажей',
    'Зарядные станции для электромобилей',
    'Видеонаблюдение, к которому можно подключиться',
    'Устойчивый сигнал сотовой связи',
    'Келлеры, тележки для разгрузки',
  ],
};
