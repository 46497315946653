import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface SliderCaptionProps {
  isActive: boolean;
  slideCaption: string;
}

export const SliderCaption: React.FC<SliderCaptionProps> = ({ isActive, slideCaption }) => {
  return (
    <div {...stylex.props(styles.captionItem(isActive))}>
      <p {...stylex.props(styles.caption)}>{slideCaption}</p>
    </div>
  );
};
