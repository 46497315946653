import { useState } from 'react';
import { SliderDropdownProps } from '../model/types';
import DesktopSliderDropdown from './desktop-slider-dropdown/desktop-slider-dropdown';
import MobileSliderDropdown from './mobile-slider-dropdown/mobile-slider-dropdown';

/**
 *@component
 *
 * Компонент конструктора
 *
 * `GSITE-267`
 * `Компонент "Универсальный слайдер-dropdown"`
 *
 */
export default function SitesSliderDropdown(props: SliderDropdownProps) {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  return (
    <>
      <DesktopSliderDropdown {...props} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      <MobileSliderDropdown {...props} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
    </>
  );
}
