import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  cols: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column-reverse',
    borderBottom: `1px solid ${colors.tertiaryBorder}`,
    minHeight: '4.7222rem', // 68px / 14.4
    '@media (max-width: 767px)': {
      minHeight: '17.7778rem', // 64px / 3.6
    },
  },
  header: {
    fontSize: '0.8333rem', // 12px / 14.4
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    color: colors.tertiaryForeground,
    marginLeft: '1.1111rem', // 16px / 14.4
    marginTop: '0.8333rem', // 12px / 14.4
    '@media (max-width: 767px)': {
      fontSize: '3.3333rem', // 12px
      marginLeft: '4.4444rem', // 16px
      marginTop: '3.3333rem', // 12px
    },
  },
  text: {
    fontSize: '1.1111rem', // 16px / 14.4
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    color: colors.primaryForeground,
    marginLeft: '1.1111rem', // 16px / 14.4
    marginBottom: '0.8333rem', // 12px / 14.4
    marginTop: '0.5556rem', // 8px / 14.4
    '@media (max-width: 767px)': {
      fontSize: '3.8889rem',
      lineHeight: '142.857%',
      marginLeft: '3.3333rem',
      marginBottom: '3.3333rem', // 12px
      marginTop: '2.2222rem', // 8px
    },
  },
  textUnitMeasure: {
    color: colors.tertiaryForeground,
    marginLeft: '0.1389rem',
    '@media (max-width: 767px)': {
      marginLeft: '0.5556rem',
    },
  },
});
