import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  textCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    padding: '2.0834rem',
    height: '42.708rem',
    width: '100%',
    fontFamily: fonts.din2014Light,
    backgroundColor: colors.brandGhostBackground,
    '@media (max-width: 767px)': {
      gridСolumn: '1 / -1',
      padding: '6.4rem',
      height: 'auto',
      gap: '3.2rem',
    },
  },
  textCardExperience: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    '@media (min-width: 767px)': {
      display: 'block',
    },
  },
  textCardYears: {
    fontFamily: fonts.din2014Light,
    fontSize: '6.667rem',
    fontWeight: 300,
    color:colors.primaryText,
    lineHeight:'100%',
    marginBottom:'0.625rem',
    '@media (max-width: 767px)': {
      fontSize: '21.334rem',
      lineHeight:'110%',
      marginBottom:'0',
    },
  },
  textCardLine: {
    width:'13.75rem',
    borderBottom: `0.01rem solid ${colors.tertiaryBorder}`,
    '@media (max-width: 767px)': {
      marginBottom:'0',
      width: '70.4rem',
    },
  },
  textCardText: {
    fontFamily: fonts.din2014Light,
    width:'13.75rem',
    color:colors.primaryText,
    fontSize: '1.0417rem',
    fontWeight: 300,
    lineHeight: '140%',
    '@media (max-width: 767px)': {
      fontSize: '4.267rem',
      width:'100%',
      marginLeft:'3.2rem',
    },
  },
  textDescription:{
    fontFamily: fonts.din2014Light,
    color:colors.primaryText,
    fontSize: '1.0417rem',
    fontWeight: 300,
    lineHeight: '140%',
    '@media (max-width: 767px)': {
      fontSize: '4.267rem',
    },
  }
});
