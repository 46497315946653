import { CallOrderButton } from '@/enteties/call-order-button';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import React from 'react';

export interface ICallOrderButtonsListProps {
  apartments: IApartment[];
}
export const CallOrderButtonsList = React.forwardRef<HTMLDivElement, ICallOrderButtonsListProps>(
  ({ apartments }, buttonsRef) => {
    return (
      <div {...stylex.props(styles.buttonsContainer)} ref={buttonsRef}>
        {apartments.map((apartment) => (
          <CallOrderButton key={apartment.id} apartment={apartment} />
        ))}
      </div>
    );
  }
);
