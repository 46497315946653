import { InfoCenterOneNewsPageConfig } from '@/shared/mockData/info-center/info-center-one-news-page-mock.ts';
import { INewsItem } from '@/pages/info-center-all-news-page/model';

export const getInfoCenterOneNewsConfig = ({
  newsItem,
  recommendedNews,
}: {
  newsItem?: INewsItem;
  recommendedNews: INewsItem[];
}) => {
  return InfoCenterOneNewsPageConfig({ newsItem, recommendedNews });
};
