import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    padding: '10.4166667rem 11.0416667rem 6.25rem 11.0416667rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.91666667rem',
    alignSelf: 'stretch',
    background: 'var(--background-primary, #FFF)',
    '@media (max-width: 767px)': {
      padding: '29.8666667rem 4.26666667rem 21.3333333rem 4rem',
      gap: '8.53333333rem',
    },
  },
  h1: {
    color: '#000',
    fontFamily: fonts.din2014ExtraLight,
    fontSize: '4.16666667rem',
    fontStyle: 'normal',
    fontWeight: '250',
    lineHeight: '4.16666667rem',
    '@media (max-width: 767px)': {
      color: colors.primaryText,
      fontSize: '10.6666667rem',
      lineHeight: '10.6666667rem',
    },
  },
  text: {
    width: '17.34375rem',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04166667rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833333rem',
    '@media (max-width: 767px)': {
      width: '100%',
      fontSize: '4.26666667rem',
      lineHeight: '6.4rem',
    },
  },
});
