export const MenuListIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28 23H4" stroke="currentColor" />
      <path d="M28 9H4" stroke="currentColor" />
      <path d="M28 16H4" stroke="currentColor" />
    </svg>

  );
};
