import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface StaticCardProps {
  bgUrl: string;
}

export const StaticCard = ({ bgUrl }: StaticCardProps) => {
  return (
    <div {...stylex.props(styles.bgImageContainer)}>
      <div {...stylex.props(styles.bgImage)} style={{ backgroundImage: `url(${bgUrl})` }}></div>
    </div>
  );
};
