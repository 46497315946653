import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ArrowLeftSlider } from '@/shared/ui/icons/arrow-left-slider';
import { ArrowRightSlider } from '@/shared/ui/icons/arrow-right-slider';
import style from './slider-default.module.css';

interface SliderButtonsProps {
  handlePrevClick: () => void;
  handleNextClick: () => void;
  isPaused: boolean;
  isVisible: boolean;
}

export const SliderButtons: React.FC<SliderButtonsProps> = ({
  handlePrevClick,
  handleNextClick,
  isPaused,
  isVisible,
}) => {
  return (
    <>
      <button onClick={handlePrevClick} {...stylex.props(styles.leftButton)}>
        <span {...stylex.props(styles.arrow)}>
          <ArrowLeftSlider />
        </span>
      </button>
      <button onClick={handleNextClick} {...stylex.props(styles.rigthButton)}>
        <span {...stylex.props(styles.arrow)}>
          <ArrowRightSlider />
        </span>
        <div {...stylex.props(styles.svgContainer)}>
          <svg viewBox="0 0 48 48" {...stylex.props(styles.svg)}>
            <circle cx="24" cy="24" r="24" stroke="lightgray" fill="none" strokeWidth="1" />
            <circle
              cx="24"
              cy="24"
              r="24"
              fill="none"
              stroke="#000"
              strokeWidth="1"
              strokeDasharray="0px, 150.79632px"
              strokeDashoffset="-150.79632px"
              className={!isPaused && isVisible ? style.progressCircle : ''}
            />
          </svg>
        </div>
      </button>
    </>
  );
};
