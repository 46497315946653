import { PropsWithChildren, useState } from 'react';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-news-page/ui/components/styles.ts';

const ShareButton = ({ children, href = '/', title }: PropsWithChildren<{ href?: string; title: string }>) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <a href={href} {...stylex.props([styles.link])} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div {...stylex.props([styles.iconContainer, isHovered ? styles.iconContainerHovered : null])}>
        <div {...stylex.props([styles.iconWrapper])}>{children}</div>
      </div>
      <div {...stylex.props([styles.shareBtnTitle])}>{title}</div>
    </a>
  );
};

export default ShareButton;
