import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './desktop-accordion.module.css';
import cs from 'classnames';
import { DesktopAccordionProps } from '../../model/types';
export default function DesktopAccordion({
  title,
  isDisabled,
  isOpen,
  content,
  onClick,
  color,
  isLast,
}: DesktopAccordionProps) {
  const [localOpenState, setLocalOpenState] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      setLocalOpenState((prev) => !prev);
    }
  }, [onClick]);

  const isContentOpen = isOpen || localOpenState;

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isContentOpen ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={styles.divider}
        style={{
          backgroundColor: color,
        }}
      />
      <div
        className={cs(styles.dropdownContainer, { [styles.disabled]: isDisabled, [styles.inActive]: !isOpen })}
        style={{ color: color, borderColor: color }}
        onClick={() => !isDisabled && onClickHandler()}
      >
        <div className={styles.dropdownTitle}>
          <div className={cs(styles.title)}>{title}</div>
          <div className={cs(styles.arrow)}>
            <div className={cs(styles.arrowWrapper, { [styles.arrowActive]: isContentOpen })}>
              <svg className={cs(styles.arrowIcon)} viewBox="0 0 20 11" fill="none">
                <path stroke={color ?? '#000'} d="m19 1-9 9-9-9" />
              </svg>
            </div>
          </div>
        </div>
        <div ref={contentRef} className={cs(styles.dropdownContent, { [styles.dropdownContentActive]: isContentOpen })}>
          {content}
        </div>
        {isLast && <div className={styles.divider} style={{ backgroundColor: color }} />}
      </div>
    </>
  );
}
