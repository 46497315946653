import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';
import { anatomy } from '@/shared/tokens/anatomy.stylex.ts';

export const styles = stylex.create({
  container: {
    fontFamily: fonts.din2014Regular,
    width: '234px',
    height: '390px',
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    gap: '12px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: colors.tertiaryBorder,
    backgroundColor: palette.greyWhite,
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      width: '100%',
      height: '362px',
      overflow: 'hidden',
    },
  },
  desktopIconsContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  mobileIconsContainer: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      width: '100%',
    },
  },
  upperInfo: {
    width: '100%',
    display: 'flex',
    fontSize: '12px',
    gap: '4px',
    color: '#8E9296',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  upperInfoText: {
    margin: 'auto 0',
    lineHeight: anatomy['size-12'],
    fontSize: anatomy['size-12'],
    cursor: 'default',
  },
  upperBuildingName: {
    width: '138px',
    color: colors.primaryText,
    fontSize: anatomy['size-12'],
    lineHeight: anatomy['size-12'],
    paddingBottom: '4px',
    cursor: 'default',
    '@media (max-width: 767px)': {
      width: 'unset',
      maxWidth: '100%',
    },
  },
  textTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  upperInfoTextContainer: {
    margin: 'auto 0',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  lowerClickableArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    fontSize: anatomy['size-12'],
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 0,
    border: 0,
    width: '100%',
    color: colors.primaryText,
  },
  preLowerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  preLowerInfoPrice: {
    fontFamily: fonts.din2014Regular,
    fontWeight: 600,
    fontSize: anatomy['size-24'],
    lineHeight: anatomy['size-32'],
    '@media (max-width: 767px)': {
      fontSize: anatomy['size-28'],
      lineHeight: anatomy['size-36'],
    },
  },
  preLowerInfoValue: {
    fontSize: anatomy['size-12'],
    lineHeight: anatomy['size-16'],
    '@media (max-width: 767px)': {
      lineHeight: anatomy['size-12'],
    },
  },
  lowerInfo: {
    display: 'block',
    flexGrow: '1',
    width: '100%',
    fontSize: '14px',
    lineHeight: anatomy['size-20'],
    '@media (max-width: 767px)': {
      display: 'inline',
      fontFamily: fonts.din2014Regular,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: anatomy['size-20'],
    },
  },
  imgWrapper: {
    position: 'relative',
    height: '10%',
    width: '100%',
    padding: '16px',
    boxSizing: 'border-box',
    flexGrow: 1,
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    ':hover > #GlassIconWrapper': {
      opacity: '1',
    },
    ':hover > img': {
      opacity: '0.7',
    },
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    transition: 'opacity 0.3s ease-in-out',
  },
  grayText: {
    color: '#8E9296',
  },
  pointDivider: {
    paddingHorizontal: '4px',
    color: '#8E9296',
    '@media (max-width: 767px)': {
      color: '#1D252D',
    },
  },
  desktopHidden: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'inline',
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: anatomy.borderRadiusRound,
    backgroundColor: {
      default: colors.brandGhostBackground,
      ':hover': colors.accentBackground,
    },
    color: {
      default: colors.accentIcon,
      ':hover': colors.primaryInverseText,
    },
    cursor: 'pointer',
    transition: 'background 0.3s ease, color 0.3s ease',
    border: 0,
  },
  iconMedium: {
    width: '28px',
    height: '28px',
  },
  iconLarge: {
    width: '36px',
    height: '36px',
  },
  iconsContainer: {
    display: 'flex',
    gap: '4px',
    marginLeft: 'auto',
    justifyContent: 'end',
    flexBasis: 'fit-content',
  },
  iconActive: {
    backgroundColor: colors.accentBackground,
    color: colors.primaryInverseText,
  },
  btnDefaultStyleReset: {
    fontFamily: fonts.din2014Regular,
    textIndent: 0,
    textDecoration: 0,
    textAlign: 'left',
    margin: 0,
    listStyle: 'none',
    borderSpacing: 0,
    border: 0,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  iconPlus: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    width: '36px',
    height: '36px',
    borderRadius: anatomy.borderRadiusRound,
    backgroundColor: {
      default: colors.brandGhostBackground,
    },
    color: {
      default: colors.accentIcon,
    },
    transition: 'opacity 0.3s ease-in-out',
  },
  paddingTop: {
    paddingTop: '4px',
  },
});
