import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { Play } from '@/shared/ui/icons/Play';
import { Modal } from '@/shared/ui/modal';
import { VideoPlayer } from '@/features/video-player';

interface VideoProps {
  previewUrl: string;
  videoUrl: string;
  showButton?: boolean;
}

export const Video = ({ previewUrl, videoUrl, showButton = true }: VideoProps) => {
  const videoBackgroundRef = useRef<HTMLVideoElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (videoBackgroundRef.current) {
      videoBackgroundRef.current.muted = true;
      videoBackgroundRef.current.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  const handlePlay = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div {...stylex.props(styles.videoContainer)}>
      <video
        ref={videoBackgroundRef}
        src={previewUrl}
        autoPlay={true}
        controls={false}
        loop={true}
        {...stylex.props(styles.video)}
        playsInline
      ></video>
      {showButton ? (
        <div {...stylex.props(styles.btnWrap)}>
          <button
            onClick={handlePlay}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            {...stylex.props(styles.playButton, isHover && styles.playButtonHover)}
          >
            <Play />
          </button>
          <span {...stylex.props(styles.btnText)}>Смотреть видео</span>
        </div>
      ) : (
        ''
      )}

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {isModalOpen && <VideoPlayer url={videoUrl} autoPlay={true} />}
      </Modal>
    </div>
  );
};
