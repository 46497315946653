import stylex from '@stylexjs/stylex';
import { styles } from './styles';

export const renderCell = (value: string | number, unit: string | null) => (
  <div {...stylex.props(styles.tableCell)}>
    <div {...stylex.props(styles.text)}>
      <span>{value}</span>
      {unit && <span {...stylex.props(styles.textUnitMeasure)}>{unit}</span>}
    </div>
  </div>
);
