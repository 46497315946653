import { IconWrapperProps } from '../model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';

const IconWrapper = ({ isActive, icon, onClick, size = 'medium', form = 'round' }: IconWrapperProps) => {
  return (
    <button
      role="button"
      onClick={onClick}
      {...stylex.props([
        styles.iconWrapper,
        styles.btnDefaultStyleReset,
        styles[size],
        styles[form],
        isActive && styles.iconActive,
      ])}
    >
      {icon}
    </button>
  );
};

export default IconWrapper;
