import { IButtonWIconProps } from '@/pages/info-center-all-news-page/ui/components/view-switch-section/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/view-switch-section/ui/styles.ts';

const ButtonWithIcon = ({ title, isActive, onClick, icon }: IButtonWIconProps) => {
  return (
    <button {...stylex.props([styles.button, isActive && styles.activeBtn])} onClick={onClick}>
      <span {...stylex.props([styles.iconWrapper])}>{icon}</span>
      <span {...stylex.props([styles.buttonText])}>{title}</span>
    </button>
  );
};

export default ButtonWithIcon;
