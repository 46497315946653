const VkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M23.7861 17.5266C23.7216 17.4246 23.3225 16.6064 21.4024 14.9246C19.3923 13.1635 19.6623 13.4494 22.0835 10.4043C23.5581 8.54976 24.1476 7.41721 23.9631 6.93305C23.7876 6.47154 22.703 6.59329 22.703 6.59329L19.0967 6.61311C19.0967 6.61311 18.8297 6.57913 18.6302 6.69097C18.4367 6.80139 18.3122 7.05622 18.3122 7.05622C18.3122 7.05622 17.7406 8.49171 16.9786 9.71203C15.372 12.2871 14.7284 12.4231 14.4659 12.2631C13.8554 11.8908 14.0084 10.7653 14.0084 9.96685C14.0084 7.47101 14.4089 6.43049 13.2268 6.16151C12.8338 6.07232 12.5458 6.01286 11.5422 6.00295C10.2551 5.99021 9.16451 6.0072 8.54796 6.29175C8.13693 6.48145 7.82041 6.90474 8.01392 6.92881C8.25244 6.95853 8.79248 7.06613 9.079 7.4342C9.44953 7.90845 9.43603 8.97588 9.43603 8.97588C9.43603 8.97588 9.64904 11.9134 8.93949 12.2787C8.45196 12.5292 7.78441 12.0182 6.3518 9.68088C5.61825 8.48463 5.06321 7.16098 5.06321 7.16098C5.06321 7.16098 4.9567 6.91465 4.76618 6.78299C4.53517 6.62302 4.21114 6.57205 4.21114 6.57205L0.781892 6.59187C0.781892 6.59187 0.267355 6.60603 0.0783406 6.81697C-0.0896717 7.00525 0.0648396 7.39315 0.0648396 7.39315C0.0648396 7.39315 2.75004 13.3206 5.78926 16.3091C8.57796 19.0484 11.7432 18.8686 11.7432 18.8686H13.1773C13.1773 18.8686 13.6108 18.8233 13.8314 18.5982C14.0354 18.3916 14.0279 18.0037 14.0279 18.0037C14.0279 18.0037 13.9994 16.1873 14.8934 15.9198C15.774 15.6565 16.9051 17.6752 18.1037 18.4524C19.0097 19.0399 19.6983 18.9111 19.6983 18.9111L22.904 18.8686C22.904 18.8686 24.5811 18.771 23.7861 17.5266Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VkIcon;
