import five from '@shared/ui/assets/half-screen-slider-unique-style/five.png';
import keller from '@shared/ui/assets/half-screen-slider-unique-style/keller.png';
import lift from '@shared/ui/assets/half-screen-slider-unique-style/lift.png';
import malayaOrdinka19 from '@shared/ui/assets/half-screen-slider-unique-style/malaya-ordinka-19.png';
import parking from '@shared/ui/assets/half-screen-slider-unique-style/parking.png';
import pattern from '@shared/ui/assets/half-screen-slider-unique-style/pattern.png';
import receptionMalaya from '@shared/ui/assets/half-screen-slider-unique-style/reception-malay.png';
import singOnFloor from '@shared/ui/assets/half-screen-slider-unique-style/sign-on-floor.png';
import stairs from '@shared/ui/assets/half-screen-slider-unique-style/stairs.png';
import { SlideType } from './types';

export const mockSlides: SlideType[] = [
  {
    id: 1,
    imgSrc: receptionMalaya,
    alt: `${receptionMalaya}'s photo`,
    caption: `Лобби «Малой Ордынки 19»`,
  },
  {
    id: 2,
    imgSrc: lift,
    alt: `${lift}'s photo`,
    caption: `Лифтовый холл «Малой Ордынки 19»`,
  },
  {
    id: 3,
    imgSrc: stairs,
    alt: `${stairs}'s photo`,
    caption: `Лестница «Малой Ордынки 19»`,
  },
  {
    id: 4,
    imgSrc: five,
    alt: `${five}'s photo`,
    caption: `Элемент внутренней навигации «Малой Ордынки 19»`,
  },
  {
    id: 5,
    imgSrc: keller,
    alt: `${keller}'s photo`,
    caption: `Паркинг «Малой Ордынки 19»`,
  },
  {
    id: 6,
    imgSrc: parking,
    alt: `${parking}'s photo`,
    caption: `Паркинг «Малой Ордынки 19»`,
  },
  {
    id: 7,
    imgSrc: malayaOrdinka19,
    alt: `${malayaOrdinka19}'s photo`,
    caption: `Адресная табличка «Малой Ордынки 19»`,
  },
  {
    id: 8,
    imgSrc: singOnFloor,
    alt: `${singOnFloor}'s photo`,
    caption: `Навигационная табличка «Малой Ордынки 19»`,
  },
  {
    id: 9,
    imgSrc: pattern,
    alt: `${pattern}'s photo`,
    caption: `Элемент интерьера «Малой Ордынки 19»`,
  },
];
