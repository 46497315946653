import { ReactNode, useState } from 'react';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface PopoverProps {
  children: ReactNode;
  content: ReactNode;
  position?: 'bottom' | 'bottom-left';
}

export const Popover: React.FC<PopoverProps> = ({ children, content, position = 'bottom' }) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const handleMouseEnter = () => {
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  return (
    <div {...stylex.props(styles.container)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {isPopoverVisible && (
        <div {...stylex.props(styles.popover(position))}>
          <p {...stylex.props(styles.popoverContent)}>{content}</p>
        </div>
      )}
    </div>
  );
};
