import { BigCircleProgressBarType } from '@/features/big-circle-progress-bar/model/types';
import girl from '@shared/ui/assets/circle-progress-bar/girl-1.png';

export const dataForCircleProgressBar: BigCircleProgressBarType = {
  image: {
    src: girl,
    alt: `${girl}'s photo`,
  },
  topText: 'НЕЗАБЫВАЕМЫЕ ВПЕЧАТЛЕНИЯ',
  middleText: 'Главное, что дает FINE\u00A0DEVELOPMENT — это\u00A0впечатления.',
  textWithImage: `Впечатления, которые вы получаете каждый день от жизни в совершенном доме, и которые настолько
великолепны, что такую жизнь уже невозможно назвать будничной. Вот почему каждый проект Sminex превосходит
все аналоги и становится заметным явлением на рынке, вызовом для конкурентов и новым шагом в развитии
городской среды.`,
};
