const TelegramIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5.28553C21.895 5.73292 21.7855 6.1794 21.6856 6.62784C21.0393 9.52777 20.3951 12.4282 19.7499 15.3283C19.472 16.5774 19.1962 17.8268 18.9119 19.0745C18.8749 19.2366 18.809 19.3977 18.7278 19.5445C18.5105 19.937 18.1526 20.0851 17.712 19.9526C17.5111 19.8922 17.3088 19.8039 17.1391 19.6858C15.7017 18.6858 14.2718 17.676 12.8395 16.6693C12.7876 16.6329 12.7345 16.5981 12.6726 16.5562C12.6251 16.5952 12.5802 16.6286 12.5392 16.6661C11.7998 17.3426 11.0602 18.019 10.322 18.6968C10.1021 18.8986 9.85178 19.0202 9.52284 19.0019C9.55006 18.6115 9.57505 18.2335 9.60306 17.8558C9.66172 17.065 9.7219 16.2743 9.78166 15.4834C9.79108 15.3589 9.82492 15.2315 9.8079 15.1105C9.74356 14.6545 10.0105 14.3902 10.3367 14.1112C12.9881 11.8419 15.6268 9.55887 18.2693 7.27989C18.3081 7.2465 18.3583 7.21835 18.3811 7.17666C18.423 7.10017 18.4484 7.01538 18.4807 6.93404C18.3915 6.91 18.2929 6.85031 18.215 6.8693C18.0547 6.90835 17.894 6.97164 17.7535 7.05588C14.3092 9.12103 10.8674 11.19 7.42604 13.2596C7.32937 13.3178 7.2464 13.3499 7.12229 13.3123C5.66229 12.8698 4.19901 12.4374 2.73911 11.9944C2.56248 11.9408 2.38669 11.8607 2.23557 11.7587C1.95151 11.5669 1.91731 11.2862 2.17144 11.0595C2.35759 10.8934 2.58299 10.746 2.81823 10.6563C4.04825 10.187 5.28666 9.73804 6.52272 9.28336C11.2184 7.55631 15.9141 5.8292 20.6111 4.1056C20.7606 4.05076 20.9235 4.01466 21.0828 4.00291C21.5053 3.97182 21.8187 4.19134 21.9479 4.58563C21.9631 4.63207 21.9825 4.67732 22 4.72311V5.28553Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TelegramIcon;
