import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  privacyPolicyContainer: {
    padding: '8.88888889rem 15.2777778rem 2.22222222rem 15.2777778rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(1, 1fr)',
    gap: '2.98611111rem',
    justifyItems: 'space-between',
    alignItems: 'start',
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '28.0555556rem 4.44444444rem 4.44444444rem 4.44444444rem',
      gap: '4.44444444rem',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
});
