import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    width: '100%',
    padding: '0rem 11.04167rem',

    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '20.66667rem',
      padding: '0rem 0rem',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '30.41667rem',
    gap: '2.08333rem',

    '@media (max-width: 767px)': {
      padding: '0rem 4rem 0rem 4.26667rem',
      width: '100%',
      gap: '6.4rem',
      order: '2',
    },
  },
  title: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.875rem',
    lineHeight: '2.08333rem',

    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.46667rem',
    },
  },
  description: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.04167rem',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
  imagesBlock: {
    width: '43.75rem',
    height: '43.75rem',
    position: 'relative',

    '@media (max-width: 767px)': {
      width: '100rem',
      height: '100rem',
      order: '1',
    },
  },
  allImages: {
    position: 'absolute',
    pointerEvents: 'none',
  },
  spiral: {
    width: '20.74%',
    height: '19.99%',
    top: '19.45%',
    left: '16%',
    zIndex: '1',
  },
  blurLayer: {
    width: '13%',
    height: '13%',
    top: '22.45%',
    left: '25.7%',
    zIndex: '2',
    backdropFilter: 'blur(4px)',
  },
  glass: {
    width: '42.13%',
    height: '31.41%',
    top: '11.26%',
    left: '25.2%',
    zIndex: '2',
  },
  whiteFence: {
    width: '76.81%',
    height: '40.43%',
    top: '32.5%',
    left: '6%',
    zIndex: '3',
  },
  fourLamps: {
    width: '35.53%',
    height: '21.24%',
    top: '21.7%',
    left: '55.5%',
    zIndex: '4',
  },
  sixLamps: {
    width: '47.87%',
    height: '33.12%',
    top: '50.32%',
    left: '1.5%',
    zIndex: '4',
  },
  biggerSpiral: {
    width: '23.4%',
    height: '19.65%',
    top: '66.88%',
    left: '14%',
  },
  goldenCircle: {
    width: '20.74%',
    height: '16.44%',
    top: '52.57%',
    left: '40.3%',
    zIndex: '6',
  },
  grey: {
    width: '28%',
    height: '41.12%',
    top: '42.4%',
    left: '53%',
    zIndex: '5',
  },
  art: {
    width: '30.7%',
    height: '37.53%',
    top: '47.08%',
    left: '56%',
    zIndex: '4',
  },
  block: {
    width: '40%',
    height: '26.1%',
    top: '66.7%',
    left: '58%',
    zIndex: '6',
  },
  graniteFloor: {
    width: '45%',
    height: '12.79%',
    top: '86.5%',
    left: '35%',
    zIndex: '5',
  },
});
