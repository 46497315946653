import useIsMobile from '@/shared/hooks/useIsMobile';
import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useAnimatedLogic = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const wrapperTextRef = useRef<HTMLDivElement | null>(null);

  const upperCircleWithTextRef = useRef<HTMLDivElement | null>(null);
  const rightCircleWithTextRef = useRef<HTMLDivElement | null>(null);
  const leftCircleWithTextRef = useRef<HTMLDivElement | null>(null);
  const mainCircleBlockRef = useRef<HTMLDivElement | null>(null);

  const upperCircleRef = useRef<SVGPathElement | null>(null);
  const rightCircleRef = useRef<SVGPathElement | null>(null);
  const leftCircleRef = useRef<SVGPathElement | null>(null);
  const mainCircleRef = useRef<SVGPathElement | null>(null);

  const valuesTextRef = useRef<HTMLParagraphElement | null>(null);
  const styleTextRef = useRef<HTMLParagraphElement | null>(null);
  const comfortTextRef = useRef<HTMLParagraphElement | null>(null);
  const uniquenessTextRef = useRef<HTMLParagraphElement | null>(null);
  const impressionTextRef = useRef<HTMLParagraphElement | null>(null);
  const functionTextRef = useRef<HTMLParagraphElement | null>(null);
  const aestheticsTextRef = useRef<HTMLParagraphElement | null>(null);

  const { isMobile } = useIsMobile();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const scroll = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 90%',
          end: isMobile ? '180% bottom' : '110% bottom',
          scrub: isMobile ? 3 : 0.5,
          invalidateOnRefresh: true,
        },
      });

      const circle = upperCircleRef.current;
      const length = circle?.getTotalLength();

      scroll
        .fromTo(
          upperCircleRef.current,
          { strokeDasharray: length, strokeDashoffset: length },
          { strokeDashoffset: 0 },
          0
        )
        .fromTo(upperCircleWithTextRef.current, { yPercent: -50 }, { yPercent: 0 }, 0)
        .fromTo(valuesTextRef.current, { opacity: 0 }, { opacity: 1 }, 0.5)
        .fromTo(
          rightCircleRef.current,
          { strokeDasharray: length, strokeDashoffset: length },
          { strokeDashoffset: 0 },
          0.5
        )
        .fromTo(
          leftCircleRef.current,
          { strokeDasharray: length, strokeDashoffset: length },
          { strokeDashoffset: 0 },
          0.5
        );
      !isMobile &&
        scroll.to([styleTextRef.current, comfortTextRef.current], {
          xPercent: (index) => (index === 0 ? -10 : 35),
          yPercent: (index) => (index === 0 ? -60 : -60),
        });
      scroll
        .to(
          [styleTextRef.current, comfortTextRef.current, impressionTextRef.current],
          {
            opacity: 1,
          },
          0.7
        )
        .to(uniquenessTextRef.current, { opacity: 1 }, 1)
        .to(upperCircleRef.current, { opacity: 0.2 }, 1.5)
        .to(rightCircleRef.current, { opacity: 0.2 }, 1.5)
        .to(leftCircleRef.current, { opacity: 0.2 }, 1.5)
        .fromTo(
          mainCircleRef.current,
          { strokeDasharray: length, strokeDashoffset: length },
          { strokeDashoffset: 0 },
          1.7
        );

      isMobile
        ? scroll.fromTo(
            rightCircleWithTextRef.current,
            { yPercent: 100, xPercent: 100 },
            { yPercent: 36, xPercent: 30 },
            0.5
          )
        : scroll.fromTo(
            rightCircleWithTextRef.current,
            { yPercent: 100, xPercent: 100 },
            { yPercent: 55, xPercent: 33 },
            0.5
          );
      isMobile
        ? scroll.fromTo(
            leftCircleWithTextRef.current,
            { yPercent: 100, xPercent: -100 },
            { yPercent: 36, xPercent: -30 },
            0.5
          )
        : scroll.fromTo(
            leftCircleWithTextRef.current,
            { yPercent: 100, xPercent: -100 },
            { yPercent: 55, xPercent: -33 },
            0.5
          );
      isMobile
        ? scroll.to(upperCircleWithTextRef.current, { yPercent: 34, scale: 0.57 }, 1.5)
        : scroll.to(upperCircleWithTextRef.current, { yPercent: 17.5, scale: 0.57 }, 1.5);

      const initialFontSize = uniquenessTextRef.current
        ? parseFloat(getComputedStyle(uniquenessTextRef.current).fontSize)
        : 0;
      const targetFontSize = initialFontSize * 5 + 'px';
      const targetFontSizeMob = initialFontSize * 4.2 + 'px';

      isMobile
        ? scroll.to(
            uniquenessTextRef.current,
            {
              color: colors.primaryText,
              fontFamily: fonts.din2014Regular,
              fontSize: targetFontSizeMob,
              fontWeight: '400',
              top: '80%',
              ease: 'power2.out',
            },
            1.5
          )
        : scroll.to(
            uniquenessTextRef.current,
            {
              color: colors.primaryText,
              fontFamily: fonts.din2014Regular,
              fontSize: targetFontSize,
              fontWeight: '400',
              top: '87%',
            },
            1.5
          );
      isMobile
        ? scroll.to(rightCircleWithTextRef.current, { xPercent: 20, yPercent: 44, scale: 0.57 }, 1.5)
        : scroll.to(rightCircleWithTextRef.current, { xPercent: 19.5, yPercent: 47, scale: 0.57 }, 1.5);
      isMobile
        ? scroll.to(leftCircleWithTextRef.current, { xPercent: -20, yPercent: 44, scale: 0.57 }, 1.5)
        : scroll.to(leftCircleWithTextRef.current, { xPercent: -19.5, yPercent: 47, scale: 0.57 }, 1.5);
      isMobile
        ? scroll.to([styleTextRef.current, comfortTextRef.current, impressionTextRef.current], { opacity: 0 }, 1.4)
        : scroll.to(
            [styleTextRef.current, comfortTextRef.current, impressionTextRef.current],
            {
              fontSize: '1.8rem',
            },
            1.4
          );
      isMobile
        ? scroll.to(valuesTextRef.current, { opacity: 0 }, 1.5)
        : scroll.to(
            valuesTextRef.current,
            {
              fontSize: '1.8rem',
              yPercent: 270,
              xPercent: 20,
            },
            1.5
          );
      isMobile
        ? scroll.to([functionTextRef.current, aestheticsTextRef.current], { opacity: 0 }, 1.5)
        : scroll.to(
            [functionTextRef.current, aestheticsTextRef.current],
            {
              yPercent: 200,
              fontSize: '1.8rem',
              xPercent: (index) => (index === 0 ? 50 : -15),
            },
            1.5
          );

      const scroolWrapperText = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperTextRef.current,
          start: 'top 120%',
          end: '10% 90%',
          scrub: 0.5,
          invalidateOnRefresh: true,
        },
      });
      if (!isMobile) {
        scroolWrapperText.to(upperCircleWithTextRef.current, { xPercent: -90 }, 1);
        scroolWrapperText.to(rightCircleWithTextRef.current, { xPercent: -70.5 }, 1);
        scroolWrapperText.to(leftCircleWithTextRef.current, { xPercent: -109.5 }, 1);
        scroolWrapperText.to(mainCircleBlockRef.current, { xPercent: -90 }, 1);
      }
    });

    return () => ctx.revert();
  }, [isMobile]);

  return {
    containerRef,
    upperCircleWithTextRef,
    upperCircleRef,
    valuesTextRef,
    styleTextRef,
    comfortTextRef,
    uniquenessTextRef,
    impressionTextRef,
    rightCircleWithTextRef,
    rightCircleRef,
    functionTextRef,
    leftCircleWithTextRef,
    leftCircleRef,
    aestheticsTextRef,
    mainCircleBlockRef,
    mainCircleRef,
    wrapperTextRef,
  };
};

export default useAnimatedLogic;
