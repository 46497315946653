import { ProfileCardType } from '../../model/types';
import { DesktopVicePresidentCard } from './desktop-vice-president-card';
import { MobileVicePresidentCard } from './mobile-vice-president-card';

export interface ProfileCardExpandedProps {
  item: ProfileCardType;
  isExpanded: boolean;
  onToggleExpand: () => void;
  containerRef: React.RefObject<HTMLElement>;
  getCard: () => HTMLElement | null;
}
export const ProfileVicePresidentCard = ({
  item,
  isExpanded,
  onToggleExpand,
  getCard,
  containerRef,
}: ProfileCardExpandedProps) => {
  return (
    <>
    {
      <DesktopVicePresidentCard
        item={item}
        isExpanded={isExpanded}
        onToggleExpand={onToggleExpand}
        getCard={getCard}
        containerRef={containerRef}
      />
    }

      <MobileVicePresidentCard
        item={item}
        isExpanded={isExpanded}
        onToggleExpand={onToggleExpand}
        getCard={getCard}
        containerRef={containerRef}
      />
    </>
  );
};
