import { Link } from 'react-router-dom';
import { INewsItem } from '@/pages/info-center-all-news-page/model';
import { getFormattedDate } from '@/shared/utils/dates.ts';

import * as stylex from '@stylexjs/stylex';
import styleModule from './styles.module.css';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';

import { MOCK_CATEGORIES } from '@/shared/mockData/info-center/info-center-all-news-mock.ts';

const NewsGridCard = ({ item }: { item: INewsItem }) => {
  return (
    <Link to={`/kompaniya/novosti/${item.id}`} className={styleModule.linkStyle}>
      <div {...stylex.props([styles.newsCard])}>
        <img src={item.image} alt={item.title} {...stylex.props([styles.newsCardImg])} />
        <div {...stylex.props([styles.newsCardContent])}>
          <div {...stylex.props([styles.newsCardCategory])}>
            {MOCK_CATEGORIES?.[Number(item.categoryId) - 1]?.title}
          </div>
          <div {...stylex.props([styles.newsCardDate])}>
            {getFormattedDate({
              date: item?.date,
              addYear: true,
            })}
          </div>
          <div {...stylex.props([styles.newsCardHeading])}>{item.title}</div>
        </div>
      </div>
    </Link>
  );
};

export default NewsGridCard;
