import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';
import { colors } from '@/shared/tokens/colors.stylex.ts';

export const styles = stylex.create({
  newsGridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    margin: '0 auto',
    gap: '1.25rem',
    fontSize: '1rem',
    paddingBottom: '2.916rem',
    paddingHorizontal: '11.04168rem',
    fontFamily: fonts.din2014Light,
  },
  categoryItemContainer: {
    display: 'flex',
    gap: '0.625rem',
    position: 'relative',
    shadowOffset: '10px 10px',
    backdropFilter: 'blur(10px)',
    backgroundColor: palette['lighted-80'],
    width: '100%',
    margin: '0 auto',
    flexWrap: 'wrap',
    zIndex: 5,
    paddingTop: '6.2533333rem',
    paddingBottom: '2.08333rem',
    paddingHorizontal: '11.04168rem',
    '-webkit-backdrop-filter': 'blur(10px)',
    transition: 'all 0.3s ease-in-out',
    opacity: 1,
    '@media (max-width: 767px)': {
      left: 0,
      gap: '3.2rem',
      margin: '0',
      paddingTop: '27.73rem',
      paddingBottom: '6.4rem',
      paddingHorizontal: '4.26667rem',
      backgroundColor: palette['lighted-80'],
      shadowColor: colors.tertiaryBorder,
      shadowOffset: '10px 10px',
      backdropFilter: 'blur(10px)',
      '-webkit-backdrop-filter': 'blur(10px)',
      borderBottom: `1px solid ${colors.secondaryBorder}`,
    },
  },
  categoryItemsContainerSticky: {
    zIndex: 10,
    paddingTop: '2.08333rem',
    '@media (max-width: 767px)': {
      paddingTop: '6.4rem',
    },
  },
  defaultBtn: {
    padding: '0.4166667rem 0.833333rem',
    borderRadius: '1000px',
    border: 'none',
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 300,
    transition: 'color 0.4s ease, background-color 0.4s ease',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      padding: '2.13333rem 4.26667rem',
      fontSize: '4.8rem',
      lineHeight: '6.4rem',
    },
  },
  activeBtn: {
    backgroundColor: colors.primaryInverseBackground,
    color: colors.primaryInverseText,
  },
  nonActiveBtn: {
    backgroundColor: {
      default: colors.brandBackground,
      ':hover': colors.primaryInverseBackground,
      ':active': colors.primaryInverseBackground,
    },
    color: {
      default: colors.primaryText,
      ':hover': colors.primaryInverseText,
      ':active': colors.primaryInverseText,
    },
  },
  expandBtn: {
    maxHeight: '2.08333333rem',
    maxWidth: '2.91666667rem',
    '@media (max-width: 767px)': {
      maxHeight: '10.666667rem',
      maxWidth: '14.9333333rem',
    },
  },
  closeBtn: {
    height: '6.4rem',
    width: '6.4rem',
  },
  sticky: {
    position: 'fixed',
    top: '4.17rem',
    opacity: 1,
    '@media (max-width: 767px)': {
      top: '21.33rem',
    },
  },
  hidden: {
    display: 'none',
  },
  viewSwitchContainer: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'space-between',
    minHeight: '5.41667rem',
    maxHeight: '5.41667rem',
    borderBottom: '1px solid',
    borderColor: colors.tertiaryBorder,
    marginHorizontal: '11.04168rem',
    marginBottom: '2.91666667rem',
    '@media (max-width: 767px)': {
      minHeight: '29.8666667rem',
      maxHeight: '29.8666667rem',
      marginHorizontal: '0',
      borderColor: colors.tertiaryBorder,
      marginBottom: '0',
      borderBottom: '0',
    },
  },
  viewSwitcher: {
    display: 'flex',
    maxWidth: '15.208rem',
    minWidth: '15.208rem',
    justifyContent: 'space-between',
    margin: 'auto 0',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  indexBtnText: {
    textAlign: 'left',
    marginVertical: 'auto',
  },
  indexIcon: {
    display: 'block',
    marginVertical: 'auto',
    minWidth: '3.54167rem',
    height: '3.54167rem',
  },
  indexBtn: {
    width: '23.2812rem',
    boxSizing: 'content-box',
    zIndex: 5,
    margin: 'auto 0',
    marginRight: '0.416666667rem',
    '@media (max-width: 767px)': {
      width: '80.26666667rem',
      height: '12.8rem',
      marginHorizontal: '4.2666667rem',
      paddingVertical: '8.5333333rem',
      marginRight: '2.1333333rem',
    },
  },
  newsCard: {
    backgroundColor: colors.brandBackground,
    paddingBottom: '71.196%',
    position: 'relative',
  },
  newsCardContent: {
    zIndex: 3,
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: '2.083333rem',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
  },
  newsCardImg: {
    zIndex: 2,
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    transition: 'scale 1s ease',
  },
  newsCardCategory: {
    marginBottom: 'auto',
    backgroundColor: colors.brandBackground,
    color: colors.primaryText,
    width: 'max-content',
    padding: '0.4167rem 0.8333rem',
    borderRadius: '1000px',
    fontSize: '0.9375rem',
  },
  newsCardDate: {
    fontSize: '1.25rem',
    paddingBottom: '1.25rem',
  },
  newsCardHeading: {
    textTransform: 'uppercase',
    fontSize: '1.875rem',
    lineHeight: '2.083333rem',
    color: colors.primaryInverseText,
    fontWeight: 300,
    fontFamily: fonts.din2014Light,
  },
  newsColumnCard: {
    backgroundColor: {
      ':hover': colors.brandGhostBackground,
    },
    transition: 'background-color 0.4s ease',
    paddingHorizontal: '11.04168rem',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      paddingHorizontal: 0,
    },
  },
  newsColumnCardContentWrapper: {
    display: 'grid',
    gridTemplateColumns: '22.5rem 1fr 22.5rem',
    borderColor: colors.tertiaryBorder,
    borderBottom: '1px solid',
    paddingVertical: '2.91667rem',
    gap: '3.229167rem',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      marginHorizontal: '4.26667rem',
      paddingVertical: '8.53333rem',
      gap: '8.53333rem',
    },
  },
  newsColumnCardImage: {
    height: '12.6042rem',
    objectFit: 'cover',
    transition: 'backgroundColor 2s ease',
    maxWidth: '22.5rem',
    minWidth: '22.5rem',
    maxHeight: '12.6rem',
    minHeight: '12.6045rem',
    '@media (max-width: 767px)': {
      height: '64.53333rem',
      maxWidth: '100%',
      minWidth: '100%',
      minHeight: '64.5333rem',
      maxHeight: '64.5333rem',
    },
  },
  newsColumnCardCenterContainer: {},
  newsColumnCardTitle: {
    fontFamily: fonts.din2014Light,
    color: colors.primaryText,
    fontSize: '1.875rem',
    lineHeight: '2.083333rem',
    paddingBottom: '1.25rem',
    textTransform: 'uppercase',
    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.466667rem',
      paddingBottom: '4.26667rem',
    },
  },
  newsColumnCardDate: {
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    color: colors.tertiaryText,
    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.266667rem',
    },
  },
  newsColumnCardContent: {
    minWidth: '22.5rem',
    maxWidth: '22.5rem',
    fontFamily: fonts.din2014Light,
    color: colors.primaryText,
    fontSize: '1.041667rem',
    lineHeight: '1.4583333rem',
    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  link: {
    textDecoration: 'unset',
    color: 'inherit',
  },
});
