import { ICategoryItemProps } from '@/pages/info-center-all-news-page/ui/components/category-filter/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-all-news-page/ui/components/styles.ts';

const CategoryItem = ({ item, onClick, isActive }: ICategoryItemProps) => {
  return (
    <button
      onClick={() => onClick?.(item)}
      {...stylex.props([styles.defaultBtn, isActive ? styles.activeBtn : styles.nonActiveBtn])}
    >
      {item?.title ?? '-'}
    </button>
  );
};

export default CategoryItem;
