import { Options } from '@/shared/ui/icons/Options';
import styles from './header-options-btn.module.css'

export const HeaderOptionsBtn = () => {
  return (
    <div className={styles.headerOptionsBtnWrap}>
      <Options />
    </div>
  );
};
