import React from 'react';

interface BigPathProps {
  strokeColor?: string;
  pathRef?: React.Ref<SVGPathElement> | null;
}

export const BigPath: React.FC<BigPathProps> = ({ strokeColor, pathRef }) => {
  return (
    <path
      d="M403 805C-138.5 779 -128.5 22.5 403 0"
      stroke={strokeColor ? strokeColor : 'black'}
      fill="none"
      strokeWidth="1px"
      ref={pathRef ? pathRef : null}
    ></path>
  );
};
