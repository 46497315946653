import React, { useEffect, useRef, useState } from 'react';
import { OnSliderLoadCallback } from '@/features/before-after-slider/ui/before-after-one-slide/model/types.ts';

export function useReadyStatus(
  imagesWidth: number | null,
  refContainer: React.RefObject<HTMLDivElement>,
  onReady?: OnSliderLoadCallback
) {
  const [isReady, setIsReady] = useState(false);

  const imagesLoadedRef = useRef<[boolean, boolean]>([false, false]);
  const onImageLoad = (idx: 0 | 1) => {
    const newImagesLoadedRef: [boolean, boolean] = [...imagesLoadedRef.current];
    newImagesLoadedRef[idx] = true;
    imagesLoadedRef.current = newImagesLoadedRef;
  };

  useEffect(() => {
    if (!isReady && imagesLoadedRef.current.every(Boolean) && imagesWidth && refContainer.current) {
      setIsReady(true);
    }
  }, [imagesLoadedRef.current, imagesWidth, isReady, refContainer.current]);

  useEffect(() => {
    if (isReady && onReady) {
      onReady();
    }
  }, [isReady]);

  return {
    onImageLoad,
    isReady,
  };
}
