import React, { useLayoutEffect, useRef } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import fourLamps from '@shared/ui/assets/animated-images/4-lampy.png';
import sixLamps from '@shared/ui/assets/animated-images/6-lamp.png';
import whiteFence from '@shared/ui/assets/animated-images/beloe-ograzhdenie.png';
import block from '@shared/ui/assets/animated-images/glyba.png';
import graniteFloor from '@shared/ui/assets/animated-images/granit-pol.png';
import art from '@shared/ui/assets/animated-images/iskustvo.png';
import grey from '@shared/ui/assets/animated-images/seroe.png';
import biggerSpiral from '@shared/ui/assets/animated-images/spiral-pobolshe.png';
import spiral from '@shared/ui/assets/animated-images/spiral.png';
import glass from '@shared/ui/assets/animated-images/steklo.png';
import goldenCircle from '@shared/ui/assets/animated-images/zolotoy-krug.png';
import gsap from 'gsap';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { TextBlockType } from '../model/types';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface AnimatedImagesWithTextProps {
  textBlock: TextBlockType;
}

export const AnimatedImagesWithText: React.FC<AnimatedImagesWithTextProps> = ({ textBlock }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imagesBlockRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useIsMobile();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (!isMobile) {
        const images = Array.from(imagesBlockRef.current?.querySelectorAll('img') || []).filter((image) =>
          image.hasAttribute('data-scroll-speed')
        );

        const scrollAnimation = [
          { startY: 20.429844, endY: -20.288013 },
          { startY: -45.179199, endY: 45.917915 },
          { startY: -51.341277, endY: 49.444532 },
          { startY: 29.153326, endY: -30.782271 },
          { startY: 10.313905, endY: -10.701185 },
          { startY: 29.528246, endY: -29.533808 },
        ];

        images.forEach((image, index) => {
          const animation = scrollAnimation[index];
          if (animation) {
            gsap.fromTo(
              image,
              {
                y: animation.startY,
              },
              {
                y: animation.endY,
                scrollTrigger: {
                  trigger: containerRef.current,
                  start: 'top 80%',
                  end: 'bottom',
                  scrub: true,
                },
              }
            );
          }
        });
      }
    });

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <div {...stylex.props(styles.container)} ref={containerRef}>
      <div {...stylex.props(styles.textContainer)}>
        <p {...stylex.props(styles.title)}>{textBlock.title}</p>
        <p {...stylex.props(styles.description)}>{textBlock.description}</p>
      </div>
      <div {...stylex.props(styles.imagesBlock)} ref={imagesBlockRef}>
        <img {...stylex.props(styles.allImages, styles.spiral)} src={spiral} alt="спираль" />
        <div {...stylex.props(styles.allImages, styles.blurLayer)}></div>
        <img {...stylex.props(styles.allImages, styles.glass)} src={glass} alt="стекло" data-scroll-speed="0.4" />
        <img {...stylex.props(styles.allImages, styles.whiteFence)} src={whiteFence} alt="белое ограждение" />
        <img
          {...stylex.props(styles.allImages, styles.fourLamps)}
          src={fourLamps}
          alt="4 лампы"
          data-scroll-speed="-1"
        />
        <img {...stylex.props(styles.allImages, styles.sixLamps)} src={sixLamps} alt="6 ламп" data-scroll-speed="-1" />
        <img {...stylex.props(styles.allImages, styles.biggerSpiral)} src={biggerSpiral} alt="спираль большая" />
        <img
          {...stylex.props(styles.allImages, styles.goldenCircle)}
          src={goldenCircle}
          alt="золотой круг"
          data-scroll-speed=".7"
        />
        <img {...stylex.props(styles.allImages, styles.grey)} src={grey} alt="серое" />
        <img {...stylex.props(styles.allImages, styles.art)} src={art} alt="искусство" data-scroll-speed=".2" />
        <img {...stylex.props(styles.allImages, styles.block)} src={block} alt="глыба" />
        <img
          {...stylex.props(styles.allImages, styles.graniteFloor)}
          src={graniteFloor}
          alt="гранитная плита"
          data-scroll-speed="0.7"
        />
      </div>
    </div>
  );
};
