import styles from './modal.module.css';
import { ReactNode, useEffect } from 'react';
import { Close } from '../../icons/Close';
import { Portal } from './portal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  isFullScreen?: boolean;
  isModalForApartmentPlan?: boolean;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  isFullScreen = false,
  isModalForApartmentPlan = false,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modalNoScroll'); // Добавляем класс
    } else {
      document.body.classList.remove('modalNoScroll'); // Убираем класс
    }
  
    return () => {
      document.body.classList.remove('modalNoScroll'); // Чистим класс при размонтировании
    };
  }, [isOpen]);
  
  return (
    <Portal>
      <div
        className={`${styles.modalOverlay} ${isOpen ? styles.open : ''} ${isModalForApartmentPlan ? styles.modalOverlayForApartmentPlan : ''}`}
      >
        <div
          className={`${styles.modalContent} ${isOpen ? styles.open : ''} ${isFullScreen ? styles.modalContentNotFullScreen : ''} ${isModalForApartmentPlan ? styles.modalContetnForApartmentPlan : ''}`}
        >
          {children}
          <button
            className={`${styles.closeButton} ${isFullScreen ? styles.fullScreenPosition : ''} ${isModalForApartmentPlan ? styles.closeButtonForApartmentPlan : ''}`}
            onClick={onClose}
          >
            <Close />
          </button>
        </div>
      </div>
    </Portal>
  );
};
