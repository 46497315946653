import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.08333rem',

    '@media (max-width: 767px)': {
      gap: '6.4rem',
    },
  },
  header: {
    width: '100%',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.875rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '2.08333rem',

    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.46667rem',
    },
  },
  descriptionWithlist: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.25rem',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      gap: '4.26667rem',
    },
  },
  description: {
    width: '100%',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
  descriptionListWithList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '0.625rem',

    '@media (max-width: 767px)': {
      gap: '2.13333rem',
    },
  },
  descriptionList: {
    width: '100%',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.04167rem',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    gap: '0.625rem',
    listStyle: 'none',

    '@media (max-width: 767px)': {
      gap: '2.13333rem',
    },
  },
  oneElement: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.20833rem',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      gap: '1.06667rem',
    },
  },
  svgIcon: {
    width: '1.45833rem',
    height: '1.45833rem',
    flexShrink: '0',
    color: colors.primaryIcon,

    '@media (max-width: 767px)': {
      width: '6.4rem',
      height: '6.4rem',
    },
  },
  elementText: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.04167rem',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
      flex: '1 0 0',
    },
  },
});
