import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.1111rem', // 16px / 14.4
    alignItems: 'center',
    width: '92.2222rem', // 1328px / 14.4
    overflowX: 'hidden',
    flexWrap: 'nowrap',
    opacity: 1,
    transform: 'translateY(0)',
    backgroundColor: colors.brandGhostBackground,
    transition: 'transform 0.6s ease, opacity 0.6s ease',
    position: 'sticky',
    top: '4.17rem',
    zIndex: '100',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '@media (max-width: 767px)': {
      width: '91.1111rem',
      gap: '2.2222rem', // 8px / 3.6
      top: '12.2222rem', // 44px / 3.6
      boxSizing: 'content-box',
    },
  },

  containerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    // gap: '16px', // 16px / 14.4
    alignItems: 'center',
    width: '92.2222rem', // 1328px / 14.4
    flexWrap: 'nowrap',
    opacity: 1,
    transform: 'translateY(0)',
    backgroundColor: colors.brandGhostBackground,
    transition: 'transform 0.6s ease, opacity 0.6s ease',
    position: 'sticky',
    top: '4.17rem',
    paddingTop: '1.1111rem', // 16px / 14.4
    zIndex: '100',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '@media (max-width: 767px)': {
      width: '91.1111rem',
      // gap: '8px', // 8px / 3.6
      top: '12.2222rem', // 44px / 3.6
      paddingTop: '2.2222rem', // 8px / 3.6
      boxSizing: 'content-box',
    },
  },
});
