export const GridIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M3.125 5.7207C3.125 4.68517 3.96447 3.8457 5 3.8457H6.875C7.91053 3.8457 8.75 4.68517 8.75 5.7207V7.5957C8.75 8.63124 7.91053 9.4707 6.875 9.4707H5C3.96447 9.4707 3.125 8.63124 3.125 7.5957V5.7207Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.125 13.8457C3.125 12.8102 3.96447 11.9707 5 11.9707H6.875C7.91053 11.9707 8.75 12.8102 8.75 13.8457V15.7207C8.75 16.7562 7.91053 17.5957 6.875 17.5957H5C3.96447 17.5957 3.125 16.7562 3.125 15.7207V13.8457Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 5.7207C11.25 4.68517 12.0895 3.8457 13.125 3.8457H15C16.0355 3.8457 16.875 4.68517 16.875 5.7207V7.5957C16.875 8.63124 16.0355 9.4707 15 9.4707H13.125C12.0895 9.4707 11.25 8.63124 11.25 7.5957V5.7207Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 13.8457C11.25 12.8102 12.0895 11.9707 13.125 11.9707H15C16.0355 11.9707 16.875 12.8102 16.875 13.8457V15.7207C16.875 16.7562 16.0355 17.5957 15 17.5957H13.125C12.0895 17.5957 11.25 16.7562 11.25 15.7207V13.8457Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
