const useSwipeLogic = (onLeftSwipe: () => void, onRigthSwipe: () => void) => {
  let startX: number;
  let startY: number;
  let startPosition: number;
  let isSwiping = false;
  const SWIPE_THRESHOLD = 50;
  const ANGLE_THRESHOLD = 25;

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.touchAction = 'none';
  };

  const enableScroll = () => {
    document.body.style.overflow = '';
    document.body.style.touchAction = '';
  };

  const handleStart = (clientX: number, clientY: number, target: Element) => {
    const domRect = target.getBoundingClientRect();
    startX = clientX;
    startY = clientY;
    startPosition = (startX - domRect.left) / domRect.width;
    isSwiping = true;
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    handleStart(event.clientX, event.clientY, event.currentTarget);
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    const touch = event.touches[0];
    handleStart(touch.clientX, touch.clientY, event.currentTarget);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isSwiping) return;
    const diffX = startX - event.clientX;
    const diffY = event.clientY - startY;
    const MIDDLE_POSITION = 0.5;
    const RADIANS_TO_DEGREES = 180;
    const angle = (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * RADIANS_TO_DEGREES) / Math.PI;

    if (angle <= ANGLE_THRESHOLD) {
      if (Math.abs(diffX) > SWIPE_THRESHOLD) {
        if (diffX > 0 && startPosition > MIDDLE_POSITION) {
          onRigthSwipe?.();
        }
        if (diffX < 0 && startPosition < MIDDLE_POSITION) {
          onLeftSwipe?.();
        }
        isSwiping = false;
      }
    }
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    const touch = event.touches[0];
    if (!isSwiping) return;

    const diffX = startX - touch.clientX;
    const diffY = touch.clientY - startY;
    const RADIANS_TO_DEGREES = 180;

    const angle = (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * RADIANS_TO_DEGREES) / Math.PI;

    if (angle <= ANGLE_THRESHOLD) {
      event.preventDefault();
      disableScroll();

      if (Math.abs(diffX) > SWIPE_THRESHOLD) {
        if (diffX > 0) {
          onRigthSwipe?.();
        }
        if (diffX < 0) {
          onLeftSwipe?.();
        }
        isSwiping = false;
        enableScroll();
      }
    } else {
      enableScroll();
    }
  };

  const handleMouseUp = () => {
    isSwiping = false;
  };

  const handleTouchEnd = () => {
    isSwiping = false;
    enableScroll();
  };

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default useSwipeLogic;
