import { Popover } from '@/shared/ui/components/popover';
import { IconWrapper } from '@/shared/ui/components/icon-wrapper';
import { IUpperSection } from '@/features/roompicker-grid-card/model/types.ts';

import { FavSmallIcon } from '@/shared/ui/icons/fav-small-icon.tsx';
import { FavMediumIcon } from '@/shared/ui/icons/fav-medium-icon.tsx';
import { CompareSmallIcon } from '@/shared/ui/icons/compare-small-icon.tsx';
import { CompareMediumIcon } from '@/shared/ui/icons/compare-medium-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './stylex.ts';

export const UpperSection = ({
  isToComparison,
  isFavorite,
  showBuildingName,
  buildingName,
  address,
  onCompareClick,
  onFavoriteClick,
}: IUpperSection) => {
  return (
    <div {...stylex.props(styles.upperInfo)}>
      <div {...stylex.props(styles.upperInfoTextContainer)}>
        {showBuildingName && buildingName && (
          <div {...stylex.props([styles.upperBuildingName, styles.textTruncate])}>{buildingName}</div>
        )}
        <div {...stylex.props([styles.upperInfoText, styles.textTruncate])}>{address}</div>
      </div>
      <div {...stylex.props([styles.iconsContainer, styles.desktopIconsContainer])}>
        <Popover content={'Добавить в сравнение'} position="bottom">
          <IconWrapper onClick={onCompareClick} icon={<CompareSmallIcon />} isActive={isToComparison} />
        </Popover>
        <Popover content={'Добавить в избранное'} position="bottom">
          <IconWrapper onClick={onFavoriteClick} icon={<FavSmallIcon />} isActive={isFavorite} />
        </Popover>
      </div>
      <div {...stylex.props([styles.iconsContainer, styles.mobileIconsContainer])}>
        <IconWrapper onClick={onCompareClick} icon={<CompareMediumIcon />} isActive={isToComparison} size="large" />
        <IconWrapper onClick={onFavoriteClick} icon={<FavMediumIcon />} isActive={isFavorite} size="large" />
      </div>
    </div>
  );
};
