import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { Modal } from '@/shared/ui/modal';

interface WalkModeProps{
    url:string;
    isOpen:boolean;
    onClose:() => void
}

export const WalkMode = ({url,isOpen,onClose}:WalkModeProps) => {


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <iframe
        {...stylex.props(styles.walkModeBlock)}
        src={url}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Modal>
  );
};
