import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  menuItem: (isSelected: boolean) => ({
    fontFamily:isSelected ?fonts.din2014Regular : fonts.din2014Light,
    fontSize: '1.0417rem',
    fontWeight: '300',
    lineHeight: '140%',
    color: isSelected ? colors.primaryText : colors.secondaryText,
    listStyleType: 'none',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      fontSize: '4.27rem',
      lineHeight: '150%',
    }
  }),
  buildingLastItem: {},
  nonBuildingFirstItem: {},
  underLine: {
    borderBottom: '1px solid rgba(29, 37, 45, 0.20)',
    width: '14.584rem',
    '@media (max-width: 767px)': {
      width:'100%'
    }
  },
});
