import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useConditionalRender = ({ noRenderRoutes }: { noRenderRoutes: string[] }) => {
  const { pathname } = useLocation();
  const [isRender, setIsRender] = useState(true);

  useEffect(() => {
    setIsRender(!noRenderRoutes.includes(pathname));
  }, [pathname, noRenderRoutes]);

  return { isRender };
};

export default useConditionalRender;
