import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IApartment, IHeaders } from './mockData';
import { Column } from './col';
import React, { useState } from 'react';
import { smoothScrollTo } from '@/shared/utils/scroll-polyfill';

export interface IVerticalDataGridProps {
  apartments: IApartment[];
  headers: IHeaders;
  currentCardWidth: number;
  ref: React.Ref<HTMLDivElement>;
  onScroll?: () => void;
  gapBetweenCardsInRem: number;
}

export const VerticalDataGrid = React.forwardRef<HTMLDivElement, IVerticalDataGridProps>(
  ({ apartments, headers, currentCardWidth, onScroll, gapBetweenCardsInRem }, ref) => {
    const [startPosition, setStartPosition] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
      setStartPosition(e.pageX - ref.current.offsetLeft);
      setScrollLeft(ref.current.scrollLeft);
      ref.current.style.cursor = 'grabbing';
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isDragging || !ref || typeof ref === 'function' || !ref.current) return;
      e.preventDefault();
      const x = e.pageX - ref.current.offsetLeft;
      const walk = (x - startPosition) * 0.5;
      ref.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      if (!ref || typeof ref === 'function' || !ref.current) return;
      ref.current.style.cursor = 'grab';
      snapToClosestColumn();
    };

    const snapToClosestColumn = () => {
      if (!ref || typeof ref === 'function' || !ref.current) return;
      const columnWidth = Math.round(currentCardWidth + (gapBetweenCardsInRem * window.innerWidth) / 100);
      const currentScrollLeft = ref.current.scrollLeft;
      const snappedScrollLeft = Math.round(currentScrollLeft / columnWidth) * columnWidth;
      smoothScrollTo(ref.current, snappedScrollLeft, 300); // Полифилл для корректной работы в Safari
    };

    return (
      <div {...stylex.props(styles.gridContainer)}>
        <div
          {...stylex.props(styles.gridWrapper(apartments.length))}
          ref={ref}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onScroll={onScroll}
        >
          <div {...stylex.props(styles.contentGrid(apartments.length))}>
            {apartments.map((apartment, index) => (
              <Column key={index} apartment={apartment} />
            ))}
          </div>
        </div>
        {apartments.length > 0 && (
          <div {...stylex.props(styles.headerContainer)}>
            {headers.map((header, index) => (
              <div {...stylex.props(styles.header)} key={index}>
                {header}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);
