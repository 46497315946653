import ShareSection from '@/pages/info-center-news-page/ui/components/share-section.tsx';
import { INewsItem } from '@/pages/info-center-all-news-page/model';
import { getCategoryTitle } from '@/pages/info-center-news-page/lib';
import { getFormattedDate } from '@/shared/utils/dates.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-news-page/ui/components/styles.ts';
import stylesModule from '@/pages/info-center-news-page/ui/index.module.css';

const MainContentSection = ({ newsItem }: { newsItem?: INewsItem }) => {
  return (
    <>
      <div {...stylex.props([styles.contentContainer])}>
        <div {...stylex.props([styles.date])}>{getFormattedDate({ date: newsItem?.date })}</div>
        <div {...stylex.props([styles.heading])}>{newsItem?.title}</div>
        <div {...stylex.props([styles.categoryPlate])}>{getCategoryTitle(newsItem?.categoryId)}</div>
        {newsItem?.content && (
          <div className={stylesModule.textContentContainer} dangerouslySetInnerHTML={{ __html: newsItem?.content }} />
        )}
        <img src={newsItem?.image} alt={newsItem?.title} {...stylex.props([styles.image])} />
        <ShareSection />
      </div>
    </>
  );
};

export default MainContentSection;
