import React from 'react';

interface MobilePathProps {
  strokeColor?: string;
  pathRef?: React.Ref<SVGPathElement> | null;
}

export const MobilePath: React.FC<MobilePathProps> = ({ strokeColor, pathRef }) => {
  return (
    <path
      d="M187.5 457.98C61.3029 457.98 -41 355.677 -41 229.48C-41 103.283 61.3029 0.97998 187.5 0.97998C313.697 0.97998 416 103.283 416 229.48C416 355.677 313.697 457.98 187.5 457.98Z"
      stroke={strokeColor ? strokeColor : 'black'}
      fill="none"
      strokeWidth="1px"
      ref={pathRef ? pathRef : null}
    />
  );
};
