import cn from 'classnames';
import styles from './photo-description.module.css';

interface IPhotoDescriptionProps {
  description?: string;
  btnsPosition?: 'bottom-left' | 'bottom-right' | 'center';
}

const PhotoDescription = ({ description, btnsPosition = 'center' }: IPhotoDescriptionProps) => {
  return (
    <div className={cn(styles.photoAlt)} data-variant={btnsPosition === 'bottom-right' ? 'reversed' : 'ordinary'}>
      {description}
    </div>
  );
};

export default PhotoDescription;
