import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { PopoverZoomIn } from '@/shared/ui/popoover-zoom-in';
import { useState } from 'react';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import { CompareIcon } from '@/shared/ui/icons/CompareIcon';
import { BinIcon } from '@/shared/ui/icons/BinIcon';

export interface IApartmentCardPlanProps {
  apartment: IApartment;
}

export const ApartmentCardPlan = ({ apartment }: IApartmentCardPlanProps) => {
  const [isPlanHovered, setIsPlanHovered] = useState(false);
  const [isAddedToCompare, setIsAddedToCompare] = useState(false);
  return (
    <div
      {...stylex.props(styles.plan)}
      onMouseEnter={() => setIsPlanHovered(true)}
      onMouseLeave={() => setIsPlanHovered(false)}
    >
      <PopoverZoomIn
        children={<img src={apartment.plan} {...stylex.props(styles.img)}></img>}
        handleClick={() => {}}
      ></PopoverZoomIn>
      <div {...stylex.props(styles.hoveredIcons(isPlanHovered))}>
        <div
          {...stylex.props(styles.subStrate(isAddedToCompare))}
          role="button"
          onClick={() => setIsAddedToCompare(!isAddedToCompare)}
        >
          <CompareIcon status={isAddedToCompare} />
        </div>
        <div {...stylex.props(styles.subStrate(false))} role="button">
          <BinIcon />
        </div>
      </div>
    </div>
  );
};
