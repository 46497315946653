import styles from './mobile-slider-dropdown.module.css';
import { MobileSliderDropdownProps } from '../../model/types';
import cs from 'classnames';
import MobileAccordion from '../mobile-accordion/mobile-accordion';

export default function MobileSliderDropdown({
  options,
  activeColor,
  inactiveColor,
  activeIndex,
  setActiveIndex,
}: MobileSliderDropdownProps) {
  const activeAccordionSlides = options[activeIndex]?.slides ?? [];

  return (
    <div className={styles.sliderDropdownContainer}>
      {options.map(({ title, description }, index) => (
        <div className={cs(styles.accordion, { [styles.accordionActive]: activeIndex === index })}>
          <div className={styles.accordionItemWrapper}>
            <MobileAccordion
              className={cs({ [styles.accordionItem]: activeIndex === index })}
              color={activeIndex === index ? activeColor : inactiveColor}
              title={title}
              content={description}
              onClick={() => setActiveIndex(index)}
              onClose={() => setActiveIndex(-1)}
              isOpen={activeIndex === index}
              slides={activeAccordionSlides}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
