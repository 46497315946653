export const routes = {
  public: {
    home: '' as const,
    brokerPage: '/broker' as const,
    contactsPage: '/contacts' as const,
    bestEmployerPage: '/best-employer' as const,
    allNewsPage: '/kompaniya/novosti' as const,
    oneNewsPage: '/kompaniya/novosti/:id' as const,
    realisationPage: '/realizatsiya-proyekta' as const,
    videoPlayer: '/video' as const,
    tendersCooperationPage: '/sotrudnichestvo/tendery-developera' as const,
    selectionLayout: 'selection' as const,
    fineDevelopmentPage: '/fine-development' as const,
    privacyPolicyPage: '/privacy-policy' as const,
    comparisonPage: '/podbor-nedvizhimosti/comparison' as const,
    electorPage: '/podbor-nedvizhimosti' as const,
    roompicker: '/podbor-nedvizhimosti' as const,
    test: '/test' as const,
  },
  admin: {},
};

export type RouteKeys = keyof typeof routes;
