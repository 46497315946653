import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import useSwipeLogic from '@/shared/hooks/useSwipeLogic';
import { SlideType } from '../model/types';
import { SliderButtonsWithCounter } from './slider-buttons-with-counter';
import { SliderSticker } from './slider-sticker';
import { SliderCaption } from './slider-caption';

interface SliderHalfScreenProps {
  slides: SlideType[];
  sticker?: string;
  position?: 'left' | 'right';
}

export const SliderHalfScreen: React.FC<SliderHalfScreenProps> = ({ slides, sticker, position }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isTransitioning = useRef(false);
  const [isPaused, setIsPaused] = useState(false);
  const lastInteractionTime = useRef(Date.now());
  const isInitialLoad = useRef(true);
  const [isVisible, setIsVisible] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    const FATE_OF_VISIBILITY = 0.8;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.intersectionRatio >= FATE_OF_VISIBILITY);
      },
      { threshold: [FATE_OF_VISIBILITY] }
    );
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };
    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const changeSlide = (direction: 'next' | 'prev') => {
    if (isTransitioning.current || slides.length < 2) return;
    isTransitioning.current = true;
    const SWIPE_CLICK_DELAY = 500;
    if (direction === 'next') {
      setCurrentImageIndex(currentImageIndex === slides.length - 1 ? 0 : currentImageIndex + 1);
    }
    if (direction === 'prev') {
      setCurrentImageIndex(currentImageIndex === 0 ? slides.length - 1 : currentImageIndex - 1);
    }
    setTimeout(() => {
      isTransitioning.current = false;
    }, SWIPE_CLICK_DELAY);
  };

  const nextSlideHandler = useCallback(() => changeSlide('next'), [changeSlide]);
  const prevSlideHandler = useCallback(() => changeSlide('prev'), [changeSlide]);

  useEffect(() => {
    const PAUSE_AFTER_ACTION = 10050;
    const AUTO_SWITCH_INTERVAL = 3350;
    const intervalId = setInterval(() => {
      if (isVisible && (isInitialLoad.current || Date.now() - lastInteractionTime.current >= PAUSE_AFTER_ACTION)) {
        setIsPaused(false);
        nextSlideHandler();
      }
    }, AUTO_SWITCH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [slides, nextSlideHandler, isVisible]);

  const getUploadedImageIndexes = (currentIndex: number, totalNumberImages: number) => {
    if (totalNumberImages < 4) return {};
    let prev = currentIndex - 1;
    let next = currentIndex + 1;
    if (prev < 0) prev = totalNumberImages - 1;
    if (next >= totalNumberImages) next = 0;

    return {
      prev: prev,
      current: currentIndex,
      next: next,
    };
  };
  const uploadedImage = getUploadedImageIndexes(currentImageIndex, slides.length);

  const handleChangeSlide = useCallback(() => {
    setIsPaused(true);

    lastInteractionTime.current = Date.now();
    isInitialLoad.current = false;
  }, []);

  const handleNextClick = useCallback(() => {
    if (slides.length < 2) return;
    nextSlideHandler();
    handleChangeSlide();
  }, [slides.length, nextSlideHandler, handleChangeSlide]);

  const handlePrevClick = useCallback(() => {
    if (slides.length < 2) return;
    prevSlideHandler();
    handleChangeSlide();
  }, [slides.length, prevSlideHandler, handleChangeSlide]);

  const { handleMouseDown, handleMouseMove, handleMouseUp, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useSwipeLogic(handlePrevClick, handleNextClick);

  const getRenderIndexes = (slides: SlideType[]) => {
    if (slides.length < 4) {
      const slidesIndexes = slides.map((_, index) => index);
      return slidesIndexes;
    }
    return Object.values(uploadedImage);
  };

  const renderSlides = getRenderIndexes(slides);

  return (
    <div {...stylex.props(styles.slider(position))} ref={sliderRef}>
      {renderSlides.map((index) => (
        <div key={slides[index].id} {...stylex.props(styles.sliderItem(index === currentImageIndex))}>
          <div
            {...stylex.props(styles.imageItem(index === currentImageIndex))}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {slides[index].type === 'image' || slides[index].type === undefined ? (
              <img
                src={slides[index].imgSrc}
                alt={slides[index].alt}
                {...stylex.props(styles.image)}
                draggable={false}
              />
            ) : slides[index].type === 'component' ? (
              <div {...stylex.props(styles.typeComponent)}>{slides[index].component}</div>
            ) : null}
          </div>
          <SliderCaption isActive={index === currentImageIndex} slideCaption={slides[index].caption} />
        </div>
      ))}

      {sticker && <SliderSticker sticker={sticker} />}

      <SliderButtonsWithCounter
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        isPaused={isPaused}
        isVisible={isVisible}
        currentIndex={currentImageIndex}
        slidesLength={slides.length}
      />
    </div>
  );
};
