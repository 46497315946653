import { Button } from '@/shared/ui/components/button';
import { Dropdown } from '@/shared/ui/components/dropdown';
import { IconWrapper } from '@/shared/ui/components/icon-wrapper';
import { IRoompickerProps } from '@/features/roompicker-header/model/types.ts';

import { GridIcon } from '@/shared/ui/icons/grid-icon.tsx';
import { RowsIcon } from '@/shared/ui/icons/rows-icon.tsx';
import { FilterIcon } from '@/shared/ui/icons/filter-icon.tsx';
import { CompareMediumIcon } from '@/shared/ui/icons/compare-medium-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';

import { DEFAULT_DROPDOWN_SORTING_OPTIONS } from '@/pages/roompicker-page/api/mock-data.ts';

const RoompickerHeader = ({
  compareOnClick,
  gridOnClick,
  rowOnClick,
  dropdownOnSelect,
  compareBadgeCount,
  filterBadgeCount,
  isGridRow,
  sortingOptions = DEFAULT_DROPDOWN_SORTING_OPTIONS,
}: IRoompickerProps) => {
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.wrapper)}>
        <Button size="small" variant="secondary" badgeCount={compareBadgeCount} onClick={compareOnClick}>
          <span {...stylex.props(styles.compareBtnContent)}>
            <CompareMediumIcon />
            <span {...stylex.props(styles.compareBtnText)}>Сравнение</span>
          </span>
        </Button>
        <div {...stylex.props(styles.desktopHide)}>
          <Button size="small" variant="secondary" badgeCount={filterBadgeCount} onClick={compareOnClick}>
            <span {...stylex.props(styles.filterBtnContent)}>
              <FilterIcon />
              <span>Фильтр</span>
            </span>
          </Button>
        </div>
        <div {...stylex.props([styles.mobileHide, styles.dropdownContainer])}>
          <Dropdown options={sortingOptions} onSelect={dropdownOnSelect} />
        </div>
      </div>
      <div {...stylex.props(styles.wrapper)}>
        <IconWrapper icon={<GridIcon />} isActive={isGridRow} size="large" form="square" onClick={gridOnClick} />
        <IconWrapper icon={<RowsIcon />} isActive={!isGridRow} size="large" form="square" onClick={rowOnClick} />
      </div>
    </div>
  );
};

export default RoompickerHeader;
