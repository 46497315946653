import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';

export const styles = stylex.create({
  container: {
    display: 'flex',
    border: 1,
    margin: '0 auto',
    marginTop: '4.17rem',
    paddingLeft: '3.125rem',
    paddingRight: '11.041667rem',
    paddingTop: '6.25rem',
    fontFamily: fonts.din2014Light,
    '@media (max-width: 767px)': {
      paddingTop: '21.33rem',
      flexDirection: 'column',
      paddingLeft: '4.266667rem',
      paddingRight: '4.266667rem',
      marginTop: 0,
    },
  },
  backBtnContainer: {
    minWidth: '7.91667rem',
    maxWidth: '7.91667rem',
    '@media (max-width: 767px)': {
      marginVertical: '14.933333rem',
      minWidth: '28.8rem',
      maxWidth: '28.8rem',
      minHeight: '12.8rem',
      maxHeight: '12.8rem',
    },
  },
  backBtnWrapper: {
    minWidth: '5.625rem',
    maxWidth: '5.625rem',
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
    '@media (max-width: 767px)': {
      minWidth: '28.8rem',
      maxWidth: '28.8rem',
      minHeight: '12.8rem',
      maxHeight: '12.8rem',
      paddingLeft: '1rem',
    },
  },
  allNewsBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginVertical: '6.25rem',
    paddingHorizontal: '11.041667rem',
    '@media (max-width: 767px)': {
      marginVertical: '14.93333rem',
    },
  },
});
