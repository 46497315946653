export const YouTubeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38204 7.25812C10.4263 7.97185 11.5057 8.75518 12.5422 9.50908C13.5891 10.2714 14.6513 11.0047 15.6678 11.7423C13.8454 12.917 12.0154 14.0498 10.1682 15.2077C9.90888 15.3693 9.66237 15.566 9.38204 15.6898V7.25812ZM1.00617 8.47208V15.5936C1.00617 16.4314 0.964396 17.2232 1.16238 17.8095C1.35945 18.3916 1.71545 18.8601 2.13548 19.1999C2.57094 19.5508 3.07634 19.8069 3.82013 19.868C4.52032 19.9246 5.3404 19.886 6.14731 19.886H17.8699C18.6582 19.886 19.4565 19.9379 20.2148 19.868C21.5562 19.7444 22.4798 18.89 22.8371 17.7754C23.031 17.1712 22.9934 16.3795 22.9934 15.5591V8.43667C22.9934 7.59246 23.0492 6.86503 22.8371 6.22038C22.6482 5.64607 22.3181 5.17385 21.8827 4.81382C21.4558 4.46114 20.9618 4.19031 20.2148 4.12778C19.4778 4.06708 18.6541 4.10984 17.8876 4.10984H6.16456C5.38989 4.10984 4.49626 4.06846 3.78562 4.12778C2.46605 4.23813 1.48069 5.13109 1.14558 6.23831C0.955315 6.86503 1.00617 7.66833 1.00617 8.47208Z"
      />
    </svg>
  );
};
