import plan from '@shared/ui/assets/plan.png';
export interface IHeaders extends Array<string> {}
export interface IApartment {
  id: number;
  project: string;
  bedrooms: number;
  square: string;
  size: string;
  building: string;
  floor: string;
  number: string;
  priceForSquare: string;
  title: string;
  price: string;
  plan: string;
}

export const headers: IHeaders = [
  'Проект',
  'Кол-во спален',
  'Площадь',
  'Размер',
  'Корпус',
  'Этаж',
  'Номер',
  'Цена за M²',
];

export const apartments = [
  {
    id: 1,
    project: 'Малая Ордынка 19',
    bedrooms: 3,
    square: '52',
    size: 'M',
    building: '1',
    floor: '32',
    number: '777',
    priceForSquare: '555 000',
    title: 'Квартира № 987',
    price: '23 670 000',
    plan: plan,
  },
  {
    id: 2,
    project: 'Квартал Jazz',
    bedrooms: 3,
    square: '52',
    size: 'M',
    building: '1',
    floor: '32',
    number: '777',
    priceForSquare: '555 000',
    title: 'Апартаменты № 77',
    price: '21 120 000',
    plan: plan,
  },
  {
    id: 3,
    project: 'Достижение',
    bedrooms: 3,
    square: '52',
    size: 'M',
    building: '1',
    floor: '32',
    number: '777',
    priceForSquare: '555 000',
    title: 'Пентхаус № 257',
    price: '230 367 000',
    plan: plan,
  },
  {
    id: 4,
    project: 'WestGarden',
    bedrooms: 3,
    square: '52',
    size: 'M',
    building: '1',
    floor: '32',
    number: '777',
    priceForSquare: '555 000',
    title: 'Апартаменты № 24',
    price: '542 670 000',
    plan: plan,
  },
  {
    id: 5,
    project: 'Лаврушинский',
    bedrooms: 4,
    square: '152',
    size: 'XXL',
    building: '3',
    floor: '4',
    number: '94',
    priceForSquare: '777 000',
    title: 'Квартира № 87',
    price: '177 232 000',
    plan: plan,
  },
  {
    id: 6,
    project: 'West Garden',
    bedrooms: 1,
    square: '78',
    size: 'XL',
    building: '2',
    floor: '5',
    number: '25',
    priceForSquare: '666 000',
    title: 'Пентхаус № 2',
    price: '1 032 808 000',
    plan: plan,
  },
  {
    id: 7,
    project: 'West Garden',
    bedrooms: 1,
    square: '78',
    size: 'XL',
    building: '2',
    floor: '5',
    number: '25',
    priceForSquare: '666 000',
    title: 'Пентхаус № 44',
    price: '1 000 553 000',
    plan: plan,
  },
  {
    id: 8,
    project: 'West Garden',
    bedrooms: 1,
    square: '78',
    size: 'XL',
    building: '2',
    floor: '5',
    number: '25',
    priceForSquare: '666 000',
    title: 'Пентхаус № 2',
    price: '1 432 432 000',
    plan: plan,
  },
  {
    id: 9,
    project: 'West Garden',
    bedrooms: 1,
    square: '78',
    size: 'XL',
    building: '2',
    floor: '5',
    number: '25',
    priceForSquare: '666 000',
    title: 'Пентхаус № 22',
    price: '1 500 443 000',
    plan: plan,
  },
  {
    id: 10,
    project: 'West Garden',
    bedrooms: 1,
    square: '78',
    size: 'XL',
    building: '2',
    floor: '5',
    number: '25',
    priceForSquare: '666 000',
    title: 'Пентхаус № 23',
    price: '1 232 000 000',
    plan: plan,
  },
];
