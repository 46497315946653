import fountain from '@shared/ui/assets/fine-development-slider-default/fountain.png';
import reception from '@shared/ui/assets/fine-development-slider-default/reception.png';
import clock from '@shared/ui/assets/fine-development-slider-default/clock.png';
import glass from '@shared/ui/assets/fine-development-slider-default/glass.png';
import enter from '@shared/ui/assets/fine-development-slider-default/enter.png';
import { SliderPhotoType } from './types';

export const mockFhotos: SliderPhotoType[] = [
  {
    id: 1,
    imgSrc: fountain,
    alt: `${fountain}'s photo`,
    caption: `Благоустройство территории «Малой Ордынки 19»`,
  },
  {
    id: 2,
    imgSrc: reception,
    alt: `${reception}'s photo`,
    caption: `Лобби «Малой Ордынки 19»`,
  },
  {
    id: 3,
    imgSrc: clock,
    alt: `${clock}'s photo`,
    caption: `Лобби дома «Малая Ордынка 19»`,
  },
  {
    id: 4,
    imgSrc: enter,
    alt: `${enter}'s photo`,
    caption: `Входная группа дома «Малая Ордынка 19»`,
  },
  {
    id: 5,
    imgSrc: glass,
    alt: `${glass}'s photo`,
    caption: `Фасад «Малой Ордынки 19»`,
  },
];
