import React, { useEffect } from 'react';
import { OnImageLoadCallback } from '@/features/before-after-slider/ui/before-after-one-slide/model/types.ts';

export function useInit(
  updateContainerWidth: () => void,
  onMouseUpHandler: () => void,
  firstImageRef: React.RefObject<HTMLImageElement>,
  onImageLoad: OnImageLoadCallback
) {
  useEffect(() => {
    updateContainerWidth();
    // With ssr the first image may already be loaded. The second image only appears on the client.
    if (firstImageRef.current && firstImageRef.current.complete) {
      onImageLoad(0);
    }

    document.addEventListener('click', onMouseUpHandler);
    return () => {
      document.removeEventListener('click', onMouseUpHandler);
    };
  }, []);
}
