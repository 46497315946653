export const HhIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5229 17.5229 22 12 22C6.47708 22 2 17.5229 2 12C2 6.47708 6.47708 2 12 2C17.5229 2 22 6.47708 22 12ZM7.68761 10.8887V7.48706H6.07886V16.3008H7.68594L7.68761 13.195C7.68594 12.6737 7.73511 12.28 7.83344 12.0133C7.93011 11.7471 8.08261 11.5479 8.29511 11.4146C8.50302 11.2821 8.74219 11.2154 9.00886 11.2154C9.24177 11.2154 9.43469 11.2671 9.58969 11.3716C9.74138 11.47 9.85522 11.6169 9.91261 11.7883C9.97386 11.9633 10.003 12.375 10.003 13.025V16.3008H11.6122V12.6637C11.6122 12.1071 11.5847 11.6966 11.5297 11.4329C11.4743 11.1679 11.3718 10.9275 11.2201 10.7079C11.0693 10.4904 10.8459 10.3112 10.5472 10.1716C10.2493 10.0312 9.91719 9.96039 9.54636 9.96039C8.82636 9.96039 8.20552 10.2712 7.68761 10.8887ZM14.0239 10.8887V7.48706H12.4168V16.3008H14.0239V13.195C14.0239 12.6737 14.073 12.28 14.1697 12.0133C14.2676 11.7471 14.4209 11.5479 14.6305 11.4146C14.8389 11.2821 15.078 11.2154 15.3443 11.2154C15.5772 11.2154 15.7718 11.2671 15.9251 11.3716C16.0773 11.4697 16.1915 11.6166 16.2489 11.7883C16.3101 11.9633 16.3405 12.375 16.3405 13.025V16.3008H17.948V12.6637C17.948 12.1071 17.9205 11.6966 17.8651 11.4329C17.8122 11.1727 17.7069 10.926 17.5555 10.7079C17.4055 10.4904 17.1822 10.3112 16.8847 10.1716C16.5864 10.0312 16.2518 9.96039 15.8822 9.96039C15.1626 9.96039 14.5422 10.2712 14.0239 10.8887Z"
      />
    </svg>
  );
};
