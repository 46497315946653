import { IApartment, roompickerSortKeys } from '@/pages/roompicker-page/model/types.ts';

export const sortCallbackHandler = (sortKey: roompickerSortKeys = 'area_desc') => {
  switch (sortKey) {
    case 'price_asc': {
      return (a: IApartment, b: IApartment) => a.price - b.price;
    }
    case 'price_desc': {
      return (a: IApartment, b: IApartment) => b.price - a.price;
    }
    case 'area_asc': {
      return (a: IApartment, b: IApartment) => a.area - b.area;
    }
    case 'area_desc': {
      return (a: IApartment, b: IApartment) => b.area - a.area;
    }
    case 'bedrooms_asc': {
      return (a: IApartment, b: IApartment) => a.bedroomCount - b.bedroomCount;
    }
    case 'bedrooms_desc': {
      return (a: IApartment, b: IApartment) => b.bedroomCount - a.bedroomCount;
    }
    case 'bedroom_area_asc': {
      return (a: IApartment, b: IApartment) => a.id - b.id;
    }
    case 'bedroom_area_desc': {
      return (a: IApartment, b: IApartment) => b.id - a.id;
    }
    default: {
      return;
    }
  }
};
