import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  mainContainer: {
    width: '100%',
    padding: '0rem 11.04167rem',

    '@media (max-width: 767px)': {
      padding: '0rem',
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '43.125rem',
    position: 'sticky',
    top: '0',

    '@media (max-width: 767px)': {
      height: '135.46667rem',
      overflow: 'hidden',
      position: 'relative',
      top: 'auto',
    },
  },
  upperCircleWithText: {
    position: 'absolute',
    width: '27.65625rem',
    height: '27.65625rem',

    '@media (max-width: 767px)': {
      width: '73.65733rem',
      height: '73.65733rem',
      top: '10%',
    },
  },

  upperCircle: {
    width: '27.65625rem',
    height: '27.65625rem',
    flexShrink: '0',
    color: '#0A0A0A',

    '@media (max-width: 767px)': {
      width: '73.65733rem',
      height: '73.65733rem',
    },
  },
  rightCircleWithText: {
    position: 'absolute',
    width: '27.65625rem',
    height: '27.65625rem',

    '@media (max-width: 767px)': {
      width: '73.65733rem',
      height: '73.65733rem',
      top: '20%',
    },
  },
  rightCircle: {
    width: '27.65625rem',
    height: '27.65625rem',
    flexShrink: '0',
    color: '#0A0A0A',

    '@media (max-width: 767px)': {
      width: '73.65733rem',
      height: '73.65733rem',
    },
  },
  leftCircleWithText: {
    position: 'absolute',
    width: '27.65625rem',
    height: '27.65625rem',

    '@media (max-width: 767px)': {
      width: '73.65733rem',
      height: '73.65733rem',
      top: '20%',
    },
  },
  leftCircle: {
    width: '27.65625rem',
    height: '27.65625rem',
    flexShrink: '0',
    color: '#0A0A0A',

    '@media (max-width: 767px)': {
      width: '73.65733rem',
      height: '73.65733rem',
    },
  },
  valuesText: {
    opacity: '0',
    position: 'absolute',
    top: '30%',
    right: '50%',
    transform: 'translate(50%, 0%)',
    color: colors.primaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
      transform: 'translate(50%, 50%)',
    },
  },
  styleText: {
    opacity: '0',
    position: 'absolute',
    top: '70%',
    left: '20%',
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.83333rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',
    transform: 'translate(-25%, 50%)',

    '@media (max-width: 767px)': {
      fontSize: '3.73333rem',
      lineHeight: '4.26667rem',
      left: '20%',
      top: '67%',
    },
  },
  comfortText: {
    opacity: '0',
    position: 'absolute',
    top: '70%',
    right: '20%',
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.83333rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',
    transform: 'translateY(50%)',

    '@media (max-width: 767px)': {
      fontSize: '3.73333rem',
      lineHeight: '4.26667rem',
      right: '12%',
      top: '67%',
    },
  },
  uniquenessText: {
    opacity: '0',
    position: 'absolute',
    top: '85%',
    right: '50%',
    transform: 'translate(50%, 50%)',
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.72917rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.04167rem',

    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26667rem',
      transform: 'translate(50%, 50%)',
    },
  },
  impressionText: {
    opacity: '0',
    position: 'absolute',
    top: '110%',
    right: '50%',
    transform: 'translate(50%, 50%)',
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.83333rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '3.73333rem',
      lineHeight: '4.26667rem',
      top: '105%',
    },
  },
  functionText: {
    opacity: '1',
    position: 'absolute',
    top: '45%',
    right: '40%',
    transform: 'translateX(50%)',
    color: colors.primaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
      transform: 'translate(50%, 50%)',
      top: '48%',
    },
  },
  aestheticsText: {
    opacity: '1',
    position: 'absolute',
    top: '45%',
    right: '60%',
    transform: 'translateX(75%)',
    color: colors.primaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
      transform: 'translate(50%, 50%)',
      top: '48%',
    },
  },
  mainCircle: {
    width: '27.60417rem',
    height: '27.60417rem',
    flexShrink: '0',
    color: colors.primaryIcon,

    '@media (max-width: 767px)': {
      width: '73.6rem',
      height: '73.6rem',
    },
  },
  mainCircleBlock: {
    position: 'absolute',
    width: '27.60417rem',
    height: '27.60417rem',
    top: '25%',

    '@media (max-width: 767px)': {
      width: '73.6rem',
      height: '73.6rem',
      top: '40%',
    },
  },
  textContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media (max-width: 767px)': {
      justifyContent: 'center',
    },
  },
  textBlock: {
    display: 'flex',
    width: '38.33333rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.08333rem',
    padding: '0rem 0rem 9rem 0rem',

    '@media (max-width: 767px)': {
      width: '100%',
      gap: '6.4rem',
      alignSelf: 'stretch',
      padding: '2.93333rem 4rem 0rem 4.26667rem',
    },
  },
  textBlockTitle: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.875rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '2.08333rem',

    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      fontSize: '6.4rem',
      lineHeight: '7.46667rem',
    },
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.83333rem',

    '@media (max-width: 767px)': {
      gap: '4.26667rem',
    },
  },
  textBlockDescription: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
});
