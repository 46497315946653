export const Favorites = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
      <path
        d="M16.9273 3.32241C18.0279 3.45014 18.834 4.399 18.834 5.50699V21L12.334 17.25L5.83398 21V5.50699C5.83398 4.399 6.64007 3.45014 7.74066 3.32241C9.57555 3.10947 10.442 3 12.334 3C14.226 3 15.0924 3.10947 16.9273 3.32241Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
