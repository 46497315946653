import React from 'react';

export enum MODE {
  MOVE = 'move',
  DEFAULT = 'default',
}

export interface Image {
  imageUrl: string;
  alt?: string;
}

export type OnSliderLoadCallback = () => void;

export interface BeforeAfterOneSlideProps {
  afterImage: Image;
  beforeImage: Image;
  currentPercentPosition?: number;
  className?: string;
  withResizeFeel?: boolean;
  onReady?: OnSliderLoadCallback;
  onVisible?: () => void;
  onChangePercentPosition?: (newPosition: number) => void;
  feelsOnlyTheDelimiter?: boolean;
  delimiterIconStyles?: React.CSSProperties;
  delimiterColor?: string;
  onChangeMode?: (newMode: MODE) => void;
  isHidden?: boolean;
}

export type OnImageLoadCallback = (idx: 0 | 1) => void;
