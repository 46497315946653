import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import { typography } from '@/shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    width: '100%',
    padding: '6.25rem 0',
    borderBottom: '1px solid black',

    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '14.934rem 0',
      gap: '0',
      alignSelf: 'stretch',
    },
  },

  headerWithDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '24px',
    flex: '1 0 0',
    width: '50%',
    paddingRight: '128px',
    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      gap: '16px',
      width: 'auto',
      paddingRight: '0px',
      marginBottom: '16px',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      paddingRight: '84px',
    },
  },
  header: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '3.125rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '133.333%',
    width: '30.417rem',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 767px)': {
      fontSize: '8rem',
      lineHeight: '133.3%',
      alignSelf: 'stretch',
      width: '91.3rem',
      whiteSpace: 'pre-wrap',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      fontSize: '39px',
      lineHeight: '47px',
    },
  },
  description: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: typography['fontSizeBody-2'],
    fontStyle: 'normal',
    fontWeight: '300',
    width: '30.417rem',
    lineHeight: typography['lineHeightBody-2'],
    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      width: '91.3rem',
      fontSize: typography['fontSizeBody-4'],
      lineHeight: typography['lineHeightBody-4'],
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      fontSize: typography['fontSizeCaption-2'],
      lineHeight: typography['lineHeightCaption-2'],
    },
  },
  blockList: {
    width: '50%',
    flex: '1 0 0',
    '@media (max-width: 767px)': {
      width: 'auto',
      paddingRight: '0px',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      paddingRight: '52px',
    },
  },
  point: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: typography['fontSizeBody-2'],
    fontStyle: 'normal',
    width: '30.417rem',
    fontWeight: '300',
    lineHeight: typography['lineHeightBody-2'],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    '@media (max-width: 767px)': {
      fontSize: typography['fontSizeBody-4'],
      lineHeight: typography['lineHeightBody-4'],
      alignSelf: 'stretch',
      width: '100%',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      fontSize: typography['fontSizeCaption-2'],
      lineHeight: typography['lineHeightCaption-2'],
    },
  },
  number: {
    minWidth: '1.2rem',
    textAlign: 'right',
    display: 'block',
    marginRight:'0.417rem',
    '@media (max-width: 767px)': {
      minWidth: '5rem',
      marginRight:'2.133rem',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    width: '38.334rem',
    listStyle: 'none',
    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      width: '100%',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      gap: '6px',
    },
  },
  bottomLine: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.primaryText,
    padding: '0 11.7rem',
  },
});
