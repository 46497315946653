import { useNavigate } from 'react-router-dom';
import { tabMenuItems } from '../model/mock-items';
import styles from './tab-menu.module.css';
import { useEffect, useState } from 'react';

interface TabMenuPrpos {
  setIsMenuVisible: (item: boolean) => void;
  isMenuVisible: boolean;
}

export const TabMenu = ({ setIsMenuVisible, isMenuVisible }: TabMenuPrpos) => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<string | null>(null); // Состояние для отслеживания активного элемента

  useEffect(() => {
    if (!isMenuVisible) {
      setActiveItem(null); // Сбрасываем активную иконку, когда меню сворачивается
    }
  }, [isMenuVisible]);
  const handleClick = (title: string, url?: string) => {
    setActiveItem(title);
    if (title === 'Меню') {
      setIsMenuVisible(!isMenuVisible);
    }
    if (title === 'Выбор недвижимости' || title === 'Избранное') {
      navigate(url!);
    }
  };
  return (
    <div className={`${styles.tabMenuContainer} ${isMenuVisible ? styles.clicked : ''}`}>
      {tabMenuItems.map((item, id) => {
        const IconComponent = item.btnIcon;
        const isActive = activeItem === item.title; // Проверяем, является ли элемент активным
        return (
          <div className={styles.menuBtnWrap} key={id} onClick={() => handleClick(item.title, item.url)}>
            <div className={`${styles.iconWrap} ${isActive ? styles.activeIcon : ''}`}>
              <IconComponent />
            </div>
            <p className={` ${isActive ? styles.activeText : styles.text}`}>{item.title}</p>
          </div>
        );
      })}
    </div>
  );
};
