import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  buttonsContainer: {
    width: '92.2222rem', // 1328px / 14.4
    gap: '1.1111rem', // 16px / 14.4
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'transform 0.6s ease, opacity 0.6s ease',
    '@media (max-width: 767px)': {
      width: '91.1111rem',
      gap: '2.2222rem', // 8px / 3.6
    },
  },
});
