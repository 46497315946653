import { useState, useEffect } from 'react';
import styles from './navbar.module.css';

export interface INavbarProps {
  navSections: Array<string>;
  centeredSections?: boolean;
}

export const Navbar = ({ navSections, centeredSections = false }: INavbarProps) => {
  const [isSticky, setSticky] = useState(false);
  const [activeSection, setActiveSection] = useState('Ценности компании');
  const [stickyStart, setStickyStart] = useState(0);

  useEffect(() => {
    /* Функция для установки порога появления stickyNavbar в зависимости от размеров экрана */
    const updateStickyStart = () => {
      /* в % от высоты экрана */
      const start = window.innerHeight * 0.47;
      setStickyStart(start);
    };
    /*  Установить начальное значение при монтировании */
    updateStickyStart();
    /*  Пересчитать порог появления stickyNavbar при изменении размера экрана */
    window.addEventListener('resize', updateStickyStart);

    return () => {
      window.removeEventListener('resize', updateStickyStart);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > stickyStart) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyStart]);

  const handleNavClick = (section: string) => {
    setActiveSection(section);
    console.log(section);

    const element = document.getElementById(section);
    if (element) {
      const yOffset = -250;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      console.log(y);

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <nav
      className={`${styles.navbar} ${isSticky ? styles.stickyNavbar : styles.hiddenSticky} ${centeredSections ? styles.navbarCenteredSections : ''}`}
    >
      {navSections.map((section) => (
        <div
          key={section}
          className={`${
            !isSticky ? styles.navItem : activeSection === section ? styles.activeNavItem : styles.stickyNavItem
          } `}
          onClick={() => handleNavClick(section)}
        >
          {section}
        </div>
      ))}
    </nav>
  );
};
