import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import { APARTMENT_CARD_CONSTS } from '@/shared/lib/consts';

export interface IApartmentCardInfoBlockProps {
  apartment: IApartment;
}

export const ApartmentCardInfoBlock = ({ apartment }: IApartmentCardInfoBlockProps) => {
  return (
    <div {...stylex.props(styles.infoBlock)}>
      <div {...stylex.props(styles.price)}>
        <span {...stylex.props(styles.amount)}> {apartment.price}</span>
        <span {...stylex.props(styles.currency)}>{APARTMENT_CARD_CONSTS.RUB}</span>
      </div>
      <div {...stylex.props(styles.apartment)}>
        <span {...stylex.props(styles.apartmentText)}>{apartment.title}</span>
      </div>
    </div>
  );
};
