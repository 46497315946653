import { flattenMenu } from '../lib/flattenMenuItems';
import { DesktopMenu, MenuItemType } from './desktop-menu/desktop-menu';
import { MobileMenu } from './mobile-menu/mobile-menu';
import { menuContent } from '@/shared/mockData/menu-mock-data';

export const flattenedMenuContent: MenuItemType[] = flattenMenu(menuContent);

interface MenuProps {
  onClose: () => void;
  isSelection?: boolean;
}

export const Menu = ({ onClose, isSelection }: MenuProps) => {
  return (
    <>
      <DesktopMenu menuContent={flattenedMenuContent} onClose={onClose} />
      <MobileMenu menuContent={menuContent} onClose={onClose} isSelection={isSelection} />
    </>
  );
};
