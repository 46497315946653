interface IChevronRightIcon {
  className?: string;
}
export const ChevronLeftIcon = ({ className }: IChevronRightIcon) => {
  return (
    <svg viewBox="0 0 10 18" width="100%" height="100%" fill="none" className={className}>
      <path stroke="#fff" d="M9 17 1 9l8-8" />
    </svg>
  );
};
