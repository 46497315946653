import { SliderPhotoType } from '@/features/slider-default/model/types';
import playgroundOrdinka19 from '@shared/ui/assets/slider-full-screen-fine-development/detskay-ordinka.png';
import playgroundDostizhenie from '@shared/ui/assets/slider-full-screen-fine-development/detskaya-dostizhenie.png';
import playgroundJazzSpiral from '@shared/ui/assets/slider-full-screen-fine-development/detskaya-jazz-spiral.png';
import playgroundJazz from '@shared/ui/assets/slider-full-screen-fine-development/detskaya-jazz.png';
import playgroundOrdinka19Rungs from '@shared/ui/assets/slider-full-screen-fine-development/detskaya-ordinka-stupeni.png';
import playgroundOrdinka19Child from '@shared/ui/assets/slider-full-screen-fine-development/ordinka-rebenok-v-trybe.png';
import fitnessRenome from '@shared/ui/assets/slider-full-screen-fine-development/tren-renome.png';

export const sliderFullScreenImages: SliderPhotoType[] = [
  {
    id: 1,
    imgSrc: playgroundJazz,
    alt: `${playgroundJazz}'s photo`,
    caption: `Детская площадка в «JAZZ»`,
  },
  {
    id: 2,
    imgSrc: playgroundOrdinka19,
    alt: `${playgroundOrdinka19}'s photo`,
    caption: `Детская площадка в «Малой Ордынке 19»`,
  },
  {
    id: 3,
    imgSrc: playgroundOrdinka19Rungs,
    alt: `${playgroundOrdinka19Rungs}'s photo`,
    caption: `Детская площадка в «Малой Ордынке 19»`,
  },
  {
    id: 4,
    imgSrc: playgroundJazzSpiral,
    alt: `${playgroundJazzSpiral}'s photo`,
    caption: `Детская площадка в «JAZZ»`,
  },
  {
    id: 5,
    imgSrc: playgroundOrdinka19Child,
    alt: `${playgroundOrdinka19Child}'s photo`,
    caption: `Детская площадка в «Малой Ордынке 19»`,
  },
  {
    id: 6,
    imgSrc: playgroundDostizhenie,
    alt: `${playgroundDostizhenie}'s photo`,
    caption: `Детская площадка в «Достижении»`,
  },
  {
    id: 7,
    imgSrc: fitnessRenome,
    alt: `${fitnessRenome}'s photo`,
    caption: `Тренажерный зал в «Реномэ»`,
  },
];
