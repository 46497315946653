import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  employerTitle: {
    fontFamily: fonts.din2014Light,
    fontSize: '3.125rem',
    fontWeight: '300',
    lineHeight: '133%',
    marginTop: '6.25rem',
    marginBottom: '5rem',
    color: colors.primaryText,
    '@media (max-width: 768px)': {
      fontSize: '8rem',
      marginTop: '14.936rem',
      marginBottom: '14.936rem',
    },
  },
});
