import React from 'react';
import { SectionImgProps } from '../model/types';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import useIsMobile from '@/shared/hooks/useIsMobile.tsx';

const SectionImg: React.FC<SectionImgProps> = ({ imgSrc }) => {
  const { isMobile } = useIsMobile();

  const getImageSrc = () => {
    if (typeof imgSrc === 'string') {
      return imgSrc;
    }
    return imgSrc ? (isMobile ? imgSrc.mobile : imgSrc.desktop) : '';
  };

  return (
    <div {...stylex.props(styles.containerImg)}>
      {imgSrc && <img src={getImageSrc()} {...stylex.props(styles.img)} />}
    </div>
  );
};

export default SectionImg;
