import { AnimatedCirclesTextBlockType, wordsInCirclesType } from './types';

export const animatedCirclesTextBlock: AnimatedCirclesTextBlockType = {
  title: 'РОСКОШНОЕ СОЧЕТАНИЕ ЭСТЕТИКИ\u00A0И\u00A0КОМФОРТА',
  firstDescription: `В каждом проекте мы прежде всего определяем, для кого именно мы его создаем. И\u00A0смотрим на все
проектируемые характеристики через призму ценностей людей, которые будут здесь жить. Эти ценности
определяют эстетику и функционал дома на всех уровнях и во всех деталях.`,
  secondDescription: `Так формируется гармоничное сочетание стиля и комфорта, уникальное для каждого нашего дома и наполняющее
жизнь в нем впечатляющим опытом. Это опыт, который не только обладает непосредственной ценностью, но и
служит предметом гордости для наших жителей.`,
};

export const wordsInCircles: wordsInCirclesType = {
  topCircleText: 'ЦЕННОСТИ',
  leftCircleText: 'ЭСТЕТИКА',
  rightCircleText: 'ФУНКЦИЯ',
  mainCircleText: 'УНИКАЛЬНОСТЬ',
  topAndLeftCircleText: 'СТИЛЬ',
  topAndRightCircleText: 'КОМФОРТ',
  leftAndRightCircleText: 'ВПЕЧАТЛЕНИЕ',
};
