import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { anatomy } from '@/shared/tokens/anatomy.stylex.ts';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';

export const styles = stylex.create({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0',
    transition: 'background 0.3s ease, color 0.3s ease',
    border: 0,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    backgroundColor: colors.brandGhostBackground,
    color: colors.accentBackground,
    '@media (hover: hover)': {
      ':hover': {
        color: palette.greyWhite,
        backgroundColor: colors.accentBackground,
      },
    },
  },
  btnDefaultStyleReset: {
    fontFamily: fonts.din2014Regular,
    textIndent: 0,
    textDecoration: 0,
    textAlign: 'left',
    listStyle: 'none',
    borderSpacing: 0,
    margin: 0,
    border: 0,
  },
  iconActive: {
    backgroundColor: colors.accentBackground,
    color: palette.greyWhite,
  },
  medium: {
    width: anatomy['size-28'],
    height: anatomy['size-28'],
    minWidth: anatomy['size-28'],
    minHeight: anatomy['size-28'],
  },
  large: {
    width: anatomy['size-36'],
    height: anatomy['size-36'],
  },
  round: {
    borderRadius: anatomy['borderRadiusRound'],
  },
  square: {
    borderRadius: anatomy['size-8'],
  },
});
