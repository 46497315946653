import styles from './text-animation.module.css';
import { SectionWithTextAnimationProps } from '../model/types';
import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SectionWithTextAnimation: React.FC<SectionWithTextAnimationProps> = ({ textFirst, textSecond, header }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    if (wrapperRef.current && headerRef.current) {
      // Создаем GSAP timeline для последовательной анимации
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: 'top 80%',
          end: 'top 20%',
          scrub: 5,
          once: true,
        },
      });

      // Анимация движения снизу вверх
      tl.fromTo(
        headerRef.current,
        { y: '100%' },
        { y: '0%', duration: 0.5, ease: 'power3.out' },
        0 // Начинаем эту анимацию в начале timeline
      );

      // Анимация проявления текста сверху вниз
      tl.fromTo(
        headerRef.current,
        {
          clipPath: 'inset(0% 0% 100% 0%)', // Начинаем с полностью скрытого текста
          opacity: 1, // Устанавливаем opacity в 1, так как будем использовать clipPath для эффекта появления
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)', // Заканчиваем полностью видимым текстом
          duration: 0.5,
          ease: 'power3.inOut',
        },
        0 // Начинаем эту анимацию одновременно с предыдущей
      );
    }
  }, []);

  return (
    <div ref={wrapperRef} className={styles.sectionContainer}>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <div>{textFirst}</div>
          <div>{textSecond}</div>
        </div>
      </div>

      <div className={styles.headerContainer}>
        <h2 ref={headerRef} className={styles.h2}>
          {header}
        </h2>
      </div>
    </div>
  );
};

export default SectionWithTextAnimation;
