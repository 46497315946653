import { useRef, useState } from 'react';
import useClickOutside from '@/shared/hooks/useClickOutside/useClickOutside.ts';
import { ISortOption } from '@/shared/model/types.ts';
import { IDropdownProps } from '@/shared/ui/components/dropdown/model/types.ts';

import { CounterArrowsIcon } from '@/shared/ui/icons/counter-arrows-icon.tsx';
import { ArrowDownIcon } from '@/shared/ui/icons/arrow-down-icon.tsx';
import { CheckIcon } from '@/shared/ui/icons/check-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';

const Dropdown = ({ options, onSelect }: IDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<ISortOption | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const clickOutsideHandler = () => setIsOpen(false);
  useClickOutside(ref, clickOutsideHandler);

  const selectHandler = (option: ISortOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect?.(option);
  };

  return (
    <div {...stylex.props(styles.dropdown)} ref={ref}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        {...stylex.props([styles.dropdownToggle, isOpen ? styles.dropdownToggleOpen : null])}
      >
        <span {...stylex.props(styles.dropdownIcon)}>
          <CounterArrowsIcon />
        </span>
        <span {...stylex.props([styles.dropdownText, !selectedOption && styles.dropdownTextDefault])}>
          {selectedOption ? selectedOption.title : 'Сортировка'}
        </span>
        <span {...stylex.props([styles.dropdownIcon, isOpen && styles.dropDownOpenIcon])}>
          <ArrowDownIcon />
        </span>
      </button>
      <ul {...stylex.props([styles.dropdownMenu, !isOpen && styles.menuHidden])}>
        {options.map((option) => (
          <li key={option.id} onClick={() => isOpen && selectHandler(option)} {...stylex.props([styles.dropdownItem])}>
            {option.title}{' '}
            {selectedOption && selectedOption.title === option.title ? (
              <span {...stylex.props([styles.dropdownItemIcon])}>
                <CheckIcon />
              </span>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
