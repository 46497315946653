import { MouseEvent, useState } from 'react';
import styles from './float-btn-reversed.module.css';

export interface FloatBtnProps {
  redirectUrl: string;
  btnName: string;
}

export const FloatBtnReversed = ({ redirectUrl, btnName }: FloatBtnProps) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: MouseEvent<HTMLAnchorElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();

    setPosition({
      x: -e.clientX + rect.left + rect.width / 4,
      y: -e.clientY + rect.bottom - rect.height / 2,
    });
  };

  return (
    <a href={redirectUrl} className={styles.vacanciesLink} onMouseMove={handleMouseMove}>
      <span className={styles.arrowWrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M16.2353 20.4707L19.7647 24.0001M19.7647 24.0001L16.2353 27.5295M19.7647 24.0001H5.64706" />
        </svg>
      </span>
      <div
        className={styles.btnCursor}
        style={
          {
            '--position-x': `${position.x}px`,
            '--position-y': `${position.y}px`,
          } as React.CSSProperties
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 68">
          <path d="M13 8.49544C18.8089 3.81187 26.2461 1 34.3529 1C52.9357 1 68 15.7746 68 34C68 52.2254 52.9357 67 34.3529 67C26.2461 67 18.8089 64.1881 13 59.5046"></path>
          <path
            className={styles.btnCursorPart}
            d="M15 61C10.3333 57.9434 1 48.2642 1 34C1 19.7358 10.3333 10.0566 15 7"
          ></path>
        </svg>
      </div>
      <span>{btnName}</span>
    </a>
  );
};
