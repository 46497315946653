import { currencySign, getNumberWithSpaces, getWordEnding } from '@/shared/utils/roompicker.ts';
import { ILowerSectionProps } from '@/features/roompicker-grid-card/model/types.ts';
import { FormattedPriceWithCurrency } from '@/enteties/formatted-price-with-currency';

import * as stylex from '@stylexjs/stylex';
import { styles } from './stylex.ts';

import { BEDROOM_WORD_ENDINGS } from '@/shared/constants/words.ts';
import useIsMobile from '@/shared/hooks/useIsMobile.tsx';

export const LowerSection = ({
  price,
  value,
  size,
  area,
  currencyCode,
  bedroomCount,
  onMainClick,
}: ILowerSectionProps) => {
  const { isMobile } = useIsMobile();

  return (
    <button {...stylex.props([styles.lowerClickableArea, styles.btnDefaultStyleReset])} onClick={onMainClick}>
      <span {...stylex.props(styles.preLowerInfo)}>
        <span {...stylex.props(styles.preLowerInfoPrice)}>
          {isMobile ? (
            <span>
              {getNumberWithSpaces(price)} <span {...stylex.props(styles.grayText)}>{currencySign(currencyCode)}</span>
            </span>
          ) : (
            <FormattedPriceWithCurrency price={price} currencyCode={currencyCode} textColor={styles.grayText.color} />
          )}
        </span>
        <span {...stylex.props(styles.preLowerInfoValue)}>
          <span {...stylex.props(styles.grayText)}>Цена за М² - </span>
          {getNumberWithSpaces(value)}
          <span {...stylex.props(styles.grayText)}> {currencySign(currencyCode)}</span>
        </span>
      </span>
      <span {...stylex.props([styles.lowerInfo, styles.textTruncate])}>
        <span {...stylex.props([styles.lowerInfo, styles.textTruncate])}>
          <span>Апартаменты {size}</span>
          <span {...stylex.props([styles.grayText, styles.pointDivider])}>•</span>
          <span>{area} м²</span>
        </span>
        <span {...stylex.props([styles.grayText, styles.pointDivider, styles.desktopHidden])}>•</span>
        <span {...stylex.props([styles.lowerInfo, styles.textTruncate, styles.paddingTop])}>
          {bedroomCount} {getWordEnding(bedroomCount, BEDROOM_WORD_ENDINGS)}
        </span>
      </span>
    </button>
  );
};
