export const DownLoadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.5 13.75V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625V13.75M13.75 10L10 13.75M10 13.75L6.25 10M10 13.75V2.5"
        stroke="#1D252D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
