import React from 'react';
import { PrivacyPolicySectionProps } from '../model/types';
import { styles } from './styles';
import * as stylex from '@stylexjs/stylex';
import { Ellipse } from '@/shared/ui/icons/Ellipse';

const PrivacyPolicySection: React.FC<PrivacyPolicySectionProps> = ({ sectionContent }) => {
  return (
    <div {...stylex.props(styles.privacyPolicySection)}>
      <h1 {...stylex.props(styles.h1)}>{sectionContent.header}</h1>
      <div {...stylex.props(styles.privacyPolicyText)}>
        <p>{sectionContent.firstText}</p>
        <div {...stylex.props(styles.privacyPolicyList)}>
          {sectionContent.list.map((element, index) => (
            <div key={index} {...stylex.props(styles.listItem)}>
              <div {...stylex.props(styles.ellipseItem)}>
                <Ellipse />
              </div>
              <span>{element}</span>
            </div>
          ))}
        </div>
        <p>{sectionContent.secondText}</p>
        <p>{sectionContent.thirdText}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicySection;
