import { PhotoFrameTextType } from '@/features/photo-frame-text-section/model/types';
import family from '@shared/ui/assets/photo-frame-text/family.png';
import idealEnvironmentImage from '@shared/ui/assets/photo-frame-text/ideal-environment.png';
import impeccableLayoutsImage from '@shared/ui/assets/photo-frame-text/impeccable-layouts.png';
import spalnyaImage from '@shared/ui/assets/photo-frame-text/spalnya.png';

export const absoluteComfort: PhotoFrameTextType = {
  header: 'АБСОЛЮТНЫЙ КОМФОРТ',
  description: `Мы прорабатываем сценарии жизни людей в новых домах. 
Поэтому уже на этапе проектирования знаем, где они будут проводить выходные и покупать продукты, какие сервисы есть рядом, а какие нужно создать в доме. 
Мы учитываем потребности всех категорий жителей: детей разного возраста, пар, холостяков, старшего поколения. Наша цель — абсолютный комфорт для каждого из них.`,
  secondDescription: `Мы оснащаем наши дома самыми полными системами комфорта, которые не имеют аналогов в своем классе или превосходят их. 
Они превращают квартиру или офис в исчерпывающую среду для жизни и работы. 
Детские развивающие пространства и фитнес, доступный в режиме 24/7, роскошные дворы, келлеры, колясочные, велопарковка, лобби-бар и другие 
общественные пространства позволяют освободить место в квартире и повышают качество жизни.`,
  imgSrc: family,
};

export const idealEnvironment: PhotoFrameTextType = {
  header: 'ИДЕАЛЬНАЯ СРЕДА',
  description: `Мы создаем общественные пространства, которые гармонично дополняют друг друга и учитывают интересы каждого жителя за пределами квартиры. 
  Дворы — это полноценные сады и парки с продуманным зонированием для спокойного и тихого отдыха, чтобы никто не мешал друг другу. 
  Совершенные дворы дополняют спортивные и детские пространства, общественные гостиные. 
  Мы делаем все пространства максимально функциональными, эстетичными и работающими как единая экосистема. Так мы создаем гармоничную, 
  насыщенную и самодостаточную среду для жизни. Вы располагаете природой в городе, поддерживаете физическое и душевное равновесие, 
  живете сами и растите детей в безопасности, красоте и комфорте.`,
  imgSrc: idealEnvironmentImage,
  reverseOrder: true,
};

export const impeccableLayouts: PhotoFrameTextType = {
  header: 'БЕЗУПРЕЧНЫЕ ПЛАНИРОВКИ',
  description: `FINE DEVELOPMENT наполняет ценностью каждый метр квартиры. 
  Любое планировочное решение сначала проходит проверку по десяткам показателей и дорабатывается нами до совершенного. 
  И только потом, проведя исследование, мы проектируем сам дом. Такой подход позволяет нам предложить и для компактной студии, 
  и для роскошного пентхауса объективно наилучшую планировку из возможных.`,
  imgSrc: impeccableLayoutsImage,
};

export const spalnya: PhotoFrameTextType = {
  description: `При этом мы понимаем, насколько разными бывают требования к организации личного пространства, 
  и всегда предлагаем десятки вариантов выверенных решений на выбор — чтобы вы нашли квартиру, 
  идеально подходящую вашему образу жизни. Отсутствие пустых метров, максимум функциональности при любом метраже, 
  планировки «от мебели» с учетом реалий современной жизни, уже продуманные места для хранения, ванные комнаты при мастер-спальнях, 
  разнесение общих и частных зон.`,
  imgSrc: spalnyaImage,
  reverseOrder: true,
};
