import React, { useCallback, useRef, useState } from 'react';
import { photoType } from '@/features/before-after-slider/model/types.ts';

import ArrowTriangleUpIcon from '@/shared/ui/icons/soc-media/arrow-triangle-up.tsx';
import ArrowThinRightIcon from '@/shared/ui/icons/arrow-triangle-right.tsx';
import ArrowThinLeftIcon from '@/shared/ui/icons/arrow-triangle-left.tsx';

import styles from './preview-slider.module.css';

interface IPhotoSliderProps {
  photos: photoType[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const PhotoSlider = ({ photos, currentIndex, setCurrentIndex }: IPhotoSliderProps) => {
  const smallGalleryContainerRef = useRef<HTMLDivElement>(null);

  const nextSlide = useCallback(() => {
    const element = document.getElementById(`img-1`);
    smallGalleryContainerRef?.current?.scrollTo({
      left: smallGalleryContainerRef?.current?.scrollLeft + (element?.offsetWidth ?? 0),
      behavior: 'smooth',
    });
  }, [photos?.length]);

  const prevSlide = useCallback(() => {
    const element = document.getElementById(`img-1`);
    smallGalleryContainerRef?.current?.scrollTo({
      left: smallGalleryContainerRef?.current?.scrollLeft - (element?.offsetWidth ?? 0),
      behavior: 'smooth',
    });
  }, []);

  const onSlideClickHandler = (idx: number) => {
    setCurrentIndex(idx);
  };

  const [startPosition, setStartPosition] = useState(0); // Store the initial mouse X position
  const [scrollLeft, setScrollLeft] = useState(0); // Store the initial scroll position
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!smallGalleryContainerRef?.current) return;
    setIsDragging(true);
    setStartPosition(e.pageX - (smallGalleryContainerRef.current.offsetLeft ?? 0));
    setScrollLeft(smallGalleryContainerRef.current.scrollLeft ?? 0);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!smallGalleryContainerRef.current || !isDragging) return;
    const x = e.pageX - smallGalleryContainerRef.current.offsetLeft;
    const walk = (x - startPosition) * 0.75;
    smallGalleryContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    if (!smallGalleryContainerRef?.current) return;
    setIsDragging(false);
  };

  const touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!smallGalleryContainerRef?.current) return;
    setIsDragging(true);
    setStartPosition(e.touches[0].pageX - (smallGalleryContainerRef.current.offsetLeft ?? 0));
    setScrollLeft(smallGalleryContainerRef.current.scrollLeft ?? 0);
  };

  const touchMoveHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!smallGalleryContainerRef.current || !isDragging) return;
    const x = e.touches[0].pageX - smallGalleryContainerRef.current.offsetLeft;
    const walk = x - startPosition;
    smallGalleryContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const touchEndHandler = () => {
    if (!smallGalleryContainerRef?.current) return;
    setIsDragging(false);
  };

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.btnWrapper}>
        <button className={`${styles.sliderButton}`} onClick={prevSlide}>
          <ArrowThinLeftIcon />
        </button>
      </div>

      <div className={styles.slider}>
        <div
          className={styles.sliderTrack}
          ref={smallGalleryContainerRef}
          onTouchStart={touchStartHandler}
          onTouchMove={touchMoveHandler}
          onTouchEnd={touchEndHandler}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {photos
            ?.filter((el) => !el.isDisabled)
            ?.map((item, index) => (
              <button
                id={`img-${index}`}
                className={`${styles.sliderItem}`}
                key={index}
                onClick={() => {
                  if (!isDragging) onSlideClickHandler(item?.isLogo ? item.id + 1 : item.id);
                }}
              >
                <span
                  className={`${styles.sliderItemFade} ${item?.isLogo || item.id === currentIndex ? styles.opacityNone : ''}`}
                />
                <img
                  src={item?.isLogo ? item.logoImg : item.beforeImg}
                  alt="lot"
                  className={`${styles.sliderItemImage} ${item?.isLogo ? `${styles.logoImg}` : ''}`}
                />
                <span
                  className={`${styles.sliderItemText} ${item?.isLogo || item.id === currentIndex ? styles.opacityNone : ''}`}
                >
                  {item.title}
                </span>
                <span className={`${styles.arrow} ${item.id === currentIndex ? '' : styles.opacityNone}`}>
                  <ArrowTriangleUpIcon />
                </span>
              </button>
            ))}
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <button className={`${styles.sliderButton}`} onClick={nextSlide}>
          <ArrowThinRightIcon />
        </button>
      </div>
    </div>
  );
};

export default PhotoSlider;
