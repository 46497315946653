import { routes } from '@/shared/constants/routes.ts';

export const DEFAULT_HEADER_TEXT = 'Коротко о нас';

export const headerTexts: Record<string, string> = {
  '/': DEFAULT_HEADER_TEXT,
  [routes.public.contactsPage]: 'Контакты',
  [routes.public.bestEmployerPage]: 'Работодатель № 1',
  [routes.public.allNewsPage]: 'Информационный центр',
  [routes.public.oneNewsPage]: 'Информационный центр',
  [routes.public.brokerPage]: 'Кабинет брокера',
  [routes.public.tendersCooperationPage]: 'Тендеры',
  [routes.public.realisationPage]: 'От проекта к реальности',
  [routes.public.fineDevelopmentPage]: 'Создаем уникальные проекты',
};
