import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  privacyPolicySection: {
    display: 'flex',
    width: '50rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.11111111rem',
    color: colors.primaryText,

    fontFamily: fonts.din2014Regular,
    fontSize: '1.38888889rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.94444444rem',
    '@media (max-width: 767px)': {
      width: '100%',
      fontSize: '5rem',
      lineHeight: '6.66666667rem',
      gap: '4.44444444rem',
    },
  },
  h1: {
    fontFamily: fonts.din2014Regular,
    fontSize: '2.5rem',
    fontWeight: '600',
    lineHeight: '3.05555556rem',
    marginBlock: '0',
    '@media (max-width: 767px)': {
      fontSize: '8.88888889rem',
      lineHeight: '11.1111111rem',
    },
  },
  privacyPolicyText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '1.38888889rem',
    '@media (max-width: 767px)': {
      gap: '5.55555556rem',
    },
  },
  privacyPolicyList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    alignSelf: 'stretch',
    gap: '0.83333333rem',
    '@media (max-width: 767px)': {
      gap: '3.33333333rem',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.83333333rem',
    '@media (max-width: 767px)': {
      width: '100%',
      gap: '3.33333333rem',
    },
  },
  ellipseItem: {
    display: 'flex',
    width: '0.41666666667rem',
    height: '0.41666666667rem',
    marginTop: '0.6944444444rem',
    flexShrink: '0',
    '@media (max-width: 767px)': {
      width: '1.666666666667rem',
      height: '1.666666666667rem',
      marginTop: '2.1777777778rem',
    },
  },
});
