import { GlassPlusIcon } from '@/shared/ui/icons/glass-plus-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './stylex.ts';

const GlassIconWrapperID = 'GlassIconWrapper';

export const ImageSection = ({
  address,
  onClick,
  imgLink,
}: {
  address?: string;
  onClick?: () => void;
  imgLink?: string;
}) => {
  return (
    <button {...stylex.props([styles.imgWrapper, styles.btnDefaultStyleReset])} onClick={onClick}>
      <img src={imgLink} alt={address} {...stylex.props(styles.img)} />
      <span {...stylex.props(styles.iconPlus)} id={GlassIconWrapperID}>
        <GlassPlusIcon />
      </span>
    </button>
  );
};
