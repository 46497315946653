import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface ScrollingWrapperProps {
  sliderComponent: React.ReactNode;
  textBlockComponent: React.ReactNode;
  isSliderRight?: boolean;
}

export const ScrollingWrapper: React.FC<ScrollingWrapperProps> = ({
  sliderComponent,
  textBlockComponent,
  isSliderRight,
}) => {
  return (
    <div {...stylex.props(styles.container(isSliderRight))}>
      <div {...stylex.props(styles.slider(isSliderRight))}>{sliderComponent}</div>
      <div {...stylex.props(styles.textBlockWrapper(isSliderRight))}>{textBlockComponent}</div>
    </div>
  );
};
