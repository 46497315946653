import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { Envelope } from '@/shared/ui/icons/Envelope';
import { DownLoadIcon } from '@/shared/ui/icons/DownloadIcon';
import { Bucket } from '@/shared/ui/icons/Bucket';
import { COMPARE_HEADER_CONSTS } from '@/shared/lib/consts';

export interface IListMenuProps {
  isMenuOpen: boolean;
}
export const ListMenu = ({ isMenuOpen }: IListMenuProps) => {
  return (
    <div {...stylex.props(styles.dropdownMenu(isMenuOpen))}>
      <ul {...stylex.props(styles.list)}>
        <li {...stylex.props(styles.dropdownItem)} onClick={() => {}}>
          <span {...stylex.props(styles.dropdownText)}>
            <span {...stylex.props(styles.iconWrapper)}>
              <Envelope />
            </span>
            {COMPARE_HEADER_CONSTS.SEND}
          </span>
        </li>
        <li {...stylex.props(styles.dropdownItem)} onClick={() => {}}>
          <span {...stylex.props(styles.dropdownText)}>
            <span {...stylex.props(styles.iconWrapper)}>
              <DownLoadIcon />
            </span>
            {COMPARE_HEADER_CONSTS.DOWNLOAD}
          </span>
        </li>
        <li {...stylex.props(styles.dropdownItem)} onClick={() => {}}>
          <span {...stylex.props(styles.dropdownText)}>
            <span {...stylex.props(styles.iconWrapper)}>
              <Bucket />
            </span>
            {COMPARE_HEADER_CONSTS.CLEAR}
          </span>
        </li>
      </ul>
    </div>
  );
};
