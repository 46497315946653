import React from 'react';
import { SectionWithHeadingProps } from '../model/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

const SectionWithHeading: React.FC<SectionWithHeadingProps> = ({ title, text }) => {
  return (
    <div {...stylex.props(styles.container)}>
      <h1 {...stylex.props(styles.h1)}>{title}</h1>
      <div {...stylex.props(styles.text)}>{text}</div>
    </div>
  );
};

export default SectionWithHeading;
