import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  menu: {
    paddingTop: '1.67rem',
    paddingBottom: '1.67rem',
    flexShrink: '0',
    display: 'flex',
    flexDirection: 'column',
    width: '22.5rem',
    gap: '1.67rem',
    '@media (max-width: 767px)': {
      width: '100%',
      paddingTop: 0,
      gap: '24px',
      paddingBottom:'8.534rem'
    },
    '@media (min-width: 768px)': {
      display: 'flex',
    },
  },
  menuHidden: {
    display: 'none',
  },
});
