export const MenuBoxIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.5" y="4.5" width="10" height="10" stroke="currentColor" />
      <rect x="4.5" y="17.5" width="10" height="10" stroke="currentColor" />
      <rect x="17.5" y="4.5" width="10" height="10" stroke="currentColor" />
      <rect x="17.5" y="17.5" width="10" height="10" stroke="currentColor" />
    </svg>
  );
};
