import { useNavigate } from 'react-router-dom';
import styles from './transition-card.module.css';
import { Right } from '@/shared/ui/icons/Right';

interface TransitionCardProps {
  bgUrl: string;
  title: string;
  link: string;
}

export const TransitionCard = ({ bgUrl, title, link }: TransitionCardProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.transitionCardWrap} id='Вакансии'>
      <div className={styles.bgImage} style={{ backgroundImage: `url(${bgUrl})` }}></div>
      <p className={styles.text}>Следующий раздел</p>
      <h2 className={styles.title}>{title}</h2>
      <button onClick={() => navigate(`${link}`)} className={styles.arrowBtn}>
        <Right />
      </button>
    </div>
  );
};
