import * as stylex from '@stylexjs/stylex';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';

export const styles = stylex.create({
  button: {
    display: 'flex',
    border: 'none',
    backgroundColor: 'white',
    fontSize: '1.0416667rem',
    lineHeight: '1.45833333rem',
    gap: '0.833333rem',
    color: colors.tertiaryText,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    scale: '3rem',
    padding: '0',
  },
  buttonText: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    margin: 'auto',
    fontFamily: fonts.din2014Light,
    fontWeight: 300,
  },
  activeBtn: {
    color: palette['grey-950'],
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '1.6667rem',
  },
});
