import { ReactNode } from 'react';
import { DzenIcon } from '../ui/icons/soc-media/DzenIcon';
import { HhIcon } from '../ui/icons/soc-media/HhIcon';
import { TelegIcon } from '../ui/icons/soc-media/TelegIcon';
import { VKIcon } from '../ui/icons/soc-media/VKIcon';
import { WhatsAppIcon } from '../ui/icons/soc-media/WhatsAppIcon';
import { YouTubeIcon } from '../ui/icons/soc-media/YouTubeIcon';

interface SocMediaType {
  id: number;
  title: string;
  icon: ReactNode;
}

export const menuContent = [
  {
    id: 0,
    title: 'Fine Development',
    url: 'fine-development',
  },
  {
    id: 1,
    title: 'От проекта к реальности',
  },
  {
    id: 2,
    title: 'Скоро в продаже',
    children: [
      { id: 3, title: 'Фрунзенская набережная', url: '' },
      { id: 4, title: 'Ильинка 3/1', url: '' },
      { id: 5, title: 'Лаврушинский', url: '' },
      { id: 6, title: 'Достижение', url: '' },
    ],
  },
  {
    id: 7,
    title: 'Портфолио',
  },
  {
    id: 8,
    title: 'Служба комфорта',
  },
  {
    id: 9,
    title: 'Информация',
    children: [
      { id: 10, title: 'Работодатель №1', url: 'best-employer' },
      { id: 11, title: 'Информационный центр', url: 'kompaniya/novosti' },
      { id: 12, title: 'Кабинет брокера', url: 'broker' },
      { id: 13, title: 'Тендеры и сотрудничество', url: 'sotrudnichestvo/tendery-developera' },
      { id: 14, title: 'Контакты', url: 'contacts' },
    ],
  },
];

export const footerContent = [
  {
    id: 0,
    title: 'Fine Development',
    url: 'fine-development',
  },
  {
    id: 1,
    title: 'От проекта к реальности',
    url: '',
  },
  {
    id: 2,
    title: 'Служба комфорта',
    url: '',
  },
  {
    id: 3,
    title: 'Портфолио',
    url: '',
  },

  { id: 4, title: 'Информационный центр', url: 'kompaniya/novosti' },
  { id: 5, title: 'Выбрать недвижимость', url: '' },
  { id: 6, title: 'Работодатель №1', url: 'best-employer' },
  { id: 7, title: 'Кабинет брокера', url: 'broker' },
  { id: 8, title: 'Тендеры и сотрудничество', url: 'sotrudnichestvo/tendery-developera' },
  { id: 9, title: 'Контакты', url: 'contacts' },
];

export const menuSocMedia: SocMediaType[] = [
  {
    id: 0,
    title: 'WhatsApp',
    icon: <WhatsAppIcon />,
  },
  {
    id: 1,
    title: 'VK',
    icon: <VKIcon />,
  },
  {
    id: 2,
    title: 'Telegram',
    icon: <TelegIcon />,
  },
  {
    id: 3,
    title: 'Dzen',
    icon: <DzenIcon />,
  },
  {
    id: 4,
    title: 'YouTub',
    icon: <YouTubeIcon />,
  },
  {
    id: 5,
    title: 'Hh',
    icon: <HhIcon />,
  },
];

export const navSections = ['Ценности компании', 'Компания мечты', 'Сотрудники мечты', 'Правление', 'Вакансии'];
