import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { PageTitleType } from '../model/types';

interface PageTitleProps {
  pageTitle: PageTitleType;
}

export const PageTitle: React.FC<PageTitleProps> = ({ pageTitle }) => {
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.textBlock)}>
        <p {...stylex.props(styles.title)}>{pageTitle.title}</p>
        <p {...stylex.props(styles.description)}>{pageTitle.description}</p>
      </div>
    </div>
  );
};
