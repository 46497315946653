import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

export interface EmployerTaskProps {
  text: string;
}

export const EmployerTask = ({ text }: EmployerTaskProps) => {
  return (
    <div {...stylex.props(styles.tasksWrapper)}>
      <div {...stylex.props(styles.tasks)}>{text}</div>
    </div>
  );
};
