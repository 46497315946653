export const ArrowRightSlider = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / Arrow right">
        <path
          id="Vector"
          d="M22.6667 10.6665L28 15.9998M28 15.9998L22.6667 21.3332M28 15.9998H4"
          stroke="currentColor"
        />
      </g>
    </svg>
  );
};
