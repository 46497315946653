export const CompareSmallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.03795 9.63363C5.01291 9.82578 5 10.0217 5 10.2207C5 10.4208 5.01305 10.6178 5.03836 10.8109M8.91362 5.75856C9.10554 5.73359 9.30127 5.7207 9.5 5.7207C9.69873 5.7207 9.89445 5.73359 10.0864 5.75856M7.77635 6.06262C7.41035 6.21451 7.06873 6.41332 6.759 6.65153M5.92772 7.48375C5.69036 7.7931 5.49227 8.13416 5.34091 8.49948M10.9621 7.80777C10.9871 7.61563 11 7.41967 11 7.2207C11 7.02065 10.9869 6.82364 10.9616 6.63048M7.08638 11.6828C6.89445 11.7078 6.69873 11.7207 6.5 11.7207C6.30127 11.7207 6.10555 11.7078 5.91362 11.6828M9.241 10.7899C8.93127 11.0281 8.58965 11.2269 8.22365 11.3788M10.6591 8.94192C10.5077 9.30725 10.3096 9.64831 10.0723 9.95765M14 10.2207C14 12.706 11.9853 14.7207 9.5 14.7207C7.62484 14.7207 6.01757 13.5738 5.34142 11.9432C5.28555 11.8084 5.23603 11.6703 5.19324 11.5294C5.05121 11.4863 4.91236 11.4352 4.77665 11.3789C3.14651 10.7025 2 9.09551 2 7.2207C2 4.73542 4.01472 2.7207 6.5 2.7207C8.37516 2.7207 9.98243 3.86764 10.6586 5.49825C10.7146 5.63345 10.7644 5.77205 10.8073 5.91352C10.949 5.9565 11.0879 6.00628 11.2233 6.0625C12.8535 6.73886 14 8.34589 14 10.2207Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  );
};
