import VkIcon from '@/shared/ui/icons/soc-media/vk-icon.tsx';
import OkIcon from '@/shared/ui/icons/soc-media/ok-icon.tsx';
import ShareIcon from '@/shared/ui/icons/soc-media/share-icon.tsx';
import TelegramIcon from '@/shared/ui/icons/soc-media/telegram-icon.tsx';
import WhatsappIcon from '@/shared/ui/icons/soc-media/whatsapp-icon.tsx';
import ShareButton from '@/pages/info-center-news-page/ui/components/share-button.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/info-center-news-page/ui/components/styles.ts';

const ShareSection = () => {
  return (
    <div {...stylex.props([styles.linksContainer])}>
      <div {...stylex.props([styles.shareHeading])}>Поделиться:</div>

      <div {...stylex.props([styles.shareIconsContainer])}>
        <ShareButton title="Telegram" href="/">
          <TelegramIcon />
        </ShareButton>
        <ShareButton title="Вконтакте" href="/">
          <VkIcon />
        </ShareButton>
        <ShareButton title="WhatsApp" href="/">
          <WhatsappIcon />
        </ShareButton>
        <ShareButton title="Одноклассники" href="/">
          <OkIcon />
        </ShareButton>
        <ShareButton title="Скопировать ссылку" href="/">
          <ShareIcon />
        </ShareButton>
      </div>
    </div>
  );
};

export default ShareSection;
