import { ProfileCardType } from './types';
import tulypov from '@shared/ui/assets/tulupov.png';
import chernysh from '@shared/ui/assets/chernysh.png';
import semchyshin from '@shared/ui/assets/semchyshin.png';
import lagutov from '@shared/ui/assets/lagutov.png';
import belskyikh from '@shared/ui/assets/belskyikh.png';
import kulbikova from '@shared/ui/assets/kulbikova.png';
import poletika from '@shared/ui/assets/poletika.png';
import demiralay from '@shared/ui/assets/demiralay.png';
import levshina from '@shared/ui/assets/levshina.png';
import sertakov from '@shared/ui/assets/sertakov.png';
import ahincko from '@shared/ui/assets/ahincko.png';

export const mockEmployers: ProfileCardType[] = [
  {
    id: '0',
    fullName: 'АЛЕКСЕЙ ТУЛУПОВ',
    jobTitle: 'Президент компании',
    imgSrc: tulypov,
    quote: 'МОЯ ЦЕЛЬ — СОЗДАНИЕ САМОЙ КОМФОРТНОЙ И ПРЕСТИЖНОЙ СРЕДЫ ДЛЯ КЛИЕНТОВ SMINEX.',
    biography: '49 лет; коренной москвич; женат, трое детей.',
    details: '29 лет работает в сфере недвижимости.',
    type: 'profileCard',
    slideSide: '',
    description: 'Помимо интересов в бизнесе, каждый год принимает участие в забеге на марафонскую дистанцию.',
    interview: [
      {
        title: 'Интервью о текущей ситуации на рынке недвижимости на РБК-ТВ ',
        link: 'https://tv.rbc.ru/archive/ekskluziv/666ece812ae596fe48c53bf2 ',
      },
      {
        title: 'Интервью «Ведомостям» ',
        link: 'https://www.vedomosti.ru/realty/characters/2024/03/11/1024501-menya-vsegda-porazhalo-chto-v-biznese-est-lyudi-kotorie-plachutsya?shared_token=61f33b8de7d3d021bd54127fcc07b8c0f5406761',
      },
      {
        title: 'Интервью об итогах года на РБК ',
        link: 'https://realty.rbc.ru/news/6584197f9a794792d3588967',
      },
      {
        title: 'Интервью о спросе на жильё и поддержке государства на РБК ',
        link: 'https://www.rbc.ru/business/07/12/2022/637cbe339a79478463dcdab4',
      },
    ],
  },
  {
    id: '1',
    fullName: 'ИВАН ЧЕРНЫШ',
    jobTitle: 'Вице-президент по прибыли проектов',
    imgSrc: chernysh,
    quote:
      'МОЯ ЦЕЛЬ — СОЗДАВАТЬ ЭФФЕКТИВНЫЕ КОМАНДЫ, КОТОРЫЕ ВОПЛОЩАЮТ ПЕРВОКЛАССНЫЙ ПРОДУКТ, МАКСИМИЗИРУЯ ПРИБЫЛЬ ПРОЕКТА',
    biography:
      '41 год. Окончил Финансовую академию при Правительстве РФ, МИРБИС, Академию управления и экономики AFW (Германия), получил степень MBA в Стокгольмской школе экономики. Женат, воспитывает троих детей. Увлекается спортом: преодолел две полные дистанции триатлона Ironman и участвовал в семидневной велогонке TOUR Transalp (800 км).',
    type: 'profileCard',
    slideSide: 'left',

    description: '20 лет управляет проектами жилой и коммерческой недвижимости.',
  },
  {
    id: '2',
    fullName: 'РОМАН СЕМЧИШИН',
    jobTitle: 'Вице-президент по продажам',
    imgSrc: semchyshin,
    quote:
      'МОЯ ЦЕЛЬ — ЧТОБЫ В ЛЮБОЙ СДЕЛКЕ ВСЕ ЕЕ УЧАСТНИКИ ВЫИГРАЛИ. И ЧЕМ БОЛЬШЕ УЧАСТНИКОВ, ТЕМ ИНТЕРЕСНЕЕ. В НАШЕМ ДЕЛЕ СТОРОН В КАЖДОЙ СДЕЛКЕ МНОГО: ВЛАДЕЛЬЦЫ КОМПАНИИ И СОТРУДНИКИ, ПОКУПАТЕЛИ И ИХ БЛИЗКИЕ, ГОРОД И ЕГО ЖИТЕЛИ, БАНКИ И БРОКЕРЫ.',
    biography: '41 год; москвич; окончил РУДН; есть сын. Увлекается яхтингом, сёрфингом, сноубордом.',
    type: 'profileCard',
    slideSide: 'right',
    description: '19 лет организует продажи и клиентский сервис в области недвижимости.',
    interview: [
      {
        title: 'Роман Семчишин и Светлана Бондарчук — об элитном квартале «Тишинский бульвар» ',
        link: 'https://www.youtube.com/watch?v=nheZZuXjNDM',
      },
      {
        title: 'Видеоинтервью о корпоративной культуре и ценностях Sminex ',
        link: 'https://www.youtube.com/watch?v=DH8GMEegLb8',
      },
      {
        title: 'Интервью для «Интерфакса» ',
        link: 'https://www.interfax-russia.ru/realty/interview/uroven-sprosa-nado-vosprinimat-kak-uroven-mirovogo-okeana-roman-semchishin',
      },
    ],
  },
  {
    id: '3',
    fullName: 'АЛЕКСАНДР ЛАГУТОВ',
    jobTitle: 'Вице-президент по продукту и оценке',
    imgSrc: lagutov,
    slideSide: 'right',
    quote:
      'МОИ АМБИЦИИ ИДЕАЛЬНО СОВПАДАЮТ С АМБИЦИЯМИ КОМПАНИИ — СОЗДАВАТЬ ОСОБЕННЫЕ ПРОЕКТЫ. ОСОБЕННО КРАСИВЫЕ. ОСОБЕННО КОМФОРТНЫЕ. ЛУЧШИЕ. ПРОЕКТЫ, КОТОРЫМИ МОЖНО БУДЕТ ГОРДИТЬСЯ И ПЕРЕД КЛИЕНТАМИ, И ПЕРЕД БЛИЗКИМИ. «ЭТОТ МИР ПРИДУМАН И НАМИ» — РАБОТА ПОД ТАКИМ ЛОЗУНГОМ МЕНЯ ВДОХНОВЛЯЕТ!',
    biography:
      '42 года; родился и вырос в Москве; окончил МГУГиК, ФА, МИРБИС; женат, три дочери. Катается на горных лыжах, любит хорошую музыку и литературу.',
    type: 'profileCard',
    description:
      '21 год работает в области недвижимости, из которых 10 лет разрабатывает концепции высококлассных проектов недвижимости в Москве.',
    interview: [
      {
        title: 'Циан.Журнал. Колонка о пентхаусах ',
        link: 'https://www.cian.ru/stati-penthausy-mify-i-realnost-335567/',
      },
      {
        title: 'Тест-драйв машино-мест в паркингах элитных домов. РБК ',
        link: 'https://tv.rbc.ru/archive/industries_nedvizhomost/6582c00b2ae59631b471a759',
      },
      {
        title: 'Интервью для GMK Talks in the Show ',
        link: 'https://www.youtube.com/watch?v=2d8o8ol0ifw',
      },
      {
        title: 'Циан.Журнал. 10 правил, которые помогут выбрать идеальную планировку ',
        link: 'https://www.cian.ru/stati-10-pravil-kotorye-pomogut-vybrat-idealnuju-planirovku-326709/',
      },
    ],
  },
  {
    id: '4',
    fullName: 'ИВАН БЕЛЬСКИХ',
    jobTitle: 'Вице-президент, руководитель блока HR и IT',
    imgSrc: belskyikh,
    quote:
      'КОНЕЧНО, ЛЮДИ РЕШАЮТ ВСЁ! НО И РОЛЬ IT СЕГОДНЯ ПЕРЕОЦЕНИТЬ СЛОЖНО. МОЯ ЦЕЛЬ — СОЗДАВАТЬ КОМАНДУ И ИНФОРМАЦИОННЫЕ СИСТЕМЫ, ЧТОБЫ ОНИ ДОПОЛНЯЛИ ДРУГ ДРУГА И В КОНЕЧНОМ ИТОГЕ СОЗДАВАЛИ ПРОДУКТ И ЦЕННОСТИ, ЗА КОТОРЫЕ НАС ВЫБИРАЮТ КЛИЕНТЫ.',
    biography:
      '42 года; с отличием окончил РГТЭУ по специальности «Информационные системы в экономике»; женат, воспитывает двух сыновей. Катается на сноуборде, занимается тхэквондо и фотографией, много путешествует.',
    type: 'profileCard',
    slideSide: 'left',
    description:
      '14 лет занимается развитием и поддержкой корпоративной IT-инфраструктуры, автоматизацией бизнеса, организацией корпоративных сервисов.',
  },

  {
    id: '6',
    fullName: 'ЕЛЕНА КУЛЬБИКОВА',
    jobTitle: 'Вице-президент по финансовым и юридическим вопросам',
    imgSrc: kulbikova,
    quote:
      '«МОЯ ЗАДАЧА — ПРИ ВЫХОДЕ НА НОВЫЙ УРОВЕНЬ РАЗВИТИЯ КОМПАНИИ ОБЕСПЕЧИТЬ ОПТИМАЛЬНУЮ СТРУКТУРУ ФИНАНСИРОВАНИЯ НАШИХ ПРОЕКТОВ С УЧЕТОМ ИЗМЕНЕНИЙ ЗАКОНОДАТЕЛЬСТВА В ОТНОШЕНИИ ДОЛЕВОГО СТРОИТЕЛЬСТВА. МЫ ОТВЕЧАЕМ ВЫЗОВАМ И СТАВИМ ПЕРЕД СОБОЙ ЗАДАЧУ ОБЕСПЕЧИТЬ ДОХОДНОСТЬ ИНВЕСТИЦИЙ ВЫШЕ, ЧЕМ У КОНКУРЕНТОВ, ПРИ ЭТОМ СОБЛЮДАЯ САМЫЕ ВЫСОКИЕ ТРЕБОВАНИЯ К КАЧЕСТВУ ПРЕДЛАГАЕМОГО ПРОДУКТА».',
    biography: `43 года; окончила Новосибирский государственный технический университет по специальности «Экономика и управление на предприятии», получила степень MBA в Open British University; замужем, воспитывает сына и дочку. Увлекается плаванием и любительским боксом, очень любит читать книги японских писателей.
Более 21 года занимается управлением финансами в крупных холдингах.`,
    type: 'profileCard',
    slideSide: 'right',
    description: '',
    interview: [
      {
        title: 'Интервью для «Интерфакса» ',
        link: 'https://www.interfax-russia.ru/realty/interview/dlya-pokupateley-nedvizhimosti-konsolidaciya-developerov-idet-na-polzu-elena-kulbikova',
      },
    ],
  },
  {
    id: '5',
    years: 201,
    expierence: 'год — опыт руководителей в недвижимости',
    type: 'textCard',
    slideSide: 'right',
    description:
      'Совокупный опыт в недвижимости 11 руководителей компании составляет 201 год! За плечами каждого десятки реализованных проектов. Весь этот опыт ежедневно работает на создание самых знаковых и в тоже время уютных домов и офисов.',
  },
  

  {
    id: '7',
    fullName: 'АНДРЕЙ ПОЛЕТИКА',
    jobTitle: 'Вице-президент по экономической безопасности',
    imgSrc: poletika,
    quote:
      'Я ИЩУ И ПОКУПАЮ НОВЫЕ ПЛОЩАДКИ ДЛЯ СТРОИТЕЛЬСТВА. ПРИ ЭТОМ Я СЛЕЖУ И ЗА МАТЕРИАЛЬНОЙ ВЫГОДОЙ БУДУЩЕГО ПРОЕКТА, И ЗА СОБЛЮДЕНИЕМ НАШИХ ТРЕБОВАНИЙ К ЛОКАЦИИ — ЭТО КОМФОРТ ВИЗУАЛЬНЫЙ, ТРАНСПОРТНЫЙ, ИНФРАСТРУКТУРНЫЙ.',
    biography: `53 год; коренной москвич; женат, воспитывает двух дочерей.
Играет в хоккей, пишет прозу, для друзей варит кальвадос и граппу.`,
    type: 'profileCard',
    slideSide: 'left',
    description: '',
  },
  {
    id: '8',
    fullName: 'СЕЛИМ ДЕМИРАЛАЙ',
    jobTitle: 'Вице-президент по строительству',
    imgSrc: demiralay,
    quote:
      'МОЯ ЦЕЛЬ — КАЧЕСТВЕННО СТРОИТЬ КОМФОРТНЫЕ И КРАСИВЫЕ ДОМА. ВОПЛОЩАТЬ В РЕАЛЬНОСТЬ ЗАМЫСЛЫ АРХИТЕКТОРОВ И ПРОЕКТИРОВЩИКОВ, ПРЕВОСХОДЯ ОЖИДАНИЯ НАШИХ КЛИЕНТОВ.',
    biography: `50 лет, родился в Турции; получил высшее строительное образование по специальности «железобетонные конструкции высотных зданий» в Турции; женат, воспитывает дочь. Увлекается искусством фотографии, музыкой и литературой.
Руководил масштабными инвестиционными проектами в компаниях группы Ant Yapi. За 27-летнюю карьеру построил более 1 млн кв. м недвижимости в Турции и России, включая элитные жилые проекты в Москве.`,
    type: 'profileCard',
    slideSide: 'right',
    description: '',
    interview: [
      {
        title: 'Интервью о строительстве элитной недвижимости на «Москве 24» ',
        link: 'https://www.m24.ru/shows1/187/679286',
      },
      {
        title: 'Интервью для «РИА Недвижимость» ',
        link: 'https://realty.ria.ru/20220728/sminex-1805488773.html',
      },
      {
        title: 'Интервью для «Московской перспективы» ',
        link: 'https://mperspektiva.ru/topics/glavnyy-printsip-stroim-v-tochnosti-to-chto-obeshchali-klientam/',
      },
    ],
  },
  {
    id: '9',
    fullName: 'САМИРА ЛЕВШИНА',
    jobTitle: 'Вице-президент по согласованиям',
    imgSrc: levshina,
    quote:
      'СДЕЛАТЬ МОСКВУ ГОРОДОМ С САМОЙ КОМФОРТНОЙ СРЕДОЙ ДЛЯ ЖИЗНИ — ЭТО ОБЩАЯ ЗАДАЧА БИЗНЕСА, ВЛАСТЕЙ И ГОРОЖАН. ДВИГАТЬСЯ В ЭТОМ НАПРАВЛЕНИИ НАМ ПОМОГАЕТ НАЛАЖЕННЫЙ ДИАЛОГ МЕЖДУ ВСЕМИ СТОРОНАМИ. МОЯ ЦЕЛЬ — ДОБИТЬСЯ СИНЕРГИИ, БЛАГОДАРЯ КОТОРОЙ ПОЯВЛЯЮТСЯ ФАНТАСТИЧЕСКИЕ ПРОЕКТЫ, УКРАШАЮЩИЕ НАШ ГОРОД.',
    biography:
      '39 лет; москвичка; окончила Университет Правительства Москвы и ВШЭ; замужем, есть дочь. Увлечения: театр, кино, туризм.',
    type: 'profileCard',
    slideSide: 'right',
    description: '17 лет занимается согласованиями в сфере строительства.',
  },
  {
    id: '10',
    fullName: 'ОЛЕГ СЕРТАКОВ',
    jobTitle: 'Вице-президент по проектированию',
    imgSrc: sertakov,
    quote: `«ПРОЕКТИРОВАНИЕ В ДЕВЕЛОПМЕНТЕ — ЭТО ПОСТОЯННЫЙ ПОИСК РЕШЕНИЙ, КОТОРЫЕ ПОМОГАЮТ ПОВЫСИТЬ КАЧЕСТВО ПРОДУКТА И ПОСТРОИТЬ В СРОК. МОЯ ЦЕЛЬ — ВЫБИРАТЬ И ВНЕДРЯТЬ ЛУЧШИЕ ИЗ НИХ. ЧТОБЫ В ПОСТРОЕННЫХ ДОМАХ БЫЛА СОЗДАНА САМАЯ КОМФОРТНАЯ СРЕДА ДЛЯ НАШИХ КЛИЕНТОВ».`,
    biography:
      '34 года; москвич; окончил МГСУ, РАНХиГС; учится в аспирантуре МАИ; женат, воспитывает сына. Любит экстремальные виды спорта: горный велосипед и горные лыжи. На велосипеде съезжал с Эльбруса с отметки 3000 метров.',
    type: 'profileCard',
    slideSide: 'left',
    description: '14 лет занимается проектированием недвижимости в Москве.',
  },
  {
    id: '11',
    fullName: 'АНДРЕЙ АХИНЬКО',
    jobTitle: 'Вице-президент, руководитель департамента комфорта',
    imgSrc: ahincko,
    quote: `СЛУЖБА КОМФОРТА  — ЭТО ПРЕЖДЕ ВСЕГО ПРО ЛЮДЕЙ: ВЫСОКИЙ СЕРВИС, ЗАБОТА, СОЗДАНИЕ КОМЬЮНИТИ, ДОБРОСОСЕДСКОЙ АТМОСФЕРЫ И УЮТА В ДОМАХ. МЫ СОХРАНЯЕМ И РАЗВИВАЕМ КОНЦЕПЦИЮ ПОСТРОЕННЫХ ДОМОВ И ОКАЗЫВАЕМ МАКСИМАЛЬНЫЙ СПЕКТР УСЛУГ НАШИМ ЖИТЕЛЯМ. ИХ КОМФОРТ  — НАША ГЛАВНАЯ ЦЕЛЬ.`,
    biography:
      '33 года; окончил МГТУ им. Н.Э. Баумана, МГИМО, SKOLKOVO MBA.Увлекается современным кондитерским искусством, физикой, спортом в тренажерном зале.',
    type: 'profileCard',
    slideSide: 'right',
    description: 'Работает в системе управления недвижимостью более 9 лет.',
  },
];
