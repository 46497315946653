export const FavMediumIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.8274 2.76867C14.7446 2.87511 15.4163 3.66583 15.4163 4.58916V17.5L9.99967 14.375L4.58301 17.5V4.58916C4.58301 3.66583 5.25474 2.87511 6.17191 2.76867C7.70098 2.59122 8.42301 2.5 9.99967 2.5C11.5763 2.5 12.2984 2.59122 13.8274 2.76867Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
