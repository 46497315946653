import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface SliderSlidesProps {
  isActive: boolean;
  imageSrc: string;
  imageAlt: string;
}

export const SliderSlides: React.FC<SliderSlidesProps> = ({ isActive, imageSrc, imageAlt }) => {
  return (
    <div {...stylex.props(styles.sliderItem(isActive))}>
      <img src={imageSrc} alt={imageAlt} {...stylex.props(styles.image)} draggable={false} />
    </div>
  );
};
