import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import React from 'react';
import useIsMobile from '@/shared/hooks/useIsMobile';

export interface IProgressBarProps {
  apartments: IApartment[];
}

export const ProgressBar = React.forwardRef<HTMLDivElement, IProgressBarProps>(({ apartments }, containerRef) => {
  const { isMobile } = useIsMobile();
  const initialProgress = isMobile ? 100 / apartments.length : 100 / (apartments.length - 5);
  const [progress, setProgress] = useState(initialProgress);

  const handleScroll = () => {
    if (containerRef && typeof containerRef !== 'function' && containerRef.current) {
      const container = containerRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const scrollLeft = container.scrollLeft;
      const currProgress = (scrollLeft / maxScrollLeft) * 100;
      const progress = currProgress < initialProgress ? initialProgress : currProgress;
      setProgress(progress);
    }
  };
  useEffect(() => {
    const container = containerRef && typeof containerRef !== 'function' ? containerRef.current : null;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  return (
    <>
      {apartments.length > 6 && (
        <div {...stylex.props(styles.progressBarContainerWrapper)}>
          <div {...stylex.props(styles.progressBarContainer)}>
            <div {...stylex.props(styles.progress(progress))}></div>
          </div>
        </div>
      )}
    </>
  );
});
