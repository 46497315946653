import { SlideType } from '@/features/slider-half-screen/model/types';
import loungeChistie from '@shared/ui/assets/slider-friends-lab/gostin-chistie.png';
import loungeDostizhenieInside from '@shared/ui/assets/slider-friends-lab/gostinaya-dostizhenie-vnutri.png';
import loungeDostizhenieFloor from '@shared/ui/assets/slider-friends-lab/gostinaya-dostizhenie-yarus.png';
import loungeDostizhenie from '@shared/ui/assets/slider-friends-lab/gostinaya-dostizhenie.png';
import loungeObidenskiyBook from '@shared/ui/assets/slider-friends-lab/gostinaya-obidenskiy-knigi.png';
import loungeObidenskiy from '@shared/ui/assets/slider-friends-lab/gostinaya-obidenskiy.png';
import patioChistie from '@shared/ui/assets/slider-friends-lab/patio-chistie.png';

export const slidesFriendsLab: SlideType[] = [
  {
    id: 1,
    imgSrc: loungeChistie,
    alt: `${loungeChistie}'s photo`,
    caption: `Общественная гостиная в «Чистых Прудах»`,
  },
  {
    id: 2,
    imgSrc: loungeObidenskiy,
    alt: `${loungeObidenskiy}'s photo`,
    caption: `Общественная гостиная в «Обыденском 1»`,
  },
  {
    id: 3,
    imgSrc: loungeDostizhenieFloor,
    alt: `${loungeDostizhenieFloor}'s photo`,
    caption: `Общественная гостиная в «Достижении»`,
  },
  {
    id: 4,
    imgSrc: patioChistie,
    alt: `${patioChistie}'s photo`,
    caption: `Патио в «Чистых Прудах»`,
  },
  {
    id: 5,
    imgSrc: loungeObidenskiyBook,
    alt: `${loungeObidenskiyBook}'s photo`,
    caption: `Общественная гостиная в «Обыденском 1»`,
  },
  {
    id: 6,
    imgSrc: loungeDostizhenieInside,
    alt: `${loungeDostizhenieInside}'s photo`,
    caption: `Общественная гостиная в «Достижении»`,
  },
  {
    id: 7,
    imgSrc: loungeDostizhenie,
    alt: `${loungeDostizhenie}'s photo`,
    caption: `Общественная гостиная в «Достижении»`,
  },
];
