import { IApartment } from '@/features/vertical-data-grid/ui/mockData';
import React from 'react';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { ComparisonHeaderMobile } from './mobile';
import { ComparisonHeaderDesktop } from './desktop';

export interface IComparisonHeader {
  apartments: IApartment[];
}

export const ComparisonHeader = React.forwardRef<HTMLDivElement, IComparisonHeader>(({ apartments }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile ? (
        <ComparisonHeaderMobile apartments={apartments} />
      ) : (
        <ComparisonHeaderDesktop apartments={apartments} />
      )}
    </>
  );
});
