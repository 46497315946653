import { CardWithFloatBtnProps } from '../model/card-with-float-btn-types';
import { DesktopCardWithFloatBtn } from './desktop/desktop-card-with-float-btn';
import { MobileCardWithFloatBtn } from './mobile/mobile-card-with-float-btn';

export const CardWithFloatBtn = ({
  btnName,
  title,
  firstSentence,
  secondSentence,
  redirectUrl,
}: CardWithFloatBtnProps) => {
  return (
    <>
      <DesktopCardWithFloatBtn
        btnName={btnName}
        title={title}
        firstSentence={firstSentence}
        secondSentence={secondSentence}
        redirectUrl={redirectUrl}
      />
      <MobileCardWithFloatBtn
        btnName={btnName}
        title={title}
        firstSentence={firstSentence}
        secondSentence={secondSentence}
        redirectUrl={redirectUrl}
      />
    </>
  );
};
