interface MenuItemType {
  id: number;
  title: string;
  children?: MenuItemType[];
}

export const flattenMenu = (items: MenuItemType[]): MenuItemType[] => {
  const flatItems: MenuItemType[] = [];

  const flatten = (items: MenuItemType[]) => {
    items.forEach((item) => {
         /*
          Не добавляем children в развернутое дерево
          */
      flatItems.push({ ...item, children: undefined });
      if (item.children && item.children.length > 0) {
        flatten(item.children);
      }
    });
  };

  flatten(items);
  return flatItems;
};
