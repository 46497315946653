import { CardWithFloatBtnProps } from '../../model/card-with-float-btn-types';
import styles from './mobile-card-with-float-btn.module.css';
import { FloatBtn } from '@/shared/ui/float-btn/float-btn';

export const MobileCardWithFloatBtn = ({btnName,title,firstSentence, secondSentence,redirectUrl}: CardWithFloatBtnProps) => {
  return (
    <div className={styles.headerContent}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.subTitle}>
        {firstSentence}<br></br> {secondSentence}
      </div>
      <FloatBtn redirectUrl={redirectUrl} btnName={btnName} />
    </div>
  );
};
