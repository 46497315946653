import { useEffect, useState } from 'react';
import styles from './desktop-vice-president-card.module.css';
import { Quote } from '@/shared/ui/icons/Quote';
import { Close } from '@/shared/ui/icons/Close';
import { Play } from '@/shared/ui/icons/Play';
import { ProfileCardExpandedProps } from '..';

export const DesktopVicePresidentCard: React.FC<ProfileCardExpandedProps> = ({
  item,
  isExpanded,
  onToggleExpand,
  getCard,
  containerRef,
}) => {
  const [gap, setGap] = useState(0);
  const [isZIndexVisible, setIsZIndexVisible] = useState(isExpanded);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isExpanded) {
      setIsZIndexVisible(true);
    } else {
      timeoutId = setTimeout(() => {
        setIsZIndexVisible(false);
      }, 700);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isExpanded]);

  const handleToggleExpand = () => {
    setGap((getCard()?.getBoundingClientRect().left || 0) - (containerRef.current?.getBoundingClientRect().left || 0));
    onToggleExpand();
  };

  return (
    <>
      <div
        className={`${styles.cardContainer} 
        ${isExpanded ? styles.cardExpanded : ''} 
       ${item.id === '0' ? styles.desktopHidden : styles.cardContainer} 

      `}
        style={{
          zIndex: isZIndexVisible ? 20 : 1,
          transform: `translateX(${isExpanded && item.slideSide === 'right' ? -gap : 0}px)`,
        }}
      >
        <div
          className={styles.cardContent}
          style={{
            visibility: isZIndexVisible ? 'visible' : 'hidden',
          }}
        >
          <div
            className={styles.profileInfoContainer}
            style={{ paddingLeft: getCard()?.getBoundingClientRect().width }}
          >
            <div className={styles.profileInfoContent}>
              <div className={`${styles.quoteIcon} ${isExpanded ? styles.quoteVisible : styles.quoteHidden} `}>
                <Quote />
              </div>
              <div className={`${styles.quote} ${isExpanded ? styles.quoteVisible : styles.quoteHidden} `}>
                {item.quote}
              </div>
              <div
                className={`${styles.detailsContainer} ${isExpanded ? styles.detailsVisible : styles.detailsHidden}`}
              >
                <div className={styles.detailsWrap}>
                  <div className={isExpanded ? styles.detailsContentVisible : styles.detailsContentHidden}>
                    {item.biography}
                  </div>
                  <div className={isExpanded ? styles.detailsContentVisible : styles.detailsContentHidden}>
                    {item.details}
                  </div>
                  <div
                    className={`${styles.description} ${
                      isExpanded ? styles.detailsContentVisible : styles.detailsContentHidden
                    }`}
                  >
                    {item.description}
                  </div>
                </div>
                {item.interview && (
                  <div
                    className={`${styles.underline} ${
                      isExpanded ? styles.detailsContentVisible : styles.detailsContentHidden
                    }`}
                  ></div>
                )}
                <div>
                  {item.interview?.map((interviewItem, i) => (
                    <div
                      key={i}
                      className={`${styles.interviewContainer} ${
                        isExpanded ? styles.detailsContentVisible : styles.detailsContentHidden
                      }`}
                    >
                      <div className={styles.playIcon}>
                        <Play />
                      </div>
                      <div>
                        {interviewItem.title}
                        <a className={styles.interviewBtn} target="_blank" href={interviewItem.link}>
                          Смотреть
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            className={`${styles.closeButton} ${!isExpanded ? styles.hiddenButton : ''} ${
              isExpanded ? styles.rotateBackwards : styles.rotateForwards
            }`}
            onClick={handleToggleExpand}
          >
            <Close />
          </button>
        </div>
        <div className={styles.profileImageContainer}>
          <div className={styles.profileImageWrapper} onClick={handleToggleExpand}>
            <img
              src={item.imgSrc}
              alt={`${item.fullName}'s photo`}
              className={`${styles.profileImage} ${isExpanded ? styles.profileImageExpanded : ''}`}
            />
            <button
              className={`${styles.expandButton} ${isExpanded ? styles.hiddenButton : ''} ${
                isExpanded ? styles.rotateBackwards : styles.rotateForwards
              }`}
              onClick={handleToggleExpand}
            >
              <Close />
            </button>
          </div>
          <div className={styles.name}>{item.fullName}</div>
          <div className={styles.title}>{item.jobTitle}</div>
        </div>
      </div>
    </>
  );
};
