import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { pageConfig } from '../model/mock-json';
import { renderComponent } from '@/shared/libs/dynamic-components-render.tsx';


export const PrivacyPolicyPage = () => {
  return (
    <div {...stylex.props(styles.privacyPolicyContainer)}>
      {pageConfig().map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </div>
  );
};
